import { Action, ActionCreator, Reducer } from "redux";
import { getInitialState } from "../helpers/Authentication";
import { IAppUserInfoModel } from "../models/IAppUserInfoModel";

export interface ICommonState {
  appUserInfo: IAppUserInfoModel;
  blockUi: boolean;
  isAuthenticated: boolean;
  activeMenuItem: string;
  wizard: any;
}

const initialState: ICommonState = getInitialState();

interface IAppUserSet extends Action<"AppUser_SET"> {
  payload: IAppUserInfoModel;
}

interface IBlockUiSet extends Action<"BlockUi_SET"> {
  payload: boolean;
}

interface IIsAuthenticatedSet extends Action<"IsAuthenticated_SET"> {
  payload: boolean;
}

interface ILogoutAppUser extends Action<"Logout_SET"> {}

interface ISET_ACTIVE_MENU_ITEM extends Action<"SET_ACTIVE_MENU_ITEM"> {
  payload: string;
}

interface IGET_APP_USER_INFO extends Action<"GET_APP_USER_INFO"> {
  payload: any;
}

interface ISET_WIZARD extends Action<"SET_WIZARD"> {
  payload: any;
}

type Actions =
  | IAppUserSet
  | IBlockUiSet
  | IIsAuthenticatedSet
  | ILogoutAppUser
  | ISET_ACTIVE_MENU_ITEM
  | IGET_APP_USER_INFO
  | ISET_WIZARD;

export const commonReducer: Reducer<ICommonState, Actions> = (
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case "AppUser_SET":
      return {
        ...state,
        appUserInfo: action.payload,
      };
    case "BlockUi_SET":
      return {
        ...state,
        blockUi: action.payload,
      };
    case "IsAuthenticated_SET":
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case "Logout_SET":
      return {
        ...state,
        appUserInfo: {},
        blockUi: false,
        isAuthenticated: false,
      };
    case "SET_ACTIVE_MENU_ITEM":
      return {
        ...state,
        activeMenuItem: action.payload,
      };
    case "GET_APP_USER_INFO":
      return {
        ...state,
      };
    case "SET_WIZARD":
      return {
        ...state,
        wizard: action.payload,
      };
    default:
      return state;
  }
};

export const setAppUserInfo: ActionCreator<IAppUserSet> = (
  payload: IAppUserInfoModel
) => ({
  type: "AppUser_SET",
  payload,
});

export const setBlockUi: ActionCreator<IBlockUiSet> = (payload: boolean) => ({
  payload,
  type: "BlockUi_SET",
});

export const setIsAuthenticated: ActionCreator<IIsAuthenticatedSet> = (
  payload: boolean
) => ({
  type: "IsAuthenticated_SET",
  payload,
});

export const logoutAppUser: ActionCreator<ILogoutAppUser> = () => ({
  type: "Logout_SET",
});

export const setActiveMenuItem: ActionCreator<ISET_ACTIVE_MENU_ITEM> = (
  payload: string
) => ({
  type: "SET_ACTIVE_MENU_ITEM",
  payload,
});

export const getAppUserInfo: ActionCreator<IGET_APP_USER_INFO> = (
  payload: any
) => ({
  type: "GET_APP_USER_INFO",
  payload,
});

export const setWizard: ActionCreator<ISET_WIZARD> = (payload: any) => ({
  type: "SET_WIZARD",
  payload,
});
