import decode from "jwt-decode";
import React, { useEffect } from "react";
import styles from "./styles/LoginForm.module.scss";
// import TextInputF from "../../../../components/templateForm/form/textInput/TextInputF";
import { Icon } from "antd";
import { connect, MapDispatchToProps, useDispatch } from "react-redux";
// import { createAbsoluteUrl } from "../../../../helpers/UrlCreator";
import { Dispatch } from "redux";
import { emailIsValid } from "../../../../helpers/ValidationHelpers";
import { Form, FormikBag, FormikErrors, withFormik } from "formik";
import { getInitialState } from "../../../../helpers/Authentication";
import { getToken, setToken } from "../../../../helpers/LocalStorageHelpers";
import { history } from "../../../../helpers/History";
import { IAppUserInfoModel } from "../../../../models/IAppUserInfoModel";
import {
  ICommonState,
  setAppUserInfo,
  setBlockUi,
  setIsAuthenticated,
} from "../../../../reducers/CommonReducer";
import { ILoginFormProps as IFormProps } from "./interfaces/ILoginFormProps";
import { ILoginFormValues as IFormValues } from "./interfaces/ILoginFormValues";
import { ISetAppUserInfo } from "../../../../common/interfaces/ISetAppUserInfo";
import { ISetBlockUi } from "../../../../common/interfaces/ISetBlockUi";
import { ISetIsAuthenticated } from "../../../../common/interfaces/ISetIsAuthenticated";
import { ITokenData } from "../../../../helpers/interfaces/ITokenData";
import { login } from "../../../../services/AuthenticationServices";
import { resources } from "../../../../common/Resources";

type IFormDispatchProps = ISetAppUserInfo & ISetBlockUi & ISetIsAuthenticated;

// const lang: string = localStorage.getItem("locale")
//   ? localStorage.getItem("locale")!
//   : "en_GB";

// const formikTranslation: any = {
//   en_GB: {
//     email: "E-mail", //
//     password: "Password", //
//   },
//   id_ID: {
//     email: "E-mail", //
//     password: "Пароль", //
//   },
// };

const InnerLoginForm = (props: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');

    if (!token) return;

    const initialState: ICommonState = getInitialState();
    const decoded: ITokenData = decode(token!);
    const aud: number[] = decoded.aud.map((item) => Number(item));

    decoded.aud = aud;

    initialState.appUserInfo = {
      id: decoded.sub,
      permissions: decoded.aud,
      email: decoded.username,
      acc: { orders: decoded.acc.orders },
      iia: decoded.iia,
    };

    setToken(token);
    dispatch(setAppUserInfo(initialState.appUserInfo));
    dispatch(setIsAuthenticated(true));
    dispatch(setBlockUi(false));

    return decoded.iia ? history.push("/dashboard") : history.push("/wizard");
  }, [dispatch]);

  return (
    <article className={styles.grid}>
      <Form>
        {/* <div className={styles.formItem}>
          <TextInputF
            {...props}
            name="email"
            label={formikTranslation[lang].email}
            disabled={props.values.loading}
            required
          />
        </div>
        <div className={styles.formItem}>
          <TextInputF
            {...props}
            name="password"
            label={formikTranslation[lang].password}
            isPassword
            disabled={props.values.loading}
            required
          />
        </div> */}
        <h2 className="text-center text-4xl font-semibold">{resources.loginPage.title}</h2>
        <div className={styles.formFooter}>
          {/* <a
            className={`h6__semibold block w-full text-right text-dark-gray mb-6`}
            href={createAbsoluteUrl("resetPassword")}
          >
            {resources.loginPage.forgotYourPassword}
          </a>
          <div className="w-280 h-44 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-md">
            <Button
              className="hover:text-white hover:bg-transparent text-white w-278 h-41 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-gray font-semibold text-xxs rounded-md"
              htmlType="submit"
              loading={props.values.loading}
            >
              {resources.loginPage.logIntoThePlatform}
            </Button>
          </div> */}
          <div className="w-full mt-6 flex flex-col gap-2">
            <a href="https://api.dev.affbay.com/auth/google/" className="w-full py-1 flex items-center justify-center bg-blue-gray rounded-md">
              <Icon type="google" className="-mt-1 text-white text-sm font-seminbold"/>
              <span className="block -mb-0.5 ml-1.5 text-xxs text-white font-semibold">Login with Google</span>
            </a>
            <a href="https://api.dev.affbay.com/auth/twitter/" className="w-full py-1 flex items-center justify-center bg-blue-gray rounded-md">
              <Icon type="twitter" className="-mt-1 text-white text-sm font-seminbold"/>
              <span className="block -mb-0.5 ml-1.5 text-xxs text-white font-semibold">Login with Twitter</span>
            </a>
          </div>
        </div>
      </Form>
    </article>
  );
}

const FormWithFormik = withFormik<IFormProps & IFormDispatchProps, IFormValues>(
  {
    mapPropsToValues: () => {
      return {
        email: undefined,
        password: undefined,
        loading: false,
      };
    },

    validate: (values: IFormValues) => {
      const errors: FormikErrors<IFormValues> = {};

      if (!values.email) {
        errors.email = resources.validation.emailIsRequired;
      } else if (values.email && !emailIsValid(values.email)) {
        errors.email = resources.validation.incorrectEmailAdres;
      }
      if (values.email && values.email.length > 255) {
        errors.email = resources.validation.requiredMax255Characters;
      }
      if (values.password && values.password.length > 255) {
        errors.password = resources.validation.requiredMax255Characters;
      }
      if (!values.password) {
        errors.password = resources.validation.passwordIsRequired;
      }

      return errors;
    },

    handleSubmit: (
      values: IFormValues,
      bag: FormikBag<IFormDispatchProps, IFormValues>
    ) => {
      loginAxios(
        values.email!,
        values.password!,
        bag.props.setIsAuthenticated,
        bag.props.setBlockUi,
        bag.props.setAppUserInfo,
        bag.setFieldError,
        bag.setFieldValue
      );
    },

    validateOnBlur: false,
    validateOnChange: false,
  }
)(InnerLoginForm);

const mapDispatchToProps: MapDispatchToProps<IFormDispatchProps, {}> = (
  dispatch: Dispatch
) => ({
  setIsAuthenticated: (isAuthenticated: boolean) => {
    dispatch(setIsAuthenticated(isAuthenticated));
  },
  setBlockUi: (blockUi: boolean) => {
    dispatch(setBlockUi(blockUi));
  },
  setAppUserInfo: (appUserInfoModel: IAppUserInfoModel) => {
    dispatch(setAppUserInfo(appUserInfoModel));
  },
});

const LoginForm: React.ComponentClass = connect(
  null,
  mapDispatchToProps
)(FormWithFormik);

export default LoginForm;

const loginAxios = (
  email: string,
  password: string,
  setIsAuthenticated: (iaAuthenticated: boolean) => void,
  setBlockUi: (blockUi: boolean) => void,
  setAppUserInfo: (userInfo: IAppUserInfoModel) => void,
  setLoginError: (field: any, errorMessage: string) => void,
  setFieldValue: (field: any, errorMessage: boolean) => void
) => {
  setFieldValue("loading", true);

  login(email, password)
    .then((response) => {
      if (!response.error) {
        let initialState: ICommonState = getInitialState();

        const token = getToken();
        const decoded: ITokenData = decode(token!);

        const aud: number[] = decoded.aud.map((item) => {
          return Number(item);
        });

        decoded.aud = aud;

        initialState.appUserInfo = {
          id: decoded.sub,
          permissions: decoded.aud,
          email: decoded.username,
          acc: { orders: decoded.acc.orders },
        };

        setAppUserInfo(initialState.appUserInfo);
        setIsAuthenticated(true);
        setBlockUi(false);

        history.push("/wizard");
      } else {
        switch (response.message) {
          case "user_deactivated":
            setLoginError(
              "password",
              `You account is not active. Please check email.`
            );
            break;
          case "incorrect_credentials":
          case "user_not_found":
          default:
            setLoginError(
              "password",
              resources.statement.incorrect_login_information
            );
        }

        setFieldValue("loading", false);
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
};
