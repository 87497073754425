import "moment/locale/en-gb";
import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import React from "react";
import id_ID from "antd/lib/locale-provider/id_ID";
import { _onSetLanguage } from "./common/Resources";
import { ConfigProvider } from "antd";
import { getLocale } from "./helpers/LocaleHelper";
import { MainView } from "./views/MainView";
import { QueryClient, QueryClientProvider } from "react-query";

// Create a client for the whole app
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

class App extends React.Component {
  state = {
    locale: undefined,
  };

  componentDidMount = () => {
    localStorage.setItem(
      "start_url",
      window.location.pathname + window.location.search
    );

    const locale = getLocale();

    _onSetLanguage(locale);
    moment.locale(locale);

    if (locale === "en_GB") {
      this.setState({ locale: en_GB });
    } else if (locale === "id_ID") {
      this.setState({ locale: id_ID });
    }
  };

  changeLocale = (e: any) => {
    if (e === "en_GB") {
      moment.locale("en_GB");
      localStorage.setItem("locale", "en_GB");
      _onSetLanguage("en_GB");
      this.setState({ locale: en_GB });
    } else if (e === "id_ID") {
      moment.locale("id_ID");
      localStorage.setItem("locale", "id_ID");
      _onSetLanguage("id_ID");
      this.setState({ locale: id_ID });
    }
  };

  public render() {
    return (
      <div className="change-locale">
        <ConfigProvider locale={this.state.locale}>
          <QueryClientProvider client={queryClient}>
            <MainView changeLang={this.changeLocale} />
          </QueryClientProvider>
        </ConfigProvider>
      </div>
    );
  }
}

export default App;
