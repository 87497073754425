import React, { useState } from "react";
import { IUserPersonalData } from "../../../../models/IUserPersonalData";
import { resources } from "../../../../common/Resources";
import { updateUser } from "../../../../services/m29_users_management/GeneralUserService";
import { openSuccessNotification } from "../../../../helpers/NotificationHelper";

interface IUser {
  user: IUserPersonalData;
  id: number;
}

export const WalletForm: React.FC<IUser> = ({ user, id }: IUser) => {
  const [state, setState] = useState({
    walletName: user.wallet_name,
    walletNumber: user.wallet_number,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await updateUser(id, {
      wallet_name: state.walletName,
      wallet_number: state.walletNumber,
    }).then(() => {
      openSuccessNotification(resources.statement.updated);
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-2.5 mb-5">
        <div className="w-full md:w-382 flex flex-col">
          <input
            className="placeholder-gray-500 placeholder-opacity-50 border rounded-md border-lite-gray py-2 px-5 w-full text-xxs font-normal"
            placeholder={resources.labels.walletName}
            type="text"
            name="walletName"
            required
            value={state.walletName}
            onChange={handleChange}
          />
        </div>
        <div className="w-full md:w-382 flex flex-col">
          <input
            className="placeholder-gray-500 placeholder-opacity-50 border rounded-md border-lite-gray py-2 px-5 w-full text-xxs font-normal"
            placeholder={resources.labels.walletNumber}
            type="text"
            name="walletNumber"
            required
            value={state.walletNumber}
            onChange={handleChange}
          />
        </div>

        <div className="w-148 h-44 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-md">
          <button
            className="w-144 h-41 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:bg-transparent bg-blue-gray text-white font-semibold text-xxs rounded-md"
            type="submit"
          >
            SAVE
          </button>
        </div>
      </div>
    </form>
  );
};
