import React, { Component } from "react";
import { IPartnerViewCheckbox } from "./interfaces/IPartnerViewCheckbox";
import { IPartnerViewCheckboxMethods } from "./interfaces/IPartnerViewChecboxMethods";

export class PartnerViewCheckbox extends Component<
  IPartnerViewCheckbox & IPartnerViewCheckboxMethods
> {
  render() {
    return (
      <label className="relative flex w-4 cursor-pointer">
        <input
          type="checkbox"
          className="pointer-events-none w-4 rounded-min appearance-none border-1 border-dark-blue bg-white partner-view-checkbox"
          checked={this.props.checked}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`absolute w-4 h-15 ${
            this.props.checked ? "block" : "hidden"
          }`}
        >
          <path
            d="M4.37715 8.24998L1.76465 5.63748L2.41777 4.98436L4.37715 6.94373L8.58236 2.73853L9.23548 3.39165L4.37715 8.24998Z"
            fill="#222849"
          />
        </svg>
      </label>
    );
  }
}
