import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'

const useViewport = () => {
    const { width } = useWindowSize()

    const [isMobile, setIsMobile] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)

    useEffect(() => {
        setIsMobile(width <= 576)
        setIsTablet(width > 576 && width <= 1024)
        setIsDesktop(width > 1024)
    }, [width])

    return { isMobile, isTablet, isDesktop }
}

export default useViewport