import axios from "axios";
import { createTrackerAbsoluteUrl } from "../helpers/UrlCreator";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../helpers/NotificationHelper";
import { resourceDef } from "../common/ResourceDef";

const createCampaignUrl = createTrackerAbsoluteUrl(resourceDef.TargetUrlCreate);
const getCampaignsUrl = createTrackerAbsoluteUrl(resourceDef.TargetUrlGetAll);
const deleteCampaignsUrl = createTrackerAbsoluteUrl(
  resourceDef.TargetUrlDelete
);

interface ICreate {
  name: string;
  productId: number;
  pre: Array<number>;
  lp: Array<number>;
  redirectType?: string;
  redirectRation?: string;
}

export const createTargetUrlCampaign = (data: ICreate) =>
  axios
    .post(createCampaignUrl, data)
    .then((response: any) => {
      openSuccessNotification("Campaign created!");

      return response.data;
    })
    .catch((error) => {
      console.log(error);
      if (
        error.response &&
        error.response.status &&
        error.response.status === 409
      )
        openErrorNotification(`Campaign's name already exists`);
      else openErrorNotification("createTargetUrlCampaign T2 Campaigns");

      return false;
    });

export const getTargetUrlCampaigns = (param: string) =>
  axios
    .get(getCampaignsUrl + param)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      openErrorNotification(" getTargetUrlCampaigns T2 Campaigns");
      return [];
    });

export const deleteTargetUrlCampaigns = (id: string) =>
  axios
    .delete(deleteCampaignsUrl + id)
    .then(() => {
      return true;
    })
    .catch((error) => {
      if (error.response.status === 403) {
        openErrorNotification(
          "Error! You can’t delete campaigns. Please contact your account manager."
        );
      } else {
        openErrorNotification(" deleteTargetUrlCampaigns T2 Campaigns");
      }

      return false;
    });
