import logo from "../../common/images/auth-logo.png";
import React from "react";

export const LogoAsLinkToLandingPage = () => {
  return (
    <a href={"/"}>
      {" "}
      <img width="130" src={logo} alt="linkusa" className="mb-6" />
    </a>
  );
};
