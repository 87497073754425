import React from "react";
import { Spin } from "antd";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import useViewport from "../../statistics/lib/hooks/useViewport";
import { GenerateUrlForm } from "../../../components/GenerateUrlForm/GenerateUrlForm";
import { useWizardProduct } from "../lib/getWizardProduct";
import { resources } from "../../../common/Resources";

export const WizardCampaign = () => {
  const { isMobile } = useViewport();
  const { productData, productImg } = useWizardProduct();
  const wizardLanding = useSelector(
    (state: any) => state.commonState.wizard.lp
  );

  return (
    <section className="container-fluid h-full xm:bg-white md:bg-transparent mb-16 pt-10 px-4 md:px-8">
      {Object.keys(productData).length > 0 ? (
        <>
          <div className="flex gap-3 mb-9">
            <div className="min-w-38 h-38 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-50">
              <div className="w-33 h-33 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-light-white-gray-wizard text-white font-semibold text-min-xs rounded-50 content-center text-center">
                <span className="text-indigo text-xmd font-semibold">3</span>
              </div>
            </div>
            <h2 className="self-center text-indigo font-semibold text-md md:text-sm">
              Atur nama kampanye
            </h2>
          </div>
          <div className="w-full lg:w-758 bg-white rounded-xxs">
            <div className="rounded-tl-xxs rounded-tr-xxs">
              <img
                className="mx-auto"
                src={productImg.toString()}
                alt="icon-product"
              />
            </div>
            <div className="pt-4 border border-gray md:border-none md:border-transparent rounded-b-xxs mb-12 md:mb-0">
              <div className="px-6">
                <h2 className="mb-2 text-indigo font-semibold text-md hidden sm:block">
                  {productData[0].name_short}
                </h2>
                <p className="pt-3 sm:pt-0 text-dark-gray font-normal text-xxs pb-7">
                  Masukkan nama kampanye pertama Anda
                </p>
                <div className="mb-14">
                  <GenerateUrlForm
                    landing={wizardLanding}
                    productId={productData[0].id}
                    buttonName={`${isMobile ? "Save" : "Hasilkan URL"}`}
                    placeholderName={`${isMobile ? "Kampanye pertama saya" : "Nama kampanye"}`}
                  />
                </div>
              </div>
            </div>
            <NavLink to="/wizard/copy">
              <div className="pb-8 w-181 md:w-244 mx-auto">
                <button className="w-181 h-38 md:w-244 md:h-44 text-white text-min-xs md:text-xxs font-bold text-center rounded-full border-1 bg-gradient-to-r from-blue to-aquamarine hover:from-light-blue hover:to-navy-blue hover:text-white">
                  HALAMAN BERIKUTNYA
                </button>
              </div>
            </NavLink>
          </div>
        </>
      ) : (
        <div className="text-center">
          <Spin tip={resources.spin.loading} />
        </div>
      )}
    </section>
  );
};
