import React from "react";
import { BaseForm } from "../base/BaseForm";
import { IBaseFormikProps } from "../base/interfaces/IBaseFormikProps";

export interface ITextInputFProps {
  isNumeric?: boolean;
  isPassword?: boolean;
  isCurrency?: boolean;
  iconType?: string;
  type?: string;
  placeholder?: string;
}

const TextInputF: React.SFC<IBaseFormikProps & ITextInputFProps> = (props) => {
  function onChange(value: string) {
    props.customOnChangeCb && props.customOnChangeCb(value);
    if (props.isNumeric) return value.replace(/\D/g, "");
    if (props.isCurrency) {
      if (!value.includes(".") && !value.includes(",")) return value;
      if (value.includes("."))
        return `${value.split(".")[0]}.${value.split(".")[1].substring(0, 2)}`;
      else
        return `${value.split(",")[0]}.${value.split(",")[1].substring(0, 2)}`;
    }
    return value;
  }

  const inputValue = props.values[props.name];
  const titleValue = props.isPassword ? "" : inputValue;
  const type = props.isPassword ? { type: "password" } : {};
  const placeholder = props.placeholder;

  const styles = {
    input: {
      width: "100%",
      borderWidth: "1px",
      borderColor: "#989898",
      borderRadius: "30px",
      paddingLeft: "1.25rem",
      paddingRight: "1.25rem",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      lineHeight: "22px",
      "--placeholder-color": "#989898",
      "--placeholder-opacity": "0.5",
      "--placeholder-font-size": "16px",
      "--placeholder-line-height": "22px",
      "--placeholder-font-weight": "400",
    },
  };

  return (
    <BaseForm {...props}>
      <input
        style={styles.input}
        placeholder={placeholder}
        value={inputValue}
        title={titleValue}
        type={props.type}
        onChange={(event) =>
          props.setFieldValue(props.name, onChange(event.target.value))
        }
        onBlur={() => props.setFieldTouched(props.name)}
        disabled={props.disabled}
        readOnly={props.readOnly}
        {...type}
      />
    </BaseForm>
  );
};

export default TextInputF;
