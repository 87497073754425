import React, { useState } from "react";
import { resources } from "../../common/Resources";
import { Modal } from "antd";
import { createTargetUrlCampaign } from "../../services/TargetUrlCamaigns";
import { useDispatch, useSelector } from "react-redux";
import { setWizard } from "../../reducers/CommonReducer";
import { updateUser } from "../../services/m29_users_management/GeneralUserService";

const { confirm } = Modal;

export type GenerateUrlFormPropsType = {
  landing: number[];
  productId?: number | string;
  reRenderTable?: () => void;
  buttonName: string;
  placeholderName: string;
  prelanding?: number[];
};

export const GenerateUrlForm = (props: GenerateUrlFormPropsType) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.commonState.appUserInfo);
  const [state, setState] = useState({
    errorsVisible: 0,
    campaignName: "",
  });

  const handleSubmit = (e: any): any => {
    e.preventDefault();

    if (props.landing.length === 0) {
      return confirm({
        title: "Validation",
        content: "Please choose the landings first",
        okText: "Ok",
        okType: "primary",
        className: "create-campaign-modal",
        centered: true,
        cancelButtonProps: { style: { display: "none" } },
      });
    }

    confirm({
      title: resources.labels.are_you_sure,
      content: resources.labels.do_you_want_to_generate_this_campaign,
      okText: resources.buttons.yes,
      okType: "primary",
      cancelText: resources.buttons.no,
      className: "create-campaign-modal",
      centered: true,
      onOk: () => submit(),
      onCancel() {},
    });
  };

  const submit = async () => {
    const data = {
      productId: Number(props.productId),
      lp: props.landing,
      pre: props.prelanding!,
      name: state.campaignName,
      redirectType: "302",
      redirectRation: "auto",
    };

    await createTargetUrlCampaign(data).then((response: any) => {
      setState({
        campaignName: "",
        errorsVisible: 0,
      });

      return props.reRenderTable
        ? props.reRenderTable()
        : dispatch(setWizard({ url: response.urls.tracker }));
    });

    if (!user.iia) {
      await updateUser(user.id, {
        is_influencer_active: true,
      });
    }

  };

  const onInputChange = (event: any): void =>
    setState({ ...state, campaignName: event.target.value });

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-7">
        <div
          className={`${
            props.buttonName === "Save" ? "items-center" : "items-end"
          } flex  xm:flex-col md:flex md:flex-row md:items-start gap-2`}
        >
          <div className="w-full md:w-382 flex flex-col">
            <input
              className="placeholder-gray-500 placeholder-opacity-50 border rounded-md border-lite-gray py-2 px-5 w-full text-xxs font-normal mb-2"
              placeholder={props.placeholderName}
              type="text"
              required
              value={state.campaignName}
              onChange={onInputChange}
            />
            <span className="block self-end text-red text-min font-normal">
              *please add correct name
            </span>
          </div>
          <button
            className="w-121 h-40 text-white text-center rounded-full border-1 bg-gradient-to-r from-blue to-aquamarine hover:from-light-blue hover:to-navy-blue hover:text-white"
            type="submit"
          >
            {props.buttonName}
          </button>
        </div>
      </div>
    </form>
  );
};
