import React, { useState } from "react";
import { useQuery } from "react-query";

import backgroundLeft from "./images/bg-top-left.svg";
import backgroundRight from "./images/bg-bottom-right.svg";
import backgroundLeftPayout from "./images/bg-payout-left.svg";
import backgroundRightPayout from "./images/bg-payout-right.svg";
// import { PayoutChart } from "../components/LineChartStatistic";
import { getLeadsStatistics } from "../../../services/m55_leads_statistics/LeadsStatisticsService";

const PERIODS = {
  TODAY: "today",
  MONTH: "this_month",
  LAST_MONTH: "last_month",
};

export const DashboardContent = () => {
  const [period, setPeriod] = useState(PERIODS.TODAY);
  const { data, isLoading } = useQuery("dashboard-history", () =>
    getLeadsStatistics("")
  );

  if (isLoading) return null;

  return (
    <section className="container-fluid h-full xm:bg-white md:bg-transparent mb-16 pt-10 px-6 md:px-8">
      <div className="flex flex-col md:flex-row gap-2 md:gap-16 items-start md:items-center pb-4 md:pb-2">
        <h1 className="inline-block text-indigo text-md sm:text-sm font-semibold">
          Statistik
        </h1>
        <ul className="flex gap-10 max-w-max">
          <li
            className={`text-xxxs font-semibold cursor-pointer ${
              period === PERIODS.TODAY ? "text-green" : "text-blue-dark"
            }`}
            onClick={() => setPeriod(PERIODS.TODAY)}
          >
            Hari ini
            <hr
              className={`border-b-2 w-1/8 border-green ${
                period === PERIODS.TODAY ? "visible" : "hidden"
              }`}
            />
          </li>
          <li
            className={`text-xxxs font-semibold cursor-pointer ${
              period === PERIODS.MONTH ? "text-green" : "text-blue-dark"
            }`}
            onClick={() => setPeriod(PERIODS.MONTH)}
          >
            Bulan ini
            <hr
              className={`border-b-2 w-1/8 border-green ${
                period === PERIODS.MONTH ? "visible" : "hidden"
              }`}
            />
          </li>
          <li
            className={`text-xxxs font-semibold cursor-pointer ${
              period === PERIODS.LAST_MONTH ? "text-green" : "text-blue-dark"
            }`}
            onClick={() => setPeriod(PERIODS.LAST_MONTH)}
          >
            Bulan lalu
            <hr
              className={`border-b-2 w-1/8 border-green ${
                period === PERIODS.LAST_MONTH ? "visible" : "hidden"
              }`}
            />
          </li>
        </ul>
      </div>
      <hr className="border-white-gray mb-7 xl:mb-5" />

      <div className="flex flex-col-reverse sm:items-center xl:items-start xl:flex-row gap-6">
        <div>
          <div>
            {/* <div className="w-auto 4xl:w-1189 p-8 h-402 bg-white rounded-xs shadow-black-3xl mb-6 hidden xl:block">
              <div className="flex justify-between mb-12">
                <span className="text-blue-dark text-xmd font-bold">
                  Your payout
                </span>
                <span className="text-blue-dark text-xs font-normal">
                  Full report
                </span>
              </div>
              <PayoutChart />
              <div className="flex gap-6">
                <span className="ml-16 text-xs font-semibold text-blue-dark">
                  DAY BY DAY 1-05-2024
                </span>
                <span className="text-xs font-semibold text-blue-dark">
                  02-05-2024
                </span>
              </div>
            </div> */}

            <div className="bg-green rounded-xs px-12 py-8 sm:pl-14 sm:pr-12 md:pl-6 md:pr-8 md:pt-6 md:pb-16 relative shadow-blue-3xl">
              <img
                className="z-0 absolute w-130 md:w-148 2xl:w-auto top-px left-px md:top-0 md:left-0"
                alt="background"
                src={backgroundLeft}
              />
              <img
                className="z-0 absolute w-130 md:w-250 2xl:w-auto bottom-0 right-0 hidden md:block"
                alt="background"
                src={backgroundRight}
              />
              <img
                className="absolute z-0 bottom-0 right-0 block md:hidden"
                src={backgroundRightPayout}
                alt="background"
              />
              <div className="flex justify-between mb-12 hidden md:flex">
                <span className="relative z-1 text-blue-dark text-xmd font-bold">
                  Statistics
                </span>
                <span className="text-blue-dark text-xs font-normal">
                  Full report
                </span>
              </div>
              <div className="flex flex-col md:flex-row md:flex-wrap pt-4 pb-8 md:pr-0 md:pl-8 md:pt-0 md:pb-0">
                <div className="w-250 pr-0 pl-8 md:pr-14 flex flex-col sm:block m-auto sm:m-0">
                  <span className="text-blue-dark text-xmd font-bold">
                    Klik
                  </span>
                  <div className="relative pl-5 max-w-max sm:w-auto">
                    <span className="w-md h-4 bg-white rounded-xs absolute bottom-3/6 left-2/7 transform rotate-90" />
                    <span className="text-white text-2lg">
                      {data[period].clicks}
                    </span>
                  </div>
                </div>
                <div className="w-250 pr-0 pl-8 md:pr-32 flex flex-col sm:block m-auto sm:m-0">
                  <span className="text-blue-dark text-xmd font-bold">
                    Memimpin
                  </span>
                  <div className="relative pl-5 max-w-max sm:w-auto">
                    <span className="w-md h-4 bg-white rounded-xs absolute bottom-3/6 left-2/7 md:left-3/8 transform rotate-90" />
                    <span className="text-white text-2lg">
                      {data[period].leads}
                    </span>
                  </div>
                </div>
                <div className="w-250 pr-0 pl-8 md:pr-32 flex flex-col sm:block m-auto sm:m-0">
                  <span className="text-blue-dark text-xmd font-bold">
                    Diterima
                  </span>
                  <div className="relative pl-5 max-w-max sm:w-auto">
                    <span className="w-md h-4 bg-white rounded-xs absolute bottom-3/6 left-2/7 md:left-3/10 transform rotate-90" />
                    <span className="text-white text-2lg">
                      {data[period].sold}
                    </span>
                  </div>
                </div>
                <div className="w-250 flex flex-col pl-8 sm:block m-auto sm:m-0">
                  <span className="text-blue-dark text-xmd font-bold ">
                    Delivered
                  </span>
                  <div className="w-max relative pl-5 max-w-max sm:w-auto">
                    <span className="w-md h-4 bg-white rounded-xs absolute bottom-3/6 left-2/12 sm:left-2/7 md:left-3/11 transform rotate-90" />
                    <span className="relative z-1 text-white text-2lg">
                      {data[period].delivered}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center md:items-baseline gap-y-6 xl:gap-y-4">
          <div className="bg-light-heavenly shadow-blue-2xl relative w-full sm:w-347 h-191 p-7 md:pt-4 md:pl-8 rounded-xs">
            <img
              className="absolute z-0 top-0 left-0"
              src={backgroundLeftPayout}
              alt="background"
            />
            <img
              className="absolute z-0 bottom-0 right-0"
              src={backgroundRightPayout}
              alt="background"
            />
            <span className="relative z-1 block font-bold text-white text-xs sm:text-xmd pb-8">
              Pembayaran hari ini
            </span>
            <span className="relative z-1 block font-bold text-white text-mdm md:text-lm text-center md:text-start pr-5 pt-5 md:pr-0 md:pt-0">
              {data.payouts[period]}
            </span>
          </div>
          <div className="bg-light-heavenly shadow-blue-2xl relative w-full sm:w-347 h-191 p-7 md:pt-4 md:pl-8 rounded-xs">
            <img
              className="absolute z-0 top-0 left-0"
              src={backgroundLeftPayout}
              alt="background"
            />
            <img
              className="absolute z-0 bottom-0 right-0"
              src={backgroundRightPayout}
              alt="background"
            />
            <span className="relative z-1 block font-bold text-white text-xs sm:text-xmd pb-8">
              Pembayaran bulan ini
            </span>
            <span className="relative z-1 block font-bold text-white text-mdm md:text-lm text-center md:text-start pr-5 pt-5 md:pr-0 md:pt-0">
              {data.payouts[period]}
            </span>
          </div>
          <div className="bg-light-heavenly shadow-blue-2xl relative w-347 h-191 p-7 md:pt-4 md:pl-8 rounded-xs hidden xl:block">
            <img
              className="absolute z-0 top-0 left-0"
              src={backgroundLeftPayout}
              alt="background"
            />
            <img
              className="absolute z-0 bottom-0 right-0"
              src={backgroundRightPayout}
              alt="background"
            />
            <span className="relative z-1 block font-bold text-white text-xs sm:text-xmd pb-8">
              Last month payout
            </span>
            <span className="relative z-1 block font-bold text-white text-mdm md:text-lm text-center md:text-start pr-5 pt-5 md:pr-0 md:pt-0">
              {data.payouts[period]}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
