import LocalizedStrings from "react-localization";

export const _onSetLanguage = (lang: string) => {
  resources.setLanguage(lang);
};

export const resources = new LocalizedStrings({
  en_GB: {
    validation: {
      current_password_is_incorrect: "Current password is incorrect",
      // Name
      nameIsRequired: "Name is required",
      // Last name
      lastNameIsRequired: "Last name is required",
      // E-mail
      emailIsRequired: "E-mail is required",
      incorrectEmailAdres: "Incorrect e-mail adress",
      requiredUtf8Characters: "Required utf-8 characters",
      // Password
      passwordIsRequired: "Password is required",
      confirmPasswordIsRequired: "Confirm password is required",
      requiredTheSamePasswords: "Required the same passwords",
      passwordCondition:
        "Password must contain at least 1 upper case letter, 1 lower case letter, 1 numeric character, at least 2 special characters and must contain at least 8 characters and max 32 characters.",
      // SkypeId
      skypeIdIsRequired: "Skype ID is required",
      // Telegram
      telegramIsRequired: "Telegram is required",
      // Country
      countryIsRequired: "Country is required",
      // Rest
      requiredMin3Characters: "Required min 3 characters",
      requiredMin8Characters: "Required min 8 characters",
      requiredMax15Characters: "Required max 15 characters",
      requiredMax20Characters: "Required max 20 characters",
      requiredMax30Characters: "Required max 30 characters",
      requiredMax50Characters: "Required max 50 characters",
      requiredMax191Characters: "Required max 191 characters",
      requiredMax250Characters: "Required max 250 characters",
      requiredMax255Characters: "Required max 255 characters",
      requiredAlphaNumeric: "Required alphaNumeric",
      statusIsRequired: "Status is required (number)",
      requiredMax11Numbers: "Required max 11 numbers",
      required: "Field is required",
      invalidDomain: "Domain invalid",
      duplicatedParam: "Duplicated parameter name",
      invalidParamName: "Invalid parameter name",
      invalidParamValue: "Invalid parameter value",
      invalidUrl: "Invalid URL",
      customInvalidUrlError:
        "IMPORTANT: This URL seems invalid but you can still save it.",
    },
    statistics: {
      callCenterTitle: "Call center classifiers",
      totalDescription:
        "Validated, Send to Call Center, Awaiting, Rejected, Excluded and Sold",
      awaitingDescription:
        "Classification pending (called leads by Call Center)",
      rejectedDescription: "Called but rejected",
      soldDescription: "Verified sales",
      orderCreatedDescription: "Awaiting verification",
      trashDescription: "Wrong numbers etc.",
      excludedDescription: "Doubles and not contacted",
      validatedDescription:
        "Validated by system but not sent to call center yet",
      sendToCcDescription: "Sent to call center but not called yet",
      doubledDescription: "Marked by system",
      verifiedApprovrdRateDescription: "((Order Created + Sold) / Total) * 100",
      approvedRateDescription: "(Sold / Total) * 100",
      leads: "Leads",
      total: "Total",
      awaiting: "Awaiting",
      rejected: "Rejected",
      approved: "Approved",
      approveRate: "Approve rate",
      payoutAmount: "Payout amount",
      sold: "Sold",
      orderCreated: "Order Created",
      trash: "Trash",
      excluded: "Excluded",
      validated: "Validated",
      sendToCC: "Send to Call Center",
      sentcc: "Sent to CC",
      double: "Double",
      verifiedApprovrdRate: "Approve Rate (Potential)",
      approvedRate: "Verified Approve Rate",
      unrecognized: "Unrecognized",
      noMoney: "No money",
      noMoneyDescription: "No money Description",
      awaitingAll: "Awaiting all",
      awaitingAllDescription: "New lead, Validated, Awaiting, Send to CC",
      title: "Statistics leads",
      summary: "Summary statistics",
      numberOfLeadsByOffer: "Number of leads by offer",
      percentageOfApprovedLeads: "Percentage of approved leads",
    },
    tabPane: {
      details: "Details",
      summary: "Summary",
      personalData: "Personal data",
      changePassword: "Change password",
      notifications: "Notifications",
      apiToken: "API Token",
      userSettings: "User settings",
      wallet: "Wallet",
      payoutHistory: "Payout history",
      globalPostbacks: "Global postbacks",
      registrationLink: "Registration link",
    },
    buttons: {
      upload: "Upload",
      yes: "Yes",
      no: "No",
      confirm: "Confirm",
      today: "Today",
      yesterday: "Yesterday",
      thisMonth: "This month",
      lastMonth: "Last month",
      last30Days: "Last 30 days",
      resetFilters: "Clear filters",
      addFilter: "Add filter",
      removeFilter: "Remove filter",
      clearAll: "Clear all",
      submit: "Submit",
      search: "Search",
      cancel: "Cancel",
      refreshTable: "Refresh table",
      updateCampaign: "Update campaign",
      reset: "Reset",
      register: "Register",
      create: "Create",
      login: "Login",
      save: "Save",
      save_new_password: "Save new password",
      send: "Send",
      apply: "Apply",
      generateToken: "Generate token",
      addNewEmployee: "Add new employee",
      addNewProduct: "Add new product",
      add_new: "Add new",
      filter: "Filter",
      addVariable: "Add variable",
      goToMainPage: "Go to main page",
      download: "Download",
      exportXlsx: "Export XLSX",
      downloading: "Downloading",
      new: "New",
      switchToUser: "Switch To",
      backToAdminAccount: "Back To Admin",
      setPostbackForAllProducts: "Set this postbacks for all products",
      show: "Show",
      hide: "Hide",
      enable: "Enable",
      disable: "Disable",
    },
    radioButtons: {
      leadsCreatedDate: "Lead created at date",
      leadsApprovedDate: "Lead approved at date",
      leadsSoldDate: "Lead sold at date",
      leadsCallCenterLastCallDate: "Lead call center last call date",
      salesCountryTimezone: "Sales country trimezone",
      myTimezone: "My timezone",
    },
    labels: {
      visibility: "Visibility",
      active_products: "Active products",
      inactive_products: "Inactive products",
      all_products: "All products",
      are_you_sure: "Are you sure?",
      do_you_want_to_generate_this_campaign:
        "Do you want to generate this campaign?",
      create_leads_on_order_delivered_status_global:
        "Create leads on order delivered status ( GLOBAL )",
      partner_token: "Partner token",
      warnings: "Warnings",
      fails: "Fails",
      success: "Successes",
      comment: "Comment",
      selected: "Selected",
      items: "items",
      global_actions: "Global actions",
      partners_that_registerd_from_your_ref_link:
        "Partners that registerd from your ref link: ",
      beauty: "Beauty",
      health: "Health",
      slimming: "Slimming",
      adult: "Adult",
      other: "Other",
      search: "Search",
      latest_news: "Latest News",
      top_offers: "Top offers",
      found: "Found",
      rows: "rows",
      balance: "Balance",
      hold: "Hold",
      your_manager: "Your manager",
      no_manager: "No manager",
      profile: "Profile",
      log_out: "Log Out",
      reportByTimezone: "Report by time zone",
      dateType: "Date type",
      dateRange: "Date range",
      dateFrom: "Date from",
      dateTo: "Date to",
      setQuickDate: "Set quick date",
      selectedFilters: "Selected filters",
      status: "Status",
      country: "Country",
      product: "Product",
      lead_type: "Lead type",
      partner: "Partner",
      userEmail: "User e-mail",
      payoutScheme: "Payout scheme",
      remarketingCampaign: "Remarketing campaign",
      classifierName: "Classifier name",
      accountManager: "Account manager",
      selectTimeZone: "Select time zone ",
      timezone: "Time zone",
      isActive: "Is active ",
      isExcluded: "Excluded from double",
      isOnline: "Is online ",
      type: "Type",
      name: "Name",
      emailAddress: "E-mail address",
      domain: "Domain",
      user: "User",
      password: "Password",
      repeatPassword: "Repeat password",
      walletName: "Wallet name",
      walletNumber: "Wallet number",
      baseUrl: "Base URL",
      id: "ID",
      email: "E-mail",
      skype: "Skype",
      telegram: "Telegram",
      object: "Object",
      message: "Message",
      section: "Section",
      phone: "Phone number",
      currentPassword: "Current password",
      newPassword: "New password",
      repeatNewPassword: "Repeat new password",
      apiToken: "API Token",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      second_name: "Second name",
      short_name: "Short name",
      role: "Role",
      description: "Description",
      application_access: "Application access",
      pages: "Pages",
      products: "Products",
      addressLine1: "Adress line 1",
      addressLine2: "Adress line 2",
      city: "City",
      state: "State",
      zipCode: "ZIP code",
      isInternal: "Internal",
      partnersAwaitingActivation: "Partners awaiting activation",
      allPartners: "All Partners",
      company: "Company",
      basedOnRole: "Based on role",
      campaigns: "Campaigns",
      instances: "Instances",
      currency: "Currency",
      code: "Code",
      address: "Address",
      operative: "Operative",
      name_eng: "Name (eng)",
      bank_transfer: "Bank transfer",
      district: "District",
      subdistrict: "Subdistrict",
      island: "Island",
      courier: "Courier",
      post_code: "Post code",
      destination_code: "Destination code",
      lead_time: "Lead time",
      lead_time_text: "Lead time text",
      province: "Province",
      delivery_cost: "Delivery cost",
      return_cost: "Return cost",
      instance_fee: "Instance fee",
      cod_fee: "Cod fee",
      admin_fee: "Admin fee",
      is_qualified: "Is qualified",
      is_contacted: "Is contacted",
      affbay_status: "Affbay status",
      is_visible: "Is visible",
      is_top: "Is top",
      is_desktop: "Is deskop",
      is_mobile: "Is mobile",
      url: "URL",
      tracker_id: "Tracker ID",
      tracker_type: "Tracker type",
      focus_ID: "Focus ID",
      instance: "Instance",
      signature: "Signature",
      value: "Value",
      courier_status: "Courier status",
      status_description: "Status description",
      notes: "Notes",
      additional_info: "Additional inf",
      external: "External",
      transactionType: "Transaction type",
      transactionStatus: "Transaction status",
      customVariables: "Custom variables",
      systemVariables: "System Variables",
      newVariable: "New variable",
      affbay_lead_id: "Affbay lead ID",
      amount: "Amount (USD)",
      optional_description: "Optional description",
      title: "Title",
      is_home_page: "Visible on dashboard",
      is_home_page_short: "On dashboard",
      news_type: "News type",
      visible_on_home_page: "Visible on dashboard",
      date_from: "Start form",
      date_from_short: "Start",
      date_to: "End form",
      date_to_short: "End",
      time: "Time",
      hour: "Hour",
      day: "Day",
      week: "Week",
      month: "Month",
      year: "Year",
      today: "Today",
      lastWeek: "Last week",
      lastMonth: "Last month",
      acceptedBalance: "Accepted Balance",
      acceptedBalanceExplanation: "(Sum of filtered records)",
      actualBalance: "Actual Balance",
      requestPayout: "Request Payout",
      notAcceptedBalance: "Not Accepted (Pending) Transactions:",
      earningsChart: "Earnings Chart",
      generatedOn: "Generated on",
      groupBy: "Group by",
      codeName: "Code name",
    },
    informations: {
      active: "Active",
      inactive: "Inactive",
      online: "Online",
      offline: "Offline",
      viewPassword: "View password",
      notAvailable: "n/a",
      qualified: "Qualified",
      notQualified: "Not qualified",
      sessionExpired: "Session expired",
      visible: "Visible",
      invisible: "Invisible",
    },
    modalButtonsTitle: {
      edit: "Edit",
      createNewInstance: "Add",
      createNewClassifier: "Add",
      addNewPage: "Add page",
      addNewCompany: "Add company",
      addNewPartner: "Add new Partner",
      addNewUser: "Add user",
      editUser: "Edit users",
      addNewRole: "Add new role",
      editRole: "Edit role",
      addNewCountry: "Add new country",
      addNewCity: "Add new city",
      addNewCourier: "Add new courier",
      addNewStatus: "Add new status",
      addNewLocation: "Add new location",
      addNewPostCode: "Add new post code",
      addNewMethod: "Add new method",
      addNewProvince: "Add new province",
      addNewChannel: "Add new channel",
      addNewSubdistrict: "Add new subdistrict",
      addNewCategory: "Add new category",
      addNewCampaign: "Add new campaign",
      editCampaign: "Edit campaign",
      addNewLeadsRedirection: "Add",
      addNewSystemVariable: "Add new variable",
      addNewTrafficSource: "Add new variable",
      addNewCouriersApiMapping: "Add new mapping",
      addNewCouriersStatuses: "Add new status",
      addNewSystemClassifier: "Add",
      deposit: "Deposit",
      withdraw: "Withdraw",
      addNew: "Add new",
    },
    modalTitle: {
      edit: "Edit",
      editPage: "Edit page",
      addNewCompany: "Add company",
      addNewUser: "Add new user",
      editUser: "Edit users",
      addNewRole: "Add new role",
      editRole: "Edit role",
      addNewCountry: "Add new country",
      editCountry: "Edit country",
      addNewCity: "Add new city",
      addNewCourier: "Add new courier",
      editCourier: "Edit courier",
      addNewDistrict: "Add new district",
      addNewStatus: "Add new status",
      editStatus: "Edit status",
      addNewLocation: "Add new location",
      editLocation: "Edit location",
      addNewPostCode: "Add new post code",
      editPostCode: "Edit post code",
      addNewMethod: "Add new method",
      editPaymentMethod: "Edit payment method",
      addNewCategory: "Add new category",
      editCategory: "Edit category",
      addNewChannel: "Add new channel",
      editChannel: "Edit channel",
      addNewSubdistrict: "Add new subdistrict",
      editSubdistrict: "Edit district",
      addNewProvince: "Add new province",
      deleteProvince: "Delete province",
      editCampaign: "Edit campaign",
      editLeadsRedirection: "Edit leads redirection",
      addNewSystemVariable: "Add new system variable",
      addNewTrafficSource: "Add new traffic source",
      addNewCouriersApiMapping: "Add new couriers api mapping",
      addNewCouriersStatuses: "Add new couriers status",
      editCouriersStatus: "Edit couriers status",
      editCouriersApiMapping: "Edit couriers api mapping",
      addNewSystemClassifier: "Add new system classifier",
      editSystemClassifier: "Edit system classifier",
      editTrafficSource: "Edit traffic source",
      deposit: "Deposit",
      withdraw: "Withdraw",
      transaction: "Transaction",
      addNew: "Add new",
      edit_news: "Edit news",
      sendFeedback: "Send feedback",
    },
    modalTooltips: {
      see_company_members: "See company members",
      addNewUser: "Add new user",
      addNewRole: "Add new role",
      addNewCountry: "Add new country",
      editCountry: "Edit country",
      addNewCity: "Add new city",
      editCity: "Edit city",
      addNewCourier: "Add new courier",
      editCourier: "Edit courier",
      addNewStatus: "Add new status",
      editStatus: "Edit status",
      addNewLocation: "Add new location",
      editLocation: "Edit location",
      addNewPostCode: "Add new post code",
      editPostCode: "Edit post code",
      addNewMethod: "Add new method",
      editCategory: "Edit category",
      addNewChannel: "Add new channel",
      editChannel: "Edit channel",
      addNewSubdistrict: "Add new subdistrict",
      editSubdistrict: "Edit district",
      addNewProvince: "Add new province",
      deleteProvince: "Delete province",
      addNewCategory: "Add new category",
      addNewSystemVariable: "Add new system variable",
      addNewTrafficSource: "Add new traffic source",
      editTrafficSource: "Edit traffic source",
      addNewCouriersApiMapping: "Add new couriers api mapping",
      addNewCouriersStatuses: "Add new couriers status",
      editCouriersApiMapping: "Edit couriers api mapping",
      editCouriersStatus: "Edit couriers statuse",
      addNewSystemClassifier: "Add new system classifier",
      editSystemClassifier: "Edit system classifier",
      filters: "Filters",
      deposit: "Deposit",
      withdraw: "Withdraw",
    },
    tables: {
      campainFocusTable: "CampainFocusTable",
    },
    title: {
      edit_account_manager: "Edit account manager",
      createNewInstance: "Create new instance",
      createNewClassifier: "Create new classifier",
      editClassifier: "Edit Classifier",
      editPartner: "Edit Partner",
      editEmployee: "Edit Employee",
      editCompany: "Edit company",
      addNewPartner: "Add new Partner",
      addNewEmployee: "Add new Employee",
      addNewCompany: "Add new company",
      selectInstance: "Select instance",
      selectInstanceAndCampaign: "Select instance and campaign",
      campaignsAllInstances: "Campaigns in all instances",
      all: "All",
      allArrowAll: "All -> All",
      allArrow: "All ->",
      arrowAll: "-> All",
      createNewProduct: "create new product",
      campaignsActivityStatus: `Change classifier activity status`,
      // partnerActivityStatus: `Change Partner activity status`,
      // employeesActivityStatus: `Change Employee activity status`,
      userActivityStatus: `Change users activity status`,
      filters: "Filters",
      activityStatus: `Change activity status`,
      addNewPage: "Add new page",
      addNewCampaign: "Add new campaign",
      addNewLeadsRedirection: "Add new leads redirection",
      notFoundLabel: "Sorry, the page you visited does not exist.",
      addNew: "Add new",
      new: "New",
      change: "Change",
      inProgressTitle: "Page in progress",
      inProgressDesc: "Sorry, the page you visited is still in progress.",
    },
    columnTitle: {
      product_sku: "Product SKU",
      product_sku_short: "SKU",
      comment: "Comment",
      classifier_end: "Classifier end",
      phone_origin: "Phone (origin)",
      id: "ID",
      type: "Type",
      country: "Country",
      country_short: "Co.",
      name: "Name",
      second_name: "Second name",
      short_name: "Short name",
      campaign_name: "Campaign name",
      domain: "Domain",
      user: "User",
      password: "Password",
      password_short: "Pass",
      url: "Base campain URL",
      url_short: "URL",
      active: "Actual campaign activity status",
      active_short: "Active",
      // classifier_is_qualified: 'Actual classifier qualified status',
      // classifier_is_qualified_short: 'Qualified',
      online: "Actual campaign online status",
      online_short: "Online",
      created: "Date created",
      created_short: "Created",
      updated: "Recently Updated",
      updated_short: "Updated",
      options: "Options",
      instance: "Instance",
      focus_instance: "Focus instance",
      classifiers_external: "Classifiers external",
      affbay_status: "Affbay status",
      status: "Status",
      qualified: "Qualified",
      approvedAt: "Approved",
      approvedAt_short: "Date approved at",
      classifier: "Classifier",
      product: "Product",
      partner: "Partner",
      payout: "Payout",
      payout_scheme: "Payout Schema",
      payout_scheme_short: "Scheme",
      phone: "Phone",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      role: "Role",
      description: "Description",
      application_access: "Application access",
      avalable_in_affbay: "Available in Affbay",
      avalable_in_affbay_short: "In Affbay",
      avalable_for_sale: "Available for sale",
      avalable_for_sale_short: "For sale",
      base_price: "Base prices",
      base_price_short: "Price",
      visible_for_partners: "Visible for partners",
      visible_for_partners_short: "For partners",
      categories: "Categories",
      landing: "Landing",
      preview: "Preview",
      is_visible: "Visible",
      is_top: "Top",
      is_mobile: "Mobile",
      is_desktop: "Desktop",
      deleted_at: "Deleted",
      accountCounter: "Account counter",
      isInternal: "Internal",
      partnerToken: "Partner token",
      email: "Email",
      manager: "Manager",
      company: "Company",
      skype: "Skype",
      telegram: "Telegram",
      is_active_short: "Active",
      key: "Key",
      focusId: "Focus ID",
      is_operative: "Operative",
      address: "Address",
      code: "Code",
      currency: "Currency",
      is_active: "Active",
      name_eng: "Name (eng)",
      bank_transfer: "Bank transfer",
      city: "City",
      province: "Province",
      district: "District",
      subdistrict: "Subdistrict",
      postCode: "Post code",
      courier: "Courier",
      lead_time: "Lead time",
      lead_time_text: "Lead time text",
      district_code: "District code",
      island: "Island",
      deliver_cost: "Deliver cost",
      resturn_cost: "Return cost",
      insurance_fee: "Insurance fee",
      code_fee: "Code fee",
      admin_fee: "Admin fee",
      dalivery_cost: "Delivery cost",
      permission_id: "Permission ID",
      is_qualified: "Qualified",
      is_contacted: "Contacted",
      is_qualified_short: "Qualified",
      value: "Value",
      courier_name: "Courier name",
      courier_name_short: "Courier",
      courier_status: "Courier status",
      status_description: "Status description",
      notes: "Notes",
      external: "External",
      additional_inf: "Additional informations",
      additional_inf_short: "Informations",
      view: "View",
      is_manual_short: "Manual",
      is_manual: "Is Manual",
      is_successful_short: "Successful",
      is_successful: "Is Successful",
      response_time_short: "Time",
      response_time: "Response time",
      response_status_short: "Status",
      response_status: "Response Status",
      response_text_short: "Text",
      response_text: "Response Text",
      title: "Title",
      is_home_page: "Visible on dashboard",
      is_home_page_short: "On dashboard",
      news_type: "News type",
      visible_on_home_page: "Visible on dashboard",
      date_from: "Start form",
      date_from_short: "Start",
      date_to: "End form",
      date_to_short: "End",
      scheme: "Scheme",
      leadId: "Lead ID",
      accepted: "Accepted",
      date: "Date",
      params: "Params",
      exclude_from_double: "Exclude from double",
      exclude_from_double_short: "Double Excl.",
    },
    tooltips: {
      see_company_members: "See company members RU",
      hide_postbacks: "Hide postbacks",
      show_postbacks: "Show postbacks",
      copyKey: "Copy key",
      editPage: "Edit page",
      deletePage: "Delete page",
      cantDeleteAdminSuperAdmin: `Can't delete Admin/Super admin`,
      userActivityStatus: "Change users activity status",
      deleteUser: "Delete user",
      changeRoleActiveStatus: "Change roles sctive status",
      editRole: "Edit role",
      deleteRole: "Delete role",
      changeActiveStatus: "Change active status",
      viewCampaign: "View campaign",
      viewCompany: "View company",
      changeQualifiedStatus: "Change qualified status",
      deleteStatus: "Delete status",
      deletePostCode: "Delete post code",
      deleteCstegory: "Delete category",
      change_activity_status: "Change activity status",
      delete_leads_direction: "Delete direction",
      edit_leads_direction: "Edit leads redirection",
      delete: "Delete",
      edit: "Edit",
      accept: "Accept",
      reject: "Reject",
    },
    statement: {
      unrecognized_error: "Unrecognized error",
      refresh_page: "Refresh page",
      your_account_has_been_created: "Your account has been activated",
      your_account_has_been_created_message:
        "Now you need to wait for our specialist to accept your account. ",
      sorry_we_could_not_get_your_data: `Sorry, we couldn't get your data`,
      databaseConnectionError:
        "Something went wrong while connecting to the database!",
      databaseConnectionSuccess: "Udane pobranie z bazy danych!",
      instanceCreated: "Udane dodanie instancji!",
      classifierCreated: "Classifier created!",
      classifierUpdated: "Classifier updated!",
      thePropsAreTheSame:
        "Wartości w polach nie uległy zmianie - do ustalenia co z tym robimy",
      campaignUpdated: "Udana aktualizacja kampanii!",
      qulifiedUpdate: "Udana zmiana kwalifikacji",
      app_user_updated: "App user updated!",
      app_user_created: "App user created!",
      status_updated: "Status updated!",
      there_was_a_problem_removing_app_user:
        "There was a problem removing app user",
      app_user_deleted: "App user deleted",
      there_was_a_problem_removing_role: "There was a problem removing role",
      there_was_a_problem_removing_permission:
        "There was a problem removing permission",
      there_was_a_problem_creating_role: "There was a problem creating role",
      user_role_updated: "User role updated!",
      companie_created: "Companie created!",
      companie_updated: "Companie updated!",
      there_was_a_problem_removing_city: "There was a problem removing city",
      city_deleted: "City deleted!",
      city_created: "City created!",
      city_updated: "City updated!",
      there_was_a_problem_removing_country:
        "There was a problem removing country",
      country_created: "Country deleted!",
      country_updated: "Companie updated!",
      there_was_a_problem_removing_courier:
        "There was a problem removing courier",
      courier_deleted: "Courier deleted!",
      courier_updated: "Courier updated!",
      courier_created: "Courier created!",
      there_was_a_problem_removing_district:
        "There was a problem removing district",
      district_deleted: "District deleted!",
      district_updated: "District updated!",
      district_created: "Dictrict created!",
      there_was_a_problem_removing_location:
        "There was a problem removing location",
      location_deleted: "Location deleted!",
      location_updated: "Location updated!",
      location_created: "Location created!",
      there_was_a_problem_removing_payment_method:
        "There was a problem removing payment method",
      payment_method_deleted: "Payment method deleted!",
      payment_method_updated: "Payment method updated!",
      payment_method_created: "Payment method created!",
      there_was_a_problem_removing_post_code:
        "There was a problem removing post code",
      post_code_deleted: "Post code deleted!",
      post_code_updated: "Post code updated!",
      post_code_created: "Post code created!",
      there_was_a_problem_removing_product_category:
        "There was a problem removing product category",
      product_category_deleted: "Product category deleted!",
      product_category_updated: "Product category updated!",
      product_category_created: "Product category created!",
      there_was_a_problem_removing_province:
        "There was a problem removing province",
      province_deleted: "Province deleted!",
      province_updated: "Province updated!",
      province_created: "Province created!",
      there_was_a_problem_removing_sales_channel:
        "There was a problem removing sales channel",
      sales_channel_deleted: "Sales channel deleted!",
      sales_channel_updated: "Sales channel updated!",
      sales_channel_created: "Sales channel created!",
      there_was_a_problem_removing_status:
        "There was a problem removing status",
      status_deleted: "Status deleted!",
      status_created: "Status created!",
      there_was_a_problem_removing_subdistrict:
        "There was a problem removing subdistrict",
      subdistrict_deleted: "Subdistrict deleted!",
      subdistrict_updated: "Subdistrict updated!",
      subdistrict_created: "Subdistrict created!",
      employee_updated: "Employee updated!",
      employee_created: "Employee created!",
      filters_confirmed: "Filtry potwierdzone",
      filters_have_been_reset: "filters have been reset",
      there_was_a_problem_removing_page: "There was a problem removing page",
      page_deleted: "Page deleted!",
      page_updated: "Page updated!",
      page_created: "Page created!",
      partner_updated: "partner updated!",
      partner_created: "partner created!",
      change_permission_status: "Change permissions status",
      there_was_a_problem_removing_campaign:
        "There was a problem removing campaign",
      there_was_a_problem_creating_campaign:
        "There was a problem creating campaign",
      campaign_deleted: "Campaign deleted!",
      campaign_updated: "Campaign updated!",
      campaign_created: "Campaign created!",
      role_deleted: "Role deleted!",
      choose_out_of_scope: "Choose out of the scope!",
      date_changed: "Date changed",
      session_expired: "Session expired",
      session_expired_description:
        "Your session has expired. Please login again to continue working",
      incorrect_login_information:
        "Your login information was incorrect. Please check and try again",
      there_was_a_problem_removing_system_variable:
        "There was a problem removing system variable",
      system_variable_deleted: "System variable deleted!",
      system_variable_updated: "System variable updated!",
      system_variable_created: "System variable created!",

      traffic_source_deleted: "Traffic source deleted!",
      traffic_source_updated: "Traffic source updated!",
      traffic_source_created: "Traffic source created!",

      there_was_a_problem_removing_couriers_api_mapping:
        "There was a problem removing couriers api mapping",
      couriers_api_mapping_deleted: "Couriers api mapping deleted!",
      couriers_api_mapping_updated: "Couriers api mapping updated!",
      couriers_api_mapping_created: "Couriers api mapping created!",
      system_classifier_created: "System classifierl created!",
      system_classifier_updated: "System classifierl updated!",
      updated: "Updated!",
      created: "Created!",
      deleted: "Deleted!",
      success: "Success!",
      accepted: "Accepted!",
      rejected: "Rejected",
      noCompanyIdAssigned:
        "Your account has no company assigned. Please contact us through support panel.",
      noDataToDisplay: "Sorry, there is no data to display",
    },
    tabs: {
      createNewProduct: "create new product",
      pricing: "Pricing",
      conversion: "Conversion",
      affbayVisibility: "Affbay visibility",
      landings: "Landings / Prelandings",
    },
    navigationItems: {
      help: "Help",
      dashboard: "Dashboard",
      guide: "Guide",
      statistics: "Statistics",
      settings: "Settings",
      financeStatistics: "Finance statistics",
      leads: "Leads",
      employees: "Employees",
      products: "Products",
      pages: "Pages",
      landings: "Landings",
      companies: "Companies",
      partners: "Partners",
      campaigns_partner: "Campaigns",
      campaigns_admin: "Campaigns admin",
      finance: "Finance",
      financeAdmin: "Finance admin",
      tools: "Tools",
      news: "News",
      news_admin: "News admin",
      support: "Support",
      faq: "FAQ",
      contact: "Contact",
      login: "Login",
      register: "Register",
      offers: "Products",
      create_new_order: "Create new order",
      orders: "Orders",
      couriersStatusMapping: "Status mapping",
      account_managers: "Account Managers",
    },
    subNavigationItem: {
      k8_services: "K8 Services",
      sales: "Sales",
      sales_p2: "P2",
      sales_resale: "P3",
      sales_winback: "Winback",
      administration: "Administration",
      appRemarketing: "Remarketing - P2",
      appUsers: "Users",
      appUsersRole: "Users role",
      webSocket: "WebSocket",
      appUsersPermissions: "Users permissions",
      // Call center
      callCenter: "Call center",
      cc_instances: "Instances",
      cc_campaigns: "Campaigns",
      cc_classifiers: "Classifiers",
      li_classifiers_cc: "Mapping classifiers",
      cc_webhooks: "Webhooks",
      cc_leads_direction: "Leads redirection",
      // Settings
      setting: "Setting",
      api: "API",
      payment: "Payment",
      postbacks: "Postbacks",
      referralHistory: "Referral history",
      security: "Security",
      settings: "Settings",
      // Dictionaries
      dictionaries: "Dictionaries",
      countries: "Countries",
      cities: "Cities",
      couriers: "Couriers",
      districts: "Districts",
      subdistricts: "Subdistricts",
      statuses: "Statuses",
      locations: "Locations",
      postCodes: "Post cods",
      paymentMethods: "Payment methods",
      productCategories: "Product cat.",
      salesChannels: "Sales channels",
      provinces: "Provinces",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers mapping",
      couriers_statuses: "Couriers statuses",
      system_classifiers: "System classifiers",
    },
    helpView: {
      title: 'Help',
      content: 'The content will come soon.',
    },
    others: {
      of: "of",
      rows: "rows",
    },
    contentTitle: {
      support: "Support",
    },
    contentText: {
      alreadyHaveAnAccount: "Already have an account?",
      iDontHaveAnAccount: "I don't have an account.",
      rememberThePassword: "Remember the password?",
      support:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
    },
    links: {
      all_news: "All news",
      login: "Login",
      registration: "Registration",
      forgotYourPassword: "Forgot your password?",
    },
    placeholders: {
      enterName: "Enter name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
      repeatPassword: "Repeat password",
      selectCountry: "Select country",
      notRequired: "Not required",
      select: "Select...",
      globalSearch: "Global search",
      search: "Search",
      findProduct: "Find product",
      choose_affbay_status: "Choose affbay status",
      choose_country: "Choose country",
      choose_company: "Choose company",
      choose_product: "Choose product",
      choose_partner: "Choose partner",
      choose_scheme: "Choose scheme",
      choose_classifier: "Choose classifier",
      choose_remarketing_campaign: "Choose campaign",
      enter_min_4_letters: "Entere min 4 letters",
    },
    pageTitle: {
      k8_services: "K8 Services",
      dashboard: "Dashboard",
      account_managers: "Account managers",
      loginContent: "Log In",
      registerContent: "Registration",
      resetPasswordContent: "Restore password",
      employees: "All employees PL + All employees Call center",
      products: "Products",
      pages: "Pages",
      addNewCompany: "Add company",
      companies: "Companies",
      partners: "Partners",
      administrationUsers: "Administration - Users",
      administrationUsersRole: "Administration - Users Role",
      instances: "Instances",
      instancesForCCSimplified: "Call center API connection",
      campaigns: "Campaigns",
      classifiers: "Classifiers",
      mappingClassifiers: "Mapping classifiers",
      webhooks: "Webhooks",
      countriesDictionary: "Countries dictionary",
      citiesDictionary: "Cities dictionary",
      couriersDictionary: "Couriers dictionary",
      districtsDictionary: "Districts dictionary",
      statusesDictionary: "Statuses dictionary",
      locationsDictionary: "Locations dictionary",
      postCodeDictionary: "Post codes dictionary",
      paymentMethodDictionary: "Payment methods dictionary",
      productCategoriesDictionary: "Product categories dictionary",
      salesChannels: "Sales channels dictionary",
      subdistrictsDictionary: "Subdistricts dictionary",
      provincesDictionary: "Provinces dictionary",
      campaigns_admin: "Campaigns - Admin",
      orders: "Orders",
      leads_directions: "Leads redirections",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers api mapping",
      system_classifiers: "System classifiers",
      finance: "Finance",
      news_admin: "News Admin",
      finance_admin_view: "Finance admin view",
      resaleP3: "P3 Resale",
      resaleP2: "P2 Resale",
    },
    productsFormMain: {
      editProduct: "Edit product",
      createNewProduct: "Create new product",
      englishName: "English name",
      currency: "Currency",
      callCenter: "Call Center",
      sku: "SKU",
      originalName: "Original name",
      shortName: "Short name",
      printName: "Print name",
      categories: "Categories",
      availableInAffbay: "Available in AFFBAY",
      testProduct: "Test product",
      availableForSale: "Available for sale",
      active: "Active",
      country: "Country",
      sales: "Sales",
      mainCamapignId: "Main campaign [id]",
      resellCampaignId: "Resell campaign [id]",
      productionCost: "Production cost (USD)",
      productionCostLocalCurrency: "Production cost (local currency)",
      affbayVisibility: "Affaby Visibility",
      visibleForPartners: "Visible for partners",
      visibleOnHomePage: "Visible on home page (top offers)",
      isRestricted: "Is restricted",
      defaultGlobalPayout: "Default global payout (USD)",
      defaultPayoutScheme: "Default payout scheme",
      cpa: "CPA",
      cpl: "CPL",
      landingPriceForConsumer: "Landing price for consumer",
      approveVisibleOnPage: "Approve visible on page (%)",
      payoutVisibleOnPage: "Payout visible (string) on page (USD)",
      descriptionEN: "Description EN",
      descriptionRU: "Description RU",
      traffic_sources_en: "Traffic sources (description) EN",
      traffic_sources_ru: "Traffic sources (description) RU",
      terms_en: "Terms EN",
      terms_ru: "Terms RU",
      conditions_en: "Conditions EN",
      conditions_ru: "Conditions RU",
      productsWillNotBeAvailableInAffbay:
        "Products will not be available in AFFBAY",
      updateCampaigns: "Refresh campaings list",
      save: "Save",
      create: "Create",
      reset: "Reset",
      delivered: "Delivered",
      av_order: "Av order",
      products: "Products",
      payout: "Payout",
      advertize: "Advertize",
      title: "Choose product to advertise",
    },
    checkBoxes: {
      iWantToReceiveImportantNewsByEmail:
        "I want to receive important news by email (recommended)",
      iWantToReceivePromotionslNewsByEmail:
        "I want to receive promotional news by email",
      iWantToReceiveInformationAboutChangesInOffersByEmail:
        "I want to receive information about changes in offers by email",
      iWantToReceiveOffersOfOffersByEmail:
        "I want to receive offers of offers by email",
      add_to_affbay_classifiers_library: "Add to Affbay Classifiers library",
    },
    landingPage: {
      about: "About",
      topOffers: "Top offers",
      contact: "Contact",
      logIn: "Log In",
      certifiedProducts: "CERTIFIED PRODUCTS FROM DIRECT ADVERTISER",
      afiliateNetwork: "Affiliate Network in Thailand",
      saudiArabAndIndonesia: "Saudi Arabia and Indonesia",
      becomeAPartner: "Become a partner",
      activePartners: "Active partners",
      offersInAsia: "Offers in Asia",
      yearsOnTheMarket: "Years on the market",
      countries: "Countries",
      aboutText:
        "Affbay is a direct advertiser in the markets of Asia and the Arab countries. We do not resell the offers, all products are our own. Here you will find dozens of offers on the topic of beauty, health and weight loss with payments from $ 18 higher for a confirmed application. We work on models CPA, CPL, CPI.",
      regularPayouts: "Regular Payouts",
      regularPayoutsText: "Payouts without delay on most payment systems.",
      highPayouts: "High Payouts",
      highPayoutsText: "From $ 18 and above for a confirmed application.",
      ownCallCenter: "Own call center",
      ownCallCenterText:
        "Experienced CC specialists performing work efficiently.",
      personalManager: "Personal manager",
      personalManagerText:
        "Responsive support will help you solve any problems.",
      weDontHaveTopOffers: "We don't have any top offers right now.",
      howCanWeHelpYou: "How can we help you?",
      writeUs: "Write us about your concerns.",
      becomeOurPartner: "Become our partner and start earning right now",
      affbayAsia: " Affbay.asia",
      ownedBy: "Owned By: Izabella Frolushkin FZE",
      privacyPolicy: "Privacy Policy",
      termsAndConditions: "Terms and Conditions",
      send: "Send",
      name: "Name",
      email: "E-mail",
      object: "Object",
      message: "Message",
      thankYou: "Thank you",
      youWillBeHearing:
        "Your message was sent. We will handle your issue with 48 hours.",
      startEarning: "Start earning",
      sitePrice: "Site price",
      approve: "Approve",
      payouts: "Payouts",
      country: "Country",
    },
    loginPage: {
      title: 'Sign in with Social Media',
      logIn: "Log In",
      registration: "Registration",
      email: "E-mail",
      password: "Password",
      forgotYourPassword: "Forgot your password?",
      logIntoThePlatform: "Log into the platform",
      iDontHaveAnAccount: "I don't have an account.",
      send_request_again: "Send request again",
    },
    registrationPage: {
      registration: "Registration",
      name: "Name",
      email: "E-mail",
      password: "Password",
      repeatPassword: "Repeat password",
      country: "Country",
      skype: "Skype",
      telegram: "Telegram",
      notRequired: "Not required",
      iAgreeToAffbayAsia: "I agree to Affbay Asia",
      privacyPolicy: "Privacy Policy",
      and: "and",
      termsAndConditions: "Terms and Conditions",
      nextStep: "Next Step",
      alreadyHaveAnAcount: "Already have an account?",
      logIn: "Log in",
      thxForReg: "Thank you for registration",
      toStartWork: "To start work please provide us few information",
      surveyIsNotAvailable: "Survey is not available",
      thisAccountIsA: "This account is a...",
      whichgeos: "Which geos you have worked with before?",
      whatgeo: "What geos do you want to work with?",
      whatTypeOfTraffic: "What type of traffic are you using?",
      whatBugdets: "What budgets did you work with?",
      whatDoYouPrefer: "What do you prefer - S2S or API?",
      submitMyApplication: "Submit my application",
      enterYourName: "Enter your name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
    },
    submitSuccess: {
      congrats: "Congratulations, your account has been created!",
      nowYouCan: "Now you can login to your account",
      logIn: "Log In",
    },
    resetPassword: {
      password_changed: "Your password has been changed. You can login now.",
      typeTheAdress:
        "Type the address linked to your account and we'll send you password reset instructions. Also, check your spam folder.",
      email: "E-mail",
      set_new_password: "Set new password",
      remember: "Remember your password?",
      logIn: "Log In",
      checkEmail: "Check your e-mail",
      withinAFew:
        "Within a few minutes you will receive an email. In this message we will send you a link to set new password.",
      new_password: "New password",
    },
    supportView: {
      support: "Support",
      ifYouNeedHelp:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
      subject: "Subject",
      message: "Message",
      send: "Send",
    },
    newsView: {
      news: "News",
      allNews: "All news",
    },
    offersView: {
      offers: "Offer details",
      offersFound: "offers found",
      show: "Show:",
      sortBy: "Sort by:",
      category: "Category",
      conversionType: "Conversion type",
      sort: "Sort",
      accessRequest: "Access Request",
      wirteUs:
        "Write us about traffic sources, with whom you work and your experience.",
      send: "Send",
      thankYou: "Thank you",
      youWillBeHearing: "You will be hearing from us soon!",
      sitePrice: "Site price",
      payouts: "Payouts",
      country: "Country",
      originalCountry: "Original country",
      readMore: "Read more",
      exampleCodePhp: "Download example",
    },
    offersDetails: {
      sku: "SKU",
      // tabs
      description: "Description",
      targetURL: "Target URL",
      postbacks: "Postbacks",
      trafficSources: "Traffic sources",
      news: "News",
      terms_and_conditions: "Terms & conditions",
      landings_tab: "Landings",
      // product card
      payout: "Payout",
      approve: "Approve",
      sitePrice: "Site price",
      category: "Category",
      country: "Country",
      devices: "Devices",
      // target url
      step_1:
        "Step 1. Choose landing page and prelanding. You can choose multiple items which be opened randomly in your campaign",
      step_2:
        "Step 2. Enter new name for your campaign and click Generere URL button to save it.",
      landings: "Landings",
      prelandings: "Prelandings",
      campaign_name: "Campaign name",
      generateURL: "Generate URL",
      allProductCampaigns: "All product campaigns",
      name: "Name",
      prelanding: "Prelanding",
      landing: "Landing",
      URL: "URL",
      youHaveTo: "You have to choose LP and type Campaign name",
      noLandings: "No pages to display",
      // postbacks
      urlForSales: "Postback URL for Sales (means APPROVE for CPL)",
      urlForHolds: "Postback URL for Holds (means APPROVE for CPL)",
      urlForRejects: "Postback URL for Rejects (means APPROVE for CPL)",
      urlForTrash: "Postback URL for Trash (means REJECTED for CPL)",
      urlForDuplicated: "Postback URL for Duplicated (means REJECTED for CPL)",
      urlForExcluded: "Postback URL for Excluded (means REJECTED for CPL)",
      urlForValidated: "Postback URL for Validated",
      googleTrackingID: "Google tracking ID",
      facebookPixelID: "Facebook pixel ID",
      customPixelID: "Custom pixel ID",
      useGlobalPostbacks: "Use global postbacks",
      createPostback: "Create postback",
      updatePostback: "Update postback",
      fillInTheData: "Fill in the data below to create a new postback",
      protocol: "Protocol",
      domain: "Domain",
      exampleCOM: "example.com",
      extraPathParameter: "Extra path parameter",
      folderSlash: "/folder/subfolder",
      parameter: "Parameter",
      addNew: "Add new",
      postbackURLPreview: "Postback URL Preview",
      save: "Save",
      basic: "Basic",
      advanced: "Advanced",
      postbackSavedSuccessfully: "Postback saved successfully",
      postbackSavedWithWarning: "Postback saved with warning",
      postbackNotSaved: "Unable to saved postback",
      clearPostback: "Clear Postback",
    },
    profileView: {
      personlaData: "Personal data",
      changePassword: "Change password",
      notifications: "Notofications",
      apitoken: "API Token",
      // personal data
      email: "E-mail",
      name: "Name",
      phone: "Phone number",
      telegram: "Telegram",
      skype: "Skype",
      timeZone: "Time zone",
      apply: "Apply",
      cancel: "Cancel",
      id_referral: "Registration link",
      // change password
      currentPass: "Current password",
      newPass: "New password",
      repeatNewPass: "Repeat new password",
      change: "Change",
      // notifications
      importantNews: "I want to receive important news by email (recommended)",
      promotionalNews: "I want to receive promotional news by emial",
      information:
        "I want to receive information about changes in offers by email",
      offers: "I want to receive offers of offers by email",
      customVariablesList: "Custom Variables List",
      postbacksList: "Postbacks List",
      // api token
    },
    toolsView: {
      open: "Open",
      tools: "Tools",
      // faq
      faq: "FAQ",
      answersToFAQ:
        "Answers to frequently asked questions regarding payments for their implementation and Affbay in general",
      readMore: "Read more",
      // API Documentation
      apiDocs: "API Documentation",
      leadSendExample: "Send lead example form",
      readOurDocs:
        "Read our documentation to get answers to many questions regarding the API.",
      readDocs: "Read documentation",
      // postback documentation
      postbackDocs: "Postback Documentation",
      postbacksNotify:
        "Read our documentation to get answers to many questions regarding the API.",
      // integration archi model
      integrationArchiModel: "Integration Architecture Model",
      hardToUnderstand:
        "Hard to understand how Affbay works? Read more about a few examples of our architecture.",
      // number validation
      numberValidation: "Number validation",
      checkingTheNumber:
        "Checking the number is the key to improving the quality of customer acquisition and revenue.",
      // longpage widget
      longpageFromWidget: "Longpage form widget",
      longpageFromWidgetDescription:
        "Include our form into your webpage. Simply paste our code, add your token and sku to start generating new leads.",
    },
    select: {
      select_timezone: "Select timezone",
      select_status: "Select status",
      select_available: "Select available",
      select: "Select",
      select_country: "Select country",
    },
    spin: {
      loading: "Loading...",
    },
    faq: {
      header_1: "What is Affbay Asia?",
      header_2: "What kinds of integration do we support?",
      header_3: "Do you need your own tracker to integrate by S2S?",
      header_4: "Which parameters are supported by Affbay (S2S integration)?",
      header_5: "How to add parameters you want to receive?",
      header_6: "Which parameter is Affbay’s?",
      header_7: "How to configure general postbacks for S2S?",
      header_8: "What is postback for sales, holds, rejected?",
      header_9: "How to configure getting postback for concrete offer?",
      header_10: "How to add payout to postback url?",
      header_11: "How should a postback url look like?",
      content_1:
        "We’re direct advertisers in Indonesia and Thailand, we have our own high conversion COD offers. More geos in Asia coming soon!",
      content_2:
        "We can integrate with you by S2S or by API. Documentation for API is provided on our website in section Offers (at the top of webpage). Choose offers you like and click Details.",
      content_3:
        "Affbay is a user friendly platform, which provides you with your personal account with dashboard and requires no tracker, although we can provide you S2S postback integration with your trackers.",
      content_4:
        'You can add any parameter that is supported by your tracker. See "How to add parameters you want to receive?".',
      content_5:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Just add any parameter without brackets {} () and put it also in your postback url.",
      content_6: [
        "Our built in parameters are:",
        "click_id - global identifier (UUID) of a specific lead",
        "campaign_id - global identifier (UUID) of a specific campaign",
        "aff_id - global identifier (UUID) of a specific user (you).",
        "If you specify a custom variable named as one of the above, postback will use it, substituting your tracker data instead of our default data. We discourage this to avoid confusion.",
      ],
      content_7:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Here you can add you general postback url. If you want to receive postbacks for sales – just put your postback url in this field, the same for postback for holds and rejected. Also you can add Facebook Pixel and Google Tracking ID for all the offers.",
      content_8:
        "If you want to receive postback for approved leads – choose postback for sales. Postback for holds means getting postback for every lead that came into our system. Postback for rejected means getting postback for leads that were rejected – invalid phone number or leads not being reached by Call Center in a month.",
      content_9:
        "Enter section Offers (at the top of webpage) -> choose offers and click Details -> there you can add postback urls and also Facebook Pixel and Google Tracking ID for specific offer.",
      content_10: "&amount=13&payout_type=pps¤cy=USD",
      content_11:
        "https://callbacks.bitterstrawberry.org/?token=0c4c1249825285a2997bacf09c09ec28&hash={hash}&transaction_id={click_id}&amount=13&payout_type=pps¤cy=USD",
    },
    news_type: {
      all_news: "All news",
      new: "New",
      important: "Important",
      info: "Info",
    },
    setting_page: {
      click_id: "Click ID",
      check_click_id: "Check click ID doubles",
      click_id_days: "Number of days to check",
    },
  },
  id_ID: {
    validation: {
      current_password_is_incorrect: "Password saat ini salah",
      nameIsRequired: "Nama wajib diisi",
      lastNameIsRequired: "Nama belakang wajib diisi",
      emailIsRequired: "Email diperlukan",
      incorrectEmailAdres: "Alamat email salah",
      requiredUtf8Characters: "Karakter utf-8 yang diperlukan",
      passwordIsRequired: "katakunci dibutuhkan",
      confirmPasswordIsRequired: "Konfirmasi kata sandi diperlukan",
      requiredTheSamePasswords: "Diperlukan kata sandi yang sama",
      passwordCondition:
        "Kata sandi harus mengandung minimal 1 huruf besar, 1 huruf kecil, 1 karakter numerik, minimal 2 karakter khusus dan harus berisi minimal 8 karakter dan maksimal 32 karakter.",
      skypeIdIsRequired: "ID Skype diperlukan",
      telegramIsRequired: "Telegram diperlukan",
      countryIsRequired: "Negara diperlukan",
      requiredMin3Characters: "Diperlukan minimal 3 karakter",
      requiredMin8Characters: "Diperlukan minimal 8 karakter",
      requiredMax15Characters: "Diperlukan maksimal 15 karakter",
      requiredMax20Characters: "Diperlukan maksimal 20 karakter",
      requiredMax30Characters: "Diperlukan maksimal 30 karakter",
      requiredMax50Characters: "Diperlukan maksimal 50 karakter",
      requiredMax191Characters: "Diperlukan maksimal 191 karakter",
      requiredMax250Characters: "Diperlukan maksimal 250 karakter",
      requiredMax255Characters: "Diperlukan maksimal 255 karakter",
      requiredAlphaNumeric: "AlfaNumerik yang diperlukan",
      statusIsRequired: "Status wajib diisi (angka)",
      requiredMax11Numbers: "Diperlukan maksimal 11 angka",
      required: "Bidang wajib diisi",
      invalidDomain: "Domain tidak valid",
      duplicatedParam: "Nama parameter duplikat",
      invalidParamName: "Nama parameter tidak valid",
      invalidParamValue: "Nilai parameter tidak valid",
      invalidUrl: "URL tidak valid",
      customInvalidUrlError:
        "PENTING: URL ini sepertinya tidak valid namun Anda masih dapat menyimpannya.",
    },
    statistics: {
      callCenterTitle: "Pengklasifikasi pusat panggilan",
      totalDescription:
        "Divalidasi, Kirim ke Call Center, Menunggu, Ditolak, Dikecualikan dan Dijual",
      awaitingDescription:
        "Klasifikasi tertunda (disebut prospek oleh Call Center)",
      rejectedDescription: "Ditelepon tapi ditolak",
      soldDescription: "Penjualan terverifikasi",
      orderCreatedDescription: "Menunggu verifikasi",
      trashDescription: "Nomor yang salah dll.",
      excludedDescription: "Ganda dan tidak dihubungi",
      validatedDescription:
        "Telah divalidasi oleh sistem tetapi belum dikirim ke call center",
      sendToCcDescription: "Sudah dikirim ke call center tapi belum ditelepon",
      doubledDescription: "Ditandai oleh sistem",
      verifiedApprovrdRateDescription:
        "((Pesanan Dibuat + Terjual) / Total) * 100",
      approvedRateDescription: "(Terjual / Total) * 100",
      leads: "Memimpin",
      total: "Total",
      awaiting: "Menunggu",
      rejected: "Ditolak",
      approved: "Disetujui",
      approveRate: "Menyetujui tarif",
      payoutAmount: "jumlah pembayaran",
      sold: "Terjual",
      orderCreated: "Pesanan Dibuat",
      trash: "Sampah",
      excluded: "Pengecualian",
      validated: "Divalidasi",
      sendToCC: "Kirim ke Pusat Panggilan",
      sentcc: "Dikirim ke CC",
      double: "Dobel",
      verifiedApprovrdRate: "Tingkat Persetujuan (Potensi)",
      approvedRate: "Tingkat Persetujuan Terverifikasi",
      unrecognized: "Tidak dikenali",
      noMoney: "Tidak ada uang",
      noMoneyDescription: "Tidak ada uang Deskripsi",
      awaitingAll: "Menunggu semuanya",
      awaitingAllDescription: "Prospek baru, Divalidasi, Menunggu, Kirim ke CC",
      title: "Statistik mengarah",
      summary: "Statistik ringkasan",
      numberOfLeadsByOffer: "Jumlah prospek berdasarkan penawaran",
      percentageOfApprovedLeads: "Persentase prospek yang disetujui",
    },
    tabPane: {
      details: "Detail",
      summary: "Ringkasan",
      personalData: "Data pribadi",
      changePassword: "Ganti kata sandi",
      notifications: "Pemberitahuan",
      apiToken: "Token API",
      userSettings: "Pengaturan pengguna",
      wallet: "Dompet",
      payoutHistory: "Riwayat pembayaran",
      globalPostbacks: "Postback global",
      registrationLink: "Tautan pendaftaran",
    },
    buttons: {
      upload: "Mengunggah",
      yes: "Ya",
      no: "TIDAK",
      confirm: "Mengonfirmasi",
      today: "Hari ini",
      yesterday: "Kemarin",
      thisMonth: "Bulan ini",
      lastMonth: "Bulan lalu",
      partners_that_registerd_from_your_ref_link:
        "Mitra yang mendaftar dari tautan referensi Anda:",
      resetFilters: "Hapus filter",
      addFilter: "Tambahkan penyaring",
      removeFilter: "Hapus penyaring",
      clearAll: "Bersihkan semua",
      submit: "Kirim",
      search: "Mencari",
      cancel: "Membatalkan",
      refreshTable: "Segarkan tabel",
      updateCampaign: "Perbarui kampanye",
      reset: "Mengatur ulang",
      register: "Daftar",
      create: "Membuat",
      login: "Gabung",
      save: "Menyimpan",
      save_new_password: "Simpan kata sandi baru",
      send: "Mengirim",
      apply: "Menerapkan",
      generateToken: "Hasilkan token",
      addNewEmployee: "Tambahkan karyawan baru",
      addNewProduct: "Tambahkan produk baru",
      add_new: "Tambah baru",
      filter: "Saring",
      addVariable: "Tambahkan variabel",
      goToMainPage: "Pergi ke halaman utama",
      download: "Unduh",
      exportXlsx: "Ekspor XLSX",
      downloading: "Mengunduh",
      new: "Baru",
      switchToUser: "Beralih ke",
      backToAdminAccount: "Kembali Ke Admin",
      setPostbackForAllProducts: "Tetapkan postback ini untuk semua produk",
      show: "Menunjukkan",
      hide: "Bersembunyi",
      enable: "Memungkinkan",
      disable: "Cacat",
    },
    radioButtons: {
      leadsCreatedDate: "Prospek dibuat pada saat ini",
      leadsApprovedDate: "Timbal disetujui pada tanggal tersebut",
      leadsSoldDate: "Timbal terjual pada saat ini",
      leadsCallCenterLastCallDate:
        "Tanggal panggilan terakhir pusat panggilan utama",
      salesCountryTimezone: "Trimimezone negara penjualan",
      myTimezone: "Zona waktu saya",
    },
    labels: {
      visibility: "Visibilitas",
      active_products: "Produk aktif",
      inactive_products: "Produk tidak aktif",
      all_products: "Semua produk",
      are_you_sure: "Apa kamu yakin?",
      do_you_want_to_generate_this_campaign:
        "Apakah Anda ingin membuat kampanye ini?",
      create_leads_on_order_delivered_status_global:
        "Buat prospek pada status pesanan terkirim (GLOBAL)",
      partner_token: "Token mitra",
      warnings: "Peringatan",
      fails: "Gagal",
      success: "Keberhasilan",
      comment: "Komentar",
      selected: "Terpilih",
      items: "item",
      global_actions: "Tindakan global",
      partners_that_registerd_from_your_ref_link:
        "Mitra yang mendaftar dari tautan referensi Anda:",
      beauty: "Kecantikan",
      health: "Kesehatan",
      slimming: "Yg melangsingkan",
      adult: "Dewasa",
      other: "Lainnya",
      search: "Mencari",
      latest_news: "Berita Terbaru",
      top_offers: "Penawaran teratas",
      found: "Ditemukan",
      rows: "baris",
      balance: "Keseimbangan",
      hold: "Memegang",
      your_manager: "Manajer Anda",
      no_manager: "Tidak ada manajer",
      profile: "Profil",
      log_out: "Keluar",
      reportByTimezone: "Laporkan berdasarkan zona waktu",
      dateType: "Jenis tanggal",
      dateRange: "Rentang tanggal",
      dateFrom: "Tanggal dari",
      dateTo: "Tanggal ke",
      setQuickDate: "Tetapkan tanggal cepat",
      selectedFilters: "Filter yang dipilih",
      status: "Status",
      country: "Negara",
      product: "Produk",
      lead_type: "Tipe timah",
      partner: "Mitra",
      userEmail: "Email pengguna",
      payoutScheme: "Skema pembayaran",
      remarketingCampaign: "Kampanye pemasaran ulang",
      classifierName: "Nama pengklasifikasi",
      accountManager: "Manajer Akuntansi",
      selectTimeZone: "Pilih zona waktu",
      timezone: "Zona waktu",
      isActive: "Aktif",
      isExcluded: "Dikecualikan dari ganda",
      isOnline: "Sedang on line",
      type: "Jenis",
      name: "Nama",
      emailAddress: "Alamat email",
      domain: "Domain",
      user: "Pengguna",
      password: "Kata sandi",
      repeatPassword: "Masukkan kata kunci kembali",
      walletName: "Nama dompet",
      walletNumber: "Nomor dompet",
      baseUrl: "URL dasar",
      id: "PENGENAL",
      email: "Surel",
      skype: "Skype",
      telegram: "Telegram",
      object: "Obyek",
      message: "Pesan",
      section: "Bagian",
      phone: "Nomor telepon",
      currentPassword: "Kata sandi saat ini",
      newPassword: "Kata sandi baru",
      repeatNewPassword: "Ulangi kata sandi baru",
      apiToken: "Token API",
      click_id: "klik_id",
      pub_id: "ID Pub",
      lp_url: "URL LP",
      second_name: "Nama kedua",
      short_name: "Nama pendek",
      role: "Peran",
      description: "Keterangan",
      application_access: "Akses aplikasi",
      pages: "Halaman",
      products: "Produk",
      addressLine1: "Jalur alamat 1",
      addressLine2: "Jalur alamat 2",
      city: "Kota",
      state: "Negara",
      zipCode: "Kode Pos",
      isInternal: "Intern",
      partnersAwaitingActivation: "Mitra menunggu aktivasi",
      allPartners: "Semua Mitra",
      company: "Perusahaan",
      basedOnRole: "Berdasarkan peran",
      campaigns: "Kampanye",
      instances: "Contoh",
      currency: "Mata uang",
      code: "Kode",
      address: "Alamat",
      operative: "Operatif",
      name_eng: "Nama (bahasa Inggris)",
      bank_transfer: "transfer Bank",
      notAcceptedBalance: "Transaksi Tidak Diterima (Tertunda).",
      subdistrict: "Kecamatan",
      island: "Pulau",
      courier: "Kurir",
      post_code: "Kode Pos",
      destination_code: "Kode tujuan",
      lead_time: "Waktu memimpin",
      lead_time_text: "Teks waktu tunggu",
      province: "Propinsi",
      delivery_cost: "Biaya pengiriman",
      return_cost: "Biaya pengembalian",
      instance_fee: "Biaya instan",
      cod_fee: "Biaya cod",
      admin_fee: "Biaya admin",
      is_qualified: "Memenuhi syarat",
      is_contacted: "Dihubungi",
      affbay_status: "Status Afbay",
      is_visible: "Terlihat",
      is_top: "Saya berhenti",
      is_desktop: "Apakah desktop",
      is_mobile: "Apakah seluler",
      url: "URL",
      tracker_id: "ID Pelacak",
      tracker_type: "Tipe pelacak",
      focus_ID: "ID Fokus",
      instance: "Contoh",
      signature: "Tanda tangan",
      value: "Nilai",
      courier_status: "Status kurir",
      status_description: "Deskripsi status",
      notes: "Catatan",
      additional_info: "Informasi tambahan",
      external: "Luar",
      transactionType: "Tipe transaksi",
      transactionStatus: "Status transaksi",
      customVariables: "Variabel khusus",
      systemVariables: "Variabel Sistem",
      newVariable: "Variabel baru",
      affbay_lead_id: "ID pimpinan Affbay",
      amount: "jumlah (USD)",
      optional_description: "Deskripsi opsional",
      title: "Judul",
      is_home_page: "Terlihat di dasbor",
      is_home_page_short: "Di dasbor",
      news_type: "Jenis berita",
      visible_on_home_page: "Terlihat di dasbor",
      date_from: "Mulai formulir",
      date_from_short: "Awal",
      date_to: "Bentuk akhir",
      date_to_short: "Akhir",
      time: "Waktu",
      hour: "Jam",
      day: "Hari",
      week: "Pekan",
      month: "Bulan",
      year: "Tahun",
      today: "Hari ini",
      lastWeek: "Minggu lalu",
      lastMonth: "Bulan lalu",
      acceptedBalance: "Saldo Diterima",
      acceptedBalanceExplanation: "(Jumlah catatan yang difilter)",
      actualBalance: "Saldo Sebenarnya",
      requestPayout: "Minta Pembayaran",
      earningsChart: "Bagan Penghasilan",
      generatedOn: "Dihasilkan pada",
      groupBy: "Kelompokkan berdasarkan",
      codeName: "Nama kode",
    },
    informations: {
      active: "Aktif",
      inactive: "Tidak aktif",
      online: "On line",
      offline: "Luring",
      viewPassword: "Lihat kata sandi",
      notAvailable: "tidak ada",
      qualified: "Berkualitas",
      notQualified: "Tidak memenuhi syarat",
      sessionExpired: "Sesi berakhir",
      visible: "Bisa dilihat",
      invisible: "Tak terlihat",
    },
    modalButtonsTitle: {
      edit: "Sunting",
      createNewInstance: "Menambahkan",
      createNewClassifier: "Menambahkan",
      addNewPage: "Tambah halaman",
      addNewCompany: "Tambahkan perusahaan",
      addNewPartner: "Tambahkan Mitra baru",
      addNewUser: "Tambahkan pengguna",
      editUser: "Sunting pengguna",
      addNewRole: "Tambahkan peran baru",
      editRole: "Sunting peran",
      addNewCountry: "Tambahkan negara baru",
      addNewCity: "Tambahkan kota baru",
      addNewCourier: "Tambahkan kurir baru",
      addNewStatus: "Tambahkan status baru",
      addNewLocation: "Tambahkan lokasi baru",
      addNewPostCode: "Tambahkan kode pos baru",
      addNewMethod: "Tambahkan metode baru",
      addNewProvince: "Tambahkan provinsi baru",
      addNewChannel: "Tambahkan saluran baru",
      addNewSubdistrict: "Tambahkan kecamatan baru",
      addNewCategory: "Tambahkan kategori baru",
      addNewCampaign: "Tambahkan kampanye baru",
      editCampaign: "Sunting kampanye",
      addNewLeadsRedirection: "Menambahkan",
      addNewSystemVariable: "Tambahkan variabel baru",
      addNewTrafficSource: "Tambahkan variabel baru",
      addNewCouriersApiMapping: "Tambahkan pemetaan baru",
      addNewCouriersStatuses: "Tambahkan status baru",
      addNewSystemClassifier: "Menambahkan",
      deposit: "Menyetorkan",
      withdraw: "Menarik",
      addNew: "Tambah baru",
    },
    modalTitle: {
      edit: "Sunting",
      editPage: "Sunting halaman",
      addNewCompany: "Tambahkan perusahaan",
      addNewUser: "Tambahkan pengguna baru",
      editUser: "Sunting pengguna",
      addNewRole: "Tambahkan peran baru",
      editRole: "Sunting peran",
      addNewCountry: "Tambahkan negara baru",
      editCountry: "Sunting negara",
      addNewCity: "Tambahkan kota baru",
      addNewCourier: "Tambahkan kurir baru",
      editCourier: "Sunting kurir",
      addNewDistrict: "Tambahkan distrik baru",
      addNewStatus: "Tambahkan status baru",
      editStatus: "Sunting statusnya",
      addNewLocation: "Tambahkan lokasi baru",
      editLocation: "Sunting lokasi",
      addNewPostCode: "Tambahkan kode pos baru",
      editPostCode: "Edit kode pos",
      addNewMethod: "Tambahkan metode baru",
      editPaymentMethod: "Edit metode pembayaran",
      addNewCategory: "Tambahkan kategori baru",
      editCategory: "Sunting kategori",
      addNewChannel: "Tambahkan saluran baru",
      editChannel: "Sunting saluran",
      addNewSubdistrict: "Tambahkan kecamatan baru",
      editSubdistrict: "Sunting distrik",
      addNewProvince: "Tambahkan provinsi baru",
      deleteProvince: "Hapus provinsi",
      editCampaign: "Sunting kampanye",
      editLeadsRedirection: "Edit pengalihan prospek",
      addNewSystemVariable: "Tambahkan variabel sistem baru",
      addNewTrafficSource: "Tambahkan sumber lalu lintas baru",
      addNewCouriersApiMapping: "Tambahkan pemetaan api kurir baru",
      addNewCouriersStatuses: "Tambahkan status kurir baru",
      editCouriersStatus: "Edit status kurir",
      editCouriersApiMapping: "Edit pemetaan api kurir",
      addNewSystemClassifier: "Tambahkan pengklasifikasi sistem baru",
      editSystemClassifier: "Edit pengklasifikasi sistem",
      editTrafficSource: "Edit sumber lalu lintas",
      deposit: "Menyetorkan",
      withdraw: "Menarik",
      transaction: "Transaksi",
      addNew: "Tambah baru",
      edit_news: "Sunting berita",
      sendFeedback: "Kirim masukan",
    },
    modalTooltips: {
      see_company_members: "Lihat anggota perusahaan",
      addNewUser: "Tambahkan pengguna baru",
      addNewRole: "Tambahkan peran baru",
      addNewCountry: "Tambahkan negara baru",
      editCountry: "Sunting negara",
      addNewCity: "Tambahkan kota baru",
      editCity: "Sunting kota",
      addNewCourier: "Tambahkan kurir baru",
      editCourier: "Sunting kurir",
      addNewStatus: "Tambahkan status baru",
      editStatus: "Sunting statusnya",
      addNewLocation: "Tambahkan lokasi baru",
      editLocation: "Sunting lokasi",
      addNewPostCode: "Tambahkan kode pos baru",
      editPostCode: "Edit kode pos",
      addNewMethod: "Tambahkan metode baru",
      editCategory: "Sunting kategori",
      addNewChannel: "Tambahkan saluran baru",
      editChannel: "Sunting saluran",
      addNewSubdistrict: "Tambahkan kecamatan baru",
      editSubdistrict: "Sunting distrik",
      addNewProvince: "Tambahkan provinsi baru",
      deleteProvince: "Hapus provinsi",
      addNewCategory: "Tambahkan kategori baru",
      addNewSystemVariable: "Tambahkan variabel sistem baru",
      addNewTrafficSource: "Tambahkan sumber lalu lintas baru",
      editTrafficSource: "Edit sumber lalu lintas",
      addNewCouriersApiMapping: "Tambahkan pemetaan api kurir baru",
      addNewCouriersStatuses: "Tambahkan status kurir baru",
      editCouriersApiMapping: "Edit pemetaan api kurir",
      editCouriersStatus: "Edit status kurir",
      addNewSystemClassifier: "Tambahkan pengklasifikasi sistem baru",
      editSystemClassifier: "Edit pengklasifikasi sistem",
      filters: "Filter",
      deposit: "Menyetorkan",
      withdraw: "Menarik",
    },
    tables: {
      campainFocusTable: "Tabel Fokus Kampanye",
    },
    title: {
      edit_account_manager: "Edit manajer akun",
      createNewInstance: "Buat contoh baru",
      createNewClassifier: "Buat pengklasifikasi baru",
      editClassifier: "Sunting Pengklasifikasi",
      editPartner: "Sunting Mitra",
      editEmployee: "Sunting Karyawan",
      editCompany: "Sunting perusahaan",
      addNewPartner: "Tambahkan Mitra baru",
      addNewEmployee: "Tambahkan Karyawan baru",
      addNewCompany: "Tambahkan perusahaan baru",
      selectInstance: "Pilih contoh",
      selectInstanceAndCampaign: "Pilih contoh dan kampanye",
      campaignsAllInstances: "Kampanye dalam segala hal",
      all: "Semua",
      allArrowAll: "Semua -> Semua",
      allArrow: "Semua ->",
      arrowAll: "-> Semua",
      createNewProduct: "menciptakan produk baru",
      campaignsActivityStatus: "`Ubah status aktivitas pengklasifikasi`",
      // partnerActivityStatus: "`Ubah status aktivitas Mitra`",
      // employeesActivityStatus: "`Ubah status aktivitas Karyawan`",
      userActivityStatus: "`Ubah status aktivitas pengguna`",
      filters: "Filter",
      activityStatus: "`Ubah status aktivitas`",
      addNewPage: "Tambahkan halaman baru",
      addNewCampaign: "Tambahkan kampanye baru",
      addNewLeadsRedirection: "Tambahkan pengalihan prospek baru",
      notFoundLabel: "Maaf, halaman yang Anda kunjungi tidak ada.",
      addNew: "Tambah baru",
      new: "Baru",
      change: "Mengubah",
      inProgressTitle: "Halaman sedang diproses",
      inProgressDesc: "Maaf, halaman yang Anda kunjungi masih dalam proses.",
    },
    columnTitle: {
      product_sku: "SKU Produk",
      product_sku_short: "SKU",
      comment: "Komentar",
      classifier_end: "Akhir pengklasifikasi",
      phone_origin: "Telepon (asal)",
      id: "PENGENAL",
      type: "Jenis",
      country: "Negara",
      country_short: "Bersama.",
      name: "Nama",
      second_name: "Nama kedua",
      short_name: "Nama pendek",
      campaign_name: "Nama kampanye",
      domain: "Domain",
      user: "Pengguna",
      password: "Kata sandi",
      password_short: "Lulus",
      url: "URL kampanye dasar",
      url_short: "URL",
      active: "Status aktivitas kampanye sebenarnya",
      active_short: "Aktif",
      // classifier_is_qualified: "'Status kualifikasi pengklasifikasi aktual'",
      // classifier_is_qualified_short: "'Memenuhi syarat'",
      online: "Status online kampanye aktual",
      online_short: "On line",
      created: "Tanggal Diciptakan",
      created_short: "Dibuat",
      updated: "Baru-baru ini Diperbarui",
      updated_short: "Diperbarui",
      options: "Pilihan",
      instance: "Contoh",
      focus_instance: "Contoh fokus",
      classifiers_external: "Pengklasifikasi eksternal",
      affbay_status: "Status Afbay",
      status: "Status",
      qualified: "Berkualitas",
      approvedAt: "Disetujui",
      approvedAt_short: "Tanggal disetujui pada",
      classifier: "Penggolong",
      product: "Produk",
      partner: "Mitra",
      payout: "Pembayaran",
      payout_scheme: "Skema Pembayaran",
      payout_scheme_short: "Skema",
      phone: "Telepon",
      click_id: "klik_id",
      pub_id: "ID Pub",
      lp_url: "URL LP",
      role: "Peran",
      description: "Keterangan",
      application_access: "Akses aplikasi",
      avalable_in_affbay: "Tersedia di Affbay",
      avalable_in_affbay_short: "Di Affbay",
      avalable_for_sale: "Tersedia untuk dijual",
      avalable_for_sale_short: "Dijual",
      base_price: "Harga dasar",
      base_price_short: "Harga",
      visible_for_partners: "Terlihat oleh mitra",
      visible_for_partners_short: "Untuk mitra",
      categories: "Kategori",
      landing: "Pendaratan",
      preview: "Pratinjau",
      is_visible: "Bisa dilihat",
      is_top: "Atas",
      is_mobile: "Seluler",
      is_desktop: "Desktop",
      deleted_at: "Dihapus",
      accountCounter: "Penghitung akun",
      isInternal: "Intern",
      partnerToken: "Token mitra",
      email: "Surel",
      manager: "Pengelola",
      company: "Perusahaan",
      skype: "Skype",
      telegram: "Telegram",
      is_active_short: "Aktif",
      key: "Kunci",
      focusId: "ID Fokus",
      is_operative: "Operatif",
      address: "Alamat",
      code: "Kode",
      currency: "Mata uang",
      is_active: "Aktif",
      name_eng: "Nama (bahasa Inggris)",
      bank_transfer: "transfer Bank",
      city: "Kota",
      province: "Propinsi",
      district: "Daerah",
      subdistrict: "Kecamatan",
      postCode: "Kode Pos",
      courier: "Kurir",
      lead_time: "Waktu memimpin",
      lead_time_text: "Teks waktu tunggu",
      district_code: "Kode distrik",
      island: "Pulau",
      deliver_cost: "Biaya pengiriman",
      resturn_cost: "Biaya pengembalian",
      insurance_fee: "Biaya asuransi",
      code_fee: "Biaya kode",
      admin_fee: "Biaya admin",
      dalivery_cost: "Biaya pengiriman",
      permission_id: "ID Izin",
      is_qualified: "Berkualitas",
      is_contacted: "Dihubungi",
      is_qualified_short: "Berkualitas",
      value: "Nilai",
      courier_name: "Nama kurir",
      courier_name_short: "Kurir",
      courier_status: "Status kurir",
      status_description: "Deskripsi status",
      notes: "Catatan",
      external: "Luar",
      additional_inf: "Informasi tambahan",
      additional_inf_short: "Informasi",
      view: "Melihat",
      is_manual_short: "petunjuk",
      is_manual: "Adalah Manual",
      is_successful_short: "Berhasil",
      is_successful: "Berhasil",
      response_time_short: "Waktu",
      response_time: "Waktu merespon",
      response_status_short: "Status",
      response_status: "Status Respons",
      response_text_short: "Teks",
      response_text: "Teks Respon",
      title: "Judul",
      is_home_page: "Terlihat di dasbor",
      is_home_page_short: "Di dasbor",
      news_type: "Jenis berita",
      visible_on_home_page: "Terlihat di dasbor",
      date_from: "Mulai formulir",
      date_from_short: "Awal",
      date_to: "Bentuk akhir",
      date_to_short: "Akhir",
      scheme: "Skema",
      leadId: "ID Pemimpin",
      accepted: "Diterima",
      date: "Tanggal",
      params: "Param",
      exclude_from_double: "Kecualikan dari ganda",
      exclude_from_double_short: "Pengecualian Ganda.",
    },
    tooltips: {
      see_company_members: "Lihat anggota perusahaan RU",
      hide_postbacks: "Sembunyikan postback",
      show_postbacks: "Tampilkan postback",
      copyKey: "Salin kunci",
      editPage: "Sunting halaman",
      deletePage: "Hapus halaman",
      cantDeleteAdminSuperAdmin: "`Tidak dapat menghapus Admin/Admin Super`",
      userActivityStatus: "Ubah status aktivitas pengguna",
      deleteUser: "Hapus pengguna",
      changeRoleActiveStatus: "Ubah status aktif peran",
      editRole: "Sunting peran",
      deleteRole: "Hapus peran",
      changeActiveStatus: "Ubah status aktif",
      viewCampaign: "Lihat kampanye",
      viewCompany: "Lihat perusahaan",
      changeQualifiedStatus: "Ubah status kualifikasi",
      deleteStatus: "Hapus status",
      deletePostCode: "Hapus kode pos",
      deleteCstegory: "Hapus kategori",
      change_activity_status: "Ubah status aktivitas",
      delete_leads_direction: "Hapus arah",
      edit_leads_direction: "Edit pengalihan prospek",
      delete: "Menghapus",
      edit: "Sunting",
      accept: "Menerima",
      reject: "Menolak",
    },
    statement: {
      unrecognized_error: "Kesalahan tidak dikenali",
      refresh_page: "Segarkan halaman",
      your_account_has_been_created: "Akun Anda telah diaktifkan",
      your_account_has_been_created_message:
        "Sekarang Anda harus menunggu spesialis kami menerima akun Anda.",
      sorry_we_could_not_get_your_data:
        "`Maaf, kami tidak bisa mendapatkan data Anda`",
      databaseConnectionError:
        "Ada yang tidak beres saat menghubungkan ke database!",
      databaseConnectionSuccess: "Udane pobranie z bazy danych!",
      instanceCreated: "Udane dodanie instancji!",
      classifierCreated: "Pengklasifikasi dibuat!",
      classifierUpdated: "Pengklasifikasi diperbarui!",
      thePropsAreTheSame:
        "Wartości w polach nie uległy zmianie - do ustalenia co z tym robimy",
      campaignUpdated: "Udana aktualizacja kampanii!",
      qulifiedUpdate: "Udana zmiana kwalifikacji",
      app_user_updated: "Pengguna aplikasi diperbarui!",
      app_user_created: "Pengguna aplikasi dibuat!",
      status_updated: "Status diperbarui!",
      there_was_a_problem_removing_app_user:
        "Ada masalah saat menghapus pengguna aplikasi",
      app_user_deleted: "Pengguna aplikasi dihapus",
      there_was_a_problem_removing_role: "Ada masalah saat menghapus peran",
      there_was_a_problem_removing_permission:
        "Terjadi masalah saat menghapus izin",
      there_was_a_problem_creating_role: "Ada masalah saat membuat peran",
      user_role_updated: "Peran pengguna diperbarui!",
      companie_created: "Perusahaan dibuat!",
      companie_updated: "Perusahaan diperbarui!",
      there_was_a_problem_removing_city: "Ada masalah saat menghapus kota",
      city_deleted: "Kota dihapus!",
      city_created: "Kota tercipta!",
      city_updated: "Kota diperbarui!",
      there_was_a_problem_removing_country: "Ada masalah saat menghapus negara",
      country_created: "Negara dihapus!",
      country_updated: "Perusahaan diperbarui!",
      there_was_a_problem_removing_courier: "Ada masalah saat menghapus kurir",
      courier_deleted: "Kurir dihapus!",
      courier_updated: "Kurir diperbarui!",
      courier_created: "Kurir dibuat!",
      there_was_a_problem_removing_district:
        "Terjadi masalah saat menghapus distrik",
      district_deleted: "Distrik dihapus!",
      district_updated: "Distrik diperbarui!",
      district_created: "Diktat dibuat!",
      there_was_a_problem_removing_location:
        "Terjadi masalah saat menghapus lokasi",
      location_deleted: "Lokasi dihapus!",
      location_updated: "Lokasi diperbarui!",
      location_created: "Lokasi dibuat!",
      there_was_a_problem_removing_payment_method:
        "Ada masalah saat menghapus metode pembayaran",
      payment_method_deleted: "Metode pembayaran dihapus!",
      payment_method_updated: "Metode pembayaran diperbarui!",
      payment_method_created: "Metode pembayaran dibuat!",
      there_was_a_problem_removing_post_code:
        "Terjadi masalah saat menghapus kode pos",
      post_code_deleted: "Kode pos dihapus!",
      post_code_updated: "Kode pos diperbarui!",
      post_code_created: "Kode pos dibuat!",
      there_was_a_problem_removing_product_category:
        "Terjadi masalah saat menghapus kategori produk",
      product_category_deleted: "Kategori produk dihapus!",
      product_category_updated: "Kategori produk diperbarui!",
      product_category_created: "Kategori produk dibuat!",
      there_was_a_problem_removing_province:
        "Terjadi masalah saat menghapus provinsi",
      province_deleted: "Provinsi dihapus!",
      province_updated: "Provinsi diperbarui!",
      province_created: "Provinsi dibuat!",
      there_was_a_problem_removing_sales_channel:
        "Ada masalah saat menghapus saluran penjualan",
      sales_channel_deleted: "Saluran penjualan dihapus!",
      sales_channel_updated: "Saluran penjualan diperbarui!",
      sales_channel_created: "Saluran penjualan dibuat!",
      there_was_a_problem_removing_status:
        "Terjadi masalah saat menghapus status",
      status_deleted: "Statusnya dihapus!",
      status_created: "Status dibuat!",
      there_was_a_problem_removing_subdistrict:
        "Terjadi masalah saat menghapus subdistrik",
      subdistrict_deleted: "Kecamatan dihapus!",
      subdistrict_updated: "Kecamatan diperbarui!",
      subdistrict_created: "Kecamatan dibuat!",
      employee_updated: "Karyawan diperbarui!",
      employee_created: "Karyawan dibuat!",
      filters_confirmed: "Filter potwierdzone",
      filters_have_been_reset: "filter telah diatur ulang",
      there_was_a_problem_removing_page: "Ada masalah saat menghapus halaman",
      page_deleted: "Halaman dihapus!",
      page_updated: "Halaman diperbarui!",
      page_created: "Halaman dibuat!",
      partner_updated: "mitra diperbarui!",
      partner_created: "mitra dibuat!",
      change_permission_status: "Ubah status izin",
      there_was_a_problem_removing_campaign:
        "Ada masalah saat menghapus kampanye",
      there_was_a_problem_creating_campaign:
        "Ada masalah saat membuat kampanye",
      campaign_deleted: "Kampanye dihapus!",
      campaign_updated: "Kampanye diperbarui!",
      campaign_created: "Kampanye dibuat!",
      role_deleted: "Peran dihapus!",
      choose_out_of_scope: "Pilih di luar cakupan!",
      date_changed: "Tanggal berubah",
      session_expired: "Sesi berakhir",
      session_expired_description:
        "Sesi Anda telah berakhir. Silakan login lagi untuk terus bekerja",
      incorrect_login_information:
        "Informasi login Anda salah. Silakan periksa dan coba lagi",
      there_was_a_problem_removing_system_variable:
        "Ada masalah saat menghapus variabel sistem",
      system_variable_deleted: "Variabel sistem dihapus!",
      system_variable_updated: "Variabel sistem diperbarui!",
      system_variable_created: "Variabel sistem dibuat!",
      traffic_source_deleted: "Sumber lalu lintas dihapus!",
      traffic_source_updated: "Sumber lalu lintas diperbarui!",
      traffic_source_created: "Sumber lalu lintas dibuat!",
      there_was_a_problem_removing_couriers_api_mapping:
        "Ada masalah saat menghapus pemetaan api kurir",
      couriers_api_mapping_deleted: "Pemetaan api kurir dihapus!",
      couriers_api_mapping_updated: "Pemetaan api kurir diperbarui!",
      couriers_api_mapping_created: "Pemetaan api kurir telah dibuat!",
      system_classifier_created: "Pengklasifikasi sistem telah dibuat!",
      system_classifier_updated: "Pengklasifikasi sistem telah diperbarui!",
      updated: "Diperbarui!",
      created: "Dibuat!",
      deleted: "Dihapus!",
      success: "Kesuksesan!",
      accepted: "Diterima!",
      rejected: "Ditolak",
      noCompanyIdAssigned:
        "Akun Anda tidak memiliki perusahaan yang ditetapkan. Silakan hubungi kami melalui panel dukungan.",
      noDataToDisplay: "Maaf, tidak ada data untuk ditampilkan",
    },
    tabs: {
      createNewProduct: "menciptakan produk baru",
      pricing: "Harga",
      conversion: "Konversi",
      affbayVisibility: "visibilitas Affbay",
      landings: "Pendaratan / Prelanding",
    },
    navigationItems: {
      help: "Bantuan",
      dashboard: "Dasbor",
      statistics: "Statistik",
      financeStatistics: "Statistik keuangan",
      leads: "Memimpin",
      employees: "Karyawan",
      products: "Produk",
      pages: "Halaman",
      landings: "Pendaratan",
      companies: "Perusahaan",
      partners: "Mitra",
      campaigns_partner: "Kampanye",
      campaigns_admin: "Admin kampanye",
      finance: "Keuangan",
      financeAdmin: "Admin keuangan",
      tools: "Peralatan",
      news: "Berita",
      news_admin: "Admin berita",
      support: "Mendukung",
      faq: "Pertanyaan Umum",
      contact: "Kontak",
      login: "Gabung",
      register: "Daftar",
      offers: "Produk",
      create_new_order: "Buat pesanan baru",
      orders: "Pesanan",
      couriersStatusMapping: "Pemetaan status",
      account_managers: "Manajer Akun",
      settings: "Pengaturan",
    },
    subNavigationItem: {
      k8_services: "Layanan K8",
      sales: "Penjualan",
      sales_p2: "hal2",
      sales_resale: "hal3",
      sales_winback: "Menang kembali",
      administration: "Administrasi",
      appRemarketing: "Pemasaran Ulang - P2",
      appUsers: "Pengguna",
      appUsersRole: "Peran pengguna",
      webSocket: "Soket Web",
      appUsersPermissions: "Izin pengguna",
      callCenter: "Pusat panggilan",
      cc_instances: "Contoh",
      cc_campaigns: "Kampanye",
      cc_classifiers: "Pengklasifikasi",
      li_classifiers_cc: "Memetakan pengklasifikasi",
      cc_webhooks: "Webhook",
      cc_leads_direction: "Pengalihan prospek",
      setting: "Pengaturan",
      api: "API",
      payment: "Pembayaran",
      postbacks: "Postback",
      referralHistory: "Riwayat rujukan",
      security: "Keamanan",
      settings: "Pengaturan",
      dictionaries: "Kamus",
      countries: "Negara",
      cities: "kota",
      couriers: "Kurir",
      districts: "Distrik",
      subdistricts: "Kecamatan",
      statuses: "Status",
      locations: "Lokasi",
      postCodes: "Kode pos",
      paymentMethods: "Cara Pembayaran",
      productCategories: "Produk kucing.",
      salesChannels: "Saluran penjualan",
      provinces: "Provinsi",
      system_variables: "Variabel sistem",
      traffic_sources: "Sumber lalu lintas",
      couriers_api_mapping: "Pemetaan kurir",
      couriers_statuses: "Status kurir",
      system_classifiers: "Pengklasifikasi sistem",
    },
    helpView: {
      title: 'Bantuan',
      content: 'Konten akan segera hadir.',
    },
    others: {
      of: "dari",
      rows: "baris",
    },
    contentTitle: {
      support: "Mendukung",
    },
    contentText: {
      alreadyHaveAnAccount: "Sudah memiliki akun?",
      iDontHaveAnAccount: "Saya tidak punya akun.",
      rememberThePassword: "Ingat kata sandinya?",
      support:
        "Jika Anda memerlukan bantuan dengan akun Anda, memiliki pertanyaan tentang penggunaan platform, atau mengalami masalah teknis, kirimkan surat kepada kami.",
    },
    links: {
      all_news: "Semua berita",
      login: "Gabung",
      registration: "Registrasi",
      forgotYourPassword: "lupa kata sandi Anda?",
    },
    placeholders: {
      enterName: "Masukkan nama",
      enterEmail: "Masukan email",
      enterPassword: "Masukkan kata kunci",
      repeatPassword: "Masukkan kata kunci kembali",
      selectCountry: "Pilih negara",
      notRequired: "Tidak dibutuhkan",
      select: "Pilih...",
      globalSearch: "Pencarian global",
      search: "Mencari",
      findProduct: "Temukan produk",
      choose_affbay_status: "Pilih status affbay",
      choose_country: "Pilih negara",
      choose_company: "Pilih perusahaan",
      choose_product: "Pilih produk",
      choose_partner: "Pilih mitra",
      choose_scheme: "Pilih skema",
      choose_classifier: "Pilih pengklasifikasi",
      choose_remarketing_campaign: "Pilih kampanye",
      enter_min_4_letters: "Masukkan min 4 huruf",
    },
    pageTitle: {
      k8_services: "Layanan K8",
      dashboard: "Dasbor",
      account_managers: "Manajer akun",
      loginContent: "Gabung",
      registerContent: "Registrasi",
      resetPasswordContent: "Pulihkan kata sandi",
      employees: "Seluruh karyawan PL + Seluruh karyawan Call center",
      products: "Produk",
      pages: "Halaman",
      addNewCompany: "Tambahkan perusahaan",
      companies: "Perusahaan",
      partners: "Mitra",
      administrationUsers: "Administrasi - Pengguna",
      administrationUsersRole: "Administrasi - Peran Pengguna",
      instances: "Contoh",
      instancesForCCSimplified: "Koneksi API pusat panggilan",
      campaigns: "Kampanye",
      classifiers: "Pengklasifikasi",
      mappingClassifiers: "Memetakan pengklasifikasi",
      webhooks: "Webhook",
      countriesDictionary: "Kamus negara",
      citiesDictionary: "Kamus kota",
      couriersDictionary: "Kamus kurir",
      districtsDictionary: "Kamus distrik",
      statusesDictionary: "Kamus status",
      locationsDictionary: "Kamus lokasi",
      postCodeDictionary: "Kamus kode pos",
      paymentMethodDictionary: "Kamus metode pembayaran",
      productCategoriesDictionary: "Kamus kategori produk",
      salesChannels: "Kamus saluran penjualan",
      subdistrictsDictionary: "kamus kecamatan",
      provincesDictionary: "Kamus Provinsi",
      campaigns_admin: "Kampanye - Admin",
      orders: "Pesanan",
      leads_directions: "Pengalihan prospek",
      system_variables: "Variabel sistem",
      traffic_sources: "Sumber lalu lintas",
      couriers_api_mapping: "Pemetaan api kurir",
      system_classifiers: "Pengklasifikasi sistem",
      finance: "Keuangan",
      news_admin: "Admin Berita",
      finance_admin_view: "Tampilan admin keuangan",
      resaleP3: "P3 Dijual Kembali",
      resaleP2: "P2 Dijual Kembali",
    },
    productsFormMain: {
      editProduct: "Sunting produk",
      createNewProduct: "Buat produk baru",
      englishName: "nama Inggris",
      currency: "Mata uang",
      callCenter: "Pusat Panggilan",
      sku: "SKU",
      originalName: "Nama asli",
      shortName: "Nama pendek",
      printName: "Nama cetak",
      categories: "Kategori",
      availableInAffbay: "Tersedia di AFFBAY",
      testProduct: "Produk uji",
      availableForSale: "Tersedia untuk dijual",
      active: "Aktif",
      country: "Negara",
      sales: "Penjualan",
      mainCamapignId: "Kampanye utama [id]",
      resellCampaignId: "Kampanye penjualan kembali [id]",
      productionCost: "Biaya produksi (USD)",
      productionCostLocalCurrency: "Biaya produksi (mata uang lokal)",
      affbayVisibility: "Visibilitas Affaby",
      visibleForPartners: "Terlihat oleh mitra",
      visibleOnHomePage: "Terlihat di halaman beranda (penawaran teratas)",
      isRestricted: "Dibatasi",
      defaultGlobalPayout: "Pembayaran global default (USD)",
      defaultPayoutScheme: "Skema pembayaran default",
      cpa: "BPA",
      cpl: "CPL",
      landingPriceForConsumer: "Harga pendaratan untuk konsumen",
      approveVisibleOnPage: "Setujui terlihat di halaman (%)",
      payoutVisibleOnPage: "Pembayaran terlihat (string) di halaman (USD)",
      descriptionEN: "Deskripsi EN",
      descriptionRU: "Deskripsi RU",
      traffic_sources_en: "Sumber lalu lintas (deskripsi) EN",
      traffic_sources_ru: "Sumber lalu lintas (deskripsi) RU",
      terms_en: "Ketentuan EN",
      terms_ru: "Ketentuan ru",
      conditions_en: "Ketentuan EN",
      conditions_ru: "Kondisi ru",
      productsWillNotBeAvailableInAffbay:
        "Produk tidak akan tersedia di AFFBAY",
      updateCampaigns: "Segarkan daftar kampanye",
      save: "Menyimpan",
      create: "Membuat",
      reset: "Mengatur ulang",
      delivered: "Diterima",
      av_order: "Pesanan",
      products: "Produk",
      payout: "Pembayaran",
      advertize: "Mulai beriklan",
      title: "Pilih produk untuk diiklankan",
    },
    checkBoxes: {
      iWantToReceiveImportantNewsByEmail:
        "Saya ingin menerima berita penting melalui email (disarankan)",
      iWantToReceivePromotionslNewsByEmail:
        "Saya ingin menerima berita promosi melalui email",
      iWantToReceiveInformationAboutChangesInOffersByEmail:
        "Saya ingin menerima informasi tentang perubahan penawaran melalui email",
      iWantToReceiveOffersOfOffersByEmail:
        "Saya ingin menerima tawaran penawaran melalui email",
      add_to_affbay_classifiers_library:
        "Tambahkan ke perpustakaan Pengklasifikasi Affbay",
    },
    landingPage: {
      about: "Tentang",
      topOffers: "Penawaran teratas",
      contact: "Kontak",
      logIn: "Gabung",
      certifiedProducts: "PRODUK BERSERTIFIKAT DARI PENGIKLAN LANGSUNG",
      afiliateNetwork: "Jaringan Afiliasi di Thailand",
      saudiArabAndIndonesia: "Arab Saudi dan Indonesia",
      becomeAPartner: "Menjadi mitra",
      activePartners: "Mitra aktif",
      offersInAsia: "Penawaran di Asia",
      yearsOnTheMarket: "Bertahun-tahun di pasar",
      countries: "Negara",
      aboutText:
        "Affbay adalah pengiklan langsung di pasar Asia dan negara-negara Arab. Kami tidak menjual kembali penawaran tersebut, semua produk adalah milik kami sendiri. Di sini Anda akan menemukan lusinan penawaran tentang topik kecantikan, kesehatan, dan penurunan berat badan dengan pembayaran mulai dari $18 lebih tinggi untuk aplikasi yang dikonfirmasi. Kami mengerjakan model CPA, CPL, CPI.",
      regularPayouts: "Pembayaran Reguler",
      regularPayoutsText:
        "Pembayaran tanpa penundaan di sebagian besar sistem pembayaran.",
      highPayouts: "Pembayaran Tinggi",
      highPayoutsText:
        "Mulai dari $18 ke atas untuk aplikasi yang dikonfirmasi.",
      ownCallCenter: "Pusat panggilan sendiri",
      ownCallCenterText:
        "Spesialis CC berpengalaman melakukan pekerjaan secara efisien.",
      personalManager: "Manajer pribadi",
      personalManagerText:
        "Dukungan responsif akan membantu Anda memecahkan masalah apa pun.",
      weDontHaveTopOffers: "Kami tidak memiliki penawaran terbaik saat ini.",
      howCanWeHelpYou: "Apa yang bisa kami bantu?",
      writeUs: "Tuliskan kepada kami tentang kekhawatiran Anda.",
      becomeOurPartner:
        "Jadilah mitra kami dan mulailah menghasilkan uang sekarang juga",
      affbayAsia: "Affbay.asia",
      ownedBy: "Dimiliki Oleh: Izabella Frolushkin FZE",
      privacyPolicy: "Kebijakan pribadi",
      termsAndConditions: "syarat dan Ketentuan",
      send: "Mengirim",
      name: "Nama",
      email: "Surel",
      object: "Obyek",
      message: "Pesan",
      thankYou: "Terima kasih",
      youWillBeHearing:
        "Pesan Anda telah terkirim. Kami akan menangani masalah Anda dalam waktu 48 jam.",
      startEarning: "Mulai menghasilkan",
      sitePrice: "Harga situs",
      approve: "Menyetujui",
      payouts: "Pembayaran",
      country: "Negara",
    },
    loginPage: {
      title: 'Masuk dengan Media Sosial',
      logIn: "Gabung",
      registration: "Registrasi",
      email: "Surel",
      password: "Kata sandi",
      forgotYourPassword: "lupa kata sandi Anda?",
      logIntoThePlatform: "Masuk ke platform",
      iDontHaveAnAccount: "Saya tidak punya akun.",
      send_request_again: "Kirim permintaan lagi",
    },
    registrationPage: {
      registration: "Registrasi",
      name: "Nama",
      email: "Surel",
      password: "Kata sandi",
      repeatPassword: "Masukkan kata kunci kembali",
      country: "Negara",
      skype: "Skype",
      telegram: "Telegram",
      notRequired: "Tidak dibutuhkan",
      iAgreeToAffbayAsia: "Saya setuju dengan Affbay Asia",
      privacyPolicy: "Kebijakan pribadi",
      and: "Dan",
      termsAndConditions: "syarat dan Ketentuan",
      nextStep: "Langkah berikutnya",
      alreadyHaveAnAcount: "Sudah memiliki akun?",
      logIn: "Gabung",
      thxForReg: "Terima kasih telah mendaftar",
      toStartWork: "Untuk mulai bekerja, harap berikan kami beberapa informasi",
      surveyIsNotAvailable: "Survei tidak tersedia",
      thisAccountIsA: "Akun ini adalah...",
      whichgeos: "Geo mana yang pernah Anda gunakan sebelumnya?",
      whatgeo: "Geo apa yang ingin Anda kerjakan?",
      whatTypeOfTraffic: "Jenis lalu lintas apa yang Anda gunakan?",
      whatBugdets: "Anggaran apa yang Anda gunakan?",
      whatDoYouPrefer: "Mana yang lebih Anda sukai - S2S atau API?",
      submitMyApplication: "Kirimkan lamaran saya",
      enterYourName: "Masukkan nama Anda",
      enterEmail: "Masukan email",
      enterPassword: "Masukkan kata kunci",
    },
    submitSuccess: {
      congrats: "Selamat, akun Anda telah dibuat!",
      nowYouCan: "Sekarang Anda dapat masuk ke akun Anda",
      logIn: "Gabung",
    },
    resetPassword: {
      password_changed:
        "Kata sandi Anda telah diubah. Anda dapat masuk sekarang.",
      typeTheAdress:
        "Ketikkan alamat yang tertaut ke akun Anda dan kami akan mengirimkan instruksi pengaturan ulang kata sandi. Periksa juga folder spam Anda.",
      email: "Surel",
      set_new_password: "Tetapkan kata sandi baru",
      remember: "Ingat kata sandi Anda?",
      logIn: "Gabung",
      checkEmail: "Periksa email Anda",
      withinAFew:
        "Dalam beberapa menit Anda akan menerima email. Dalam pesan ini kami akan mengirimkan Anda tautan untuk mengatur kata sandi baru.",
      new_password: "Kata sandi baru",
    },
    supportView: {
      support: "Mendukung",
      ifYouNeedHelp:
        "Jika Anda memerlukan bantuan dengan akun Anda, memiliki pertanyaan tentang penggunaan platform, atau mengalami masalah teknis, kirimkan surat kepada kami.",
      subject: "Subjek",
      message: "Pesan",
      send: "Mengirim",
    },
    newsView: {
      news: "Berita",
      allNews: "Semua berita",
    },
    offersView: {
      offers: "Detail produk",
      offersFound: "penawaran ditemukan",
      show: "Menunjukkan:",
      sortBy: "Sortir dengan:",
      category: "Kategori",
      conversionType: "Jenis konversi",
      sort: "Menyortir",
      accessRequest: "Permintaan akses",
      wirteUs:
        "Tuliskan kepada kami tentang sumber lalu lintas, dengan siapa Anda bekerja dan pengalaman Anda.",
      send: "Mengirim",
      thankYou: "Terima kasih",
      youWillBeHearing: "Anda akan segera mendapat kabar dari kami!",
      sitePrice: "Harga situs",
      payouts: "Pembayaran",
      country: "Negara",
      originalCountry: "Negara asal",
      readMore: "Baca selengkapnya",
      exampleCodePhp: "Unduh contoh",
    },
    offersDetails: {
      sku: "SKU",
      description: "Keterangan",
      targetURL: "URL sasaran",
      postbacks: "Postback",
      trafficSources: "Sumber lalu lintas",
      news: "Berita",
      terms_and_conditions: "Syarat & Ketentuan",
      landings_tab: "Pendaratan",
      payout: "Pembayaran",
      approve: "Menyetujui",
      sitePrice: "Harga situs",
      category: "Kategori",
      country: "Negara",
      devices: "Perangkat",
      step_1:
        "Langkah 1. Pilih halaman arahan dan prelanding. Anda dapat memilih beberapa item yang dibuka secara acak di kampanye Anda",
      step_2:
        "Langkah 2. Masukkan nama baru untuk kampanye Anda dan klik tombol Generere URL untuk menyimpannya.",
      landings: "Pendaratan",
      prelandings: "Pendaratan awal",
      campaign_name: "Nama kampanye",
      generateURL: "Hasilkan URL",
      allProductCampaigns: "Semua kampanye produk",
      name: "Nama",
      prelanding: "Pendaratan awal",
      landing: "Pendaratan",
      URL: "URL",
      youHaveTo: "Anda harus memilih LP dan mengetik Nama Kampanye",
      noLandings: "Tidak ada halaman untuk ditampilkan",
      urlForSales: "URL Postback untuk Penjualan (artinya SETUJU untuk CPL)",
      urlForHolds: "URL Postback untuk Penangguhan (berarti SETUJU untuk CPL)",
      urlForRejects: "URL Postback untuk Tolak (artinya SETUJU untuk CPL)",
      urlForTrash: "URL Postback untuk Sampah (berarti DITOLAK untuk CPL)",
      urlForDuplicated:
        "URL Postback untuk Duplikat (berarti DITOLAK untuk CPL)",
      urlForExcluded:
        "URL Postback untuk Dikecualikan (berarti DITOLAK untuk CPL)",
      urlForValidated: "URL Postback untuk Divalidasi",
      googleTrackingID: "ID pelacakan Google",
      facebookPixelID: "ID piksel Facebook",
      customPixelID: "ID piksel khusus",
      useGlobalPostbacks: "Gunakan postback global",
      createPostback: "Buat pos balik",
      updatePostback: "Perbarui pos balik",
      fillInTheData: "Isikan data dibawah ini untuk membuat postback baru",
      protocol: "Protokol",
      domain: "Domain",
      exampleCOM: "contoh.com",
      extraPathParameter: "Parameter jalur ekstra",
      folderSlash: "/folder/subfolder",
      parameter: "Parameter",
      addNew: "Tambah baru",
      postbackURLPreview: "Pratinjau URL Pos Balik",
      save: "Menyimpan",
      basic: "Dasar",
      advanced: "Canggih",
      postbackSavedSuccessfully: "Postback berhasil disimpan",
      postbackSavedWithWarning: "Postback disimpan dengan peringatan",
      postbackNotSaved: "Tidak dapat menyimpan postback",
      clearPostback: "Hapus Pos Balik",
    },
    profileView: {
      personlaData: "Data pribadi",
      changePassword: "Ganti kata sandi",
      notifications: "Notifikasi",
      apitoken: "Token API",
      email: "Surel",
      name: "Nama",
      phone: "Nomor telepon",
      telegram: "Telegram",
      skype: "Skype",
      timeZone: "Zona waktu",
      apply: "Menerapkan",
      cancel: "Membatalkan",
      id_referral: "Tautan pendaftaran",
      currentPass: "Kata sandi saat ini",
      newPass: "Kata sandi baru",
      repeatNewPass: "Ulangi kata sandi baru",
      change: "Mengubah",
      importantNews:
        "Saya ingin menerima berita penting melalui email (disarankan)",
      promotionalNews: "Saya ingin menerima berita promosi melalui email",
      information:
        "Saya ingin menerima informasi tentang perubahan penawaran melalui email",
      offers: "Saya ingin menerima tawaran penawaran melalui email",
      customVariablesList: "Daftar Variabel Khusus",
      postbacksList: "Daftar Postback",
    },
    toolsView: {
      open: "Membuka",
      tools: "Peralatan",
      faq: "Pertanyaan Umum",
      answersToFAQ:
        "Jawaban atas pertanyaan umum mengenai pembayaran untuk implementasinya dan Affbay secara umum",
      readMore: "Baca selengkapnya",
      apiDocs: "Dokumentasi API",
      leadSendExample: "Kirim formulir contoh prospek",
      readOurDocs:
        "Baca dokumentasi kami untuk mendapatkan jawaban atas banyak pertanyaan mengenai API.",
      readDocs: "Baca dokumentasi",
      postbackDocs: "Dokumentasi Postback",
      postbacksNotify:
        "Baca dokumentasi kami untuk mendapatkan jawaban atas banyak pertanyaan mengenai API.",
      integrationArchiModel: "Model Arsitektur Integrasi",
      hardToUnderstand:
        "Sulit memahami cara kerja Affbay? Baca selengkapnya tentang beberapa contoh arsitektur kami.",
      numberValidation: "Validasi nomor",
      checkingTheNumber:
        "Pengecekan nomor adalah kunci untuk meningkatkan kualitas akuisisi dan pendapatan pelanggan.",
      longpageFromWidget: "Widget formulir halaman panjang",
      longpageFromWidgetDescription:
        "Sertakan formulir kami ke halaman web Anda. Cukup tempelkan kode kami, tambahkan token dan sku Anda untuk mulai menghasilkan prospek baru.",
    },
    select: {
      select_timezone: "Pilih zona waktu",
      select_status: "Pilih status",
      select_available: "Pilih tersedia",
      select: "Pilih",
      select_country: "Pilih negara",
    },
    spin: {
      loading: "Memuat...",
    },
    faq: {
      header_1: "Apa itu Affbay Asia?",
      header_2: "Integrasi seperti apa yang kami dukung?",
      header_3:
        "Apakah Anda memerlukan pelacak sendiri untuk diintegrasikan dengan S2S?",
      header_4: "Parameter apa saja yang didukung oleh Affbay (integrasi S2S)?",
      header_5: "Bagaimana cara menambahkan parameter yang ingin Anda terima?",
      header_6: "Parameter Affbay yang mana?",
      header_7: "Bagaimana cara mengkonfigurasi postback umum untuk S2S?",
      header_8:
        "Apa yang dimaksud dengan postback untuk penjualan, penangguhan, penolakan?",
      header_9:
        "Bagaimana cara mengkonfigurasi mendapatkan postback untuk penawaran konkret?",
      header_10: "Bagaimana cara menambahkan pembayaran ke url postback?",
      header_11: "Bagaimana seharusnya tampilan url postback?",
      content_1:
        "Kami adalah pengiklan langsung di Indonesia dan Thailand, kami memiliki penawaran COD dengan konversi tinggi. Lebih banyak geo di Asia segera hadir!",
      content_2:
        "Kami dapat berintegrasi dengan Anda melalui S2S atau API. Dokumentasi untuk API tersedia di situs web kami di bagian Penawaran (di bagian atas halaman web). Pilih penawaran yang Anda sukai dan klik Detail.",
      content_3:
        "Affbay adalah platform ramah pengguna, yang menyediakan akun pribadi Anda dengan dasbor dan tidak memerlukan pelacak, meskipun kami dapat memberi Anda integrasi postback S2S dengan pelacak Anda.",
      content_4:
        "Anda dapat menambahkan parameter apa pun yang didukung oleh pelacak Anda. Lihat Bagaimana cara menambahkan parameter yang ingin Anda terima?.",
      content_5:
        "Masukkan akun pribadi Anda -> klik Nama pengguna Anda -> Profil -> Postback global. Cukup tambahkan parameter apa saja tanpa tanda kurung {} () dan letakkan juga di url postback Anda.",
      content_6: [
        "Parameter bawaan kami ar",
        "click_id - pengidentifikasi global (UUID) dari prospek tertentu",
        "campaign_id - pengidentifikasi global (UUID) dari kampanye tertentu",
        "aff_id - pengidentifikasi global (UUID) dari pengguna tertentu (Anda).",
        "Jika Anda menentukan variabel khusus dengan nama salah satu di atas, postback akan menggunakannya, menggantikan data pelacak Anda dan bukan data default kami. Kami mencegah hal ini untuk menghindari kebingungan.",
      ],
      content_7:
        "Masukkan akun pribadi Anda -> klik Nama pengguna Anda -> Profil -> Postback global. Di sini Anda dapat menambahkan url postback umum Anda. Jika Anda ingin menerima postback untuk penjualan – masukkan saja url postback Anda di kolom ini, sama untuk postback untuk penangguhan dan penolakan. Anda juga dapat menambahkan Piksel Facebook dan ID Pelacakan Google untuk semua penawaran.",
      content_8:
        "Jika Anda ingin menerima postback untuk prospek yang disetujui – pilih postback untuk penjualan. Postback untuk penangguhan berarti mendapatkan postback untuk setiap prospek yang masuk ke sistem kami. Postback untuk ditolak berarti mendapatkan postback untuk lead yang ditolak – nomor telepon tidak valid atau lead tidak dijangkau oleh Call Center dalam sebulan.",
      content_9:
        "Masuk ke bagian Penawaran (di bagian atas halaman web) -> pilih penawaran dan klik Detail -> di sana Anda dapat menambahkan url postback dan juga Piksel Facebook dan ID Pelacakan Google untuk penawaran tertentu.",
      content_10: "&jumlah=13&payout_type=pps¤cy=USD",
      content_11:
        "https://callbacks.bitterstrawberry.org/?token=0c4c1249825285a2997bacf09c09ec28&hash={hash}&transaction_id={click_id}&amount=13&payout_type=pps¤cy=USD",
    },
    news_type: {
      all_news: "Semua berita",
      new: "Baru",
      important: "Penting",
      info: "Informasi",
    },
    setting_page: {
      click_id: "Klik ID",
      check_click_id: "Periksa ID klik ganda",
      click_id_days: "Jumlah hari untuk diperiksa",
    },
  },
});
