import * as React from "react";
import { displayPercent } from "../../../../../helpers/DisplayHepler";
import { getLocale } from "../../../../../helpers/LocaleHelper";
import { IProductCardOffer } from "./interfaces/IProductCardOffer";
import { resources } from "../../../../../common/Resources";
import arrow from "./images/arrow.svg";
import questionModalIcon from "./images/question-modal-icon.svg";
import { getDictionary } from "../../../../../helpers/DisctionariesHelper";
import { decodeCategoriesArray } from "../../../../../helpers/Encoders";

export class ProductCardOffer extends React.Component<IProductCardOffer> {
  state = {
    showModalWindow: 0,
  };

  onMouseEnterHandler = (newValue: number) => {
    this.setState({ showModalWindow: newValue });
  };

  public render() {
    const { productData } = this.props;

    return (
      <>
        <h4 className="text-indigo font-semibold text-2xl mb-10">
          {resources.offersView.offers}
        </h4>
        <div className="container-fluid max-w-full flex pb-10 bg-white rounded-t-xs flex-col sm:flex-row">
          <img
            className="sm:w-495 xxl:w-550 3xl:w-745 3xl:h-590 flex-none mx-auto 2xl:m-0 rounded-tr-xs rounded-tl-xs sm:rounded-bl-xs sm:rounded-tl-xs sm:rounded-tr-none object-cover"
            src={this.props.img}
            alt="product-img"
          />
          <div className="flex-1 xm:mx-auto xl:mx-0 bg-dark-blue xm:p-5 md:p-10 xm:rounded-b-xs xl:rounded-bl-none xl:rounded-r-xs">
            <h3 className="text-white max-w-screen xm:text-xs font-semibold sm:text-lg mb-4">
              {productData.name_short}
            </h3>
            <div className="flex gap-2 mb-4 sm:hidden">
              {this.getProductCategories()
                .split(",")
                .map((category: any, index: number) => (
                  <span
                    key={index}
                    className="bg-gray px-3 rounded-xs text-dark-gray"
                  >
                    {category}
                  </span>
                ))}
            </div>
            <p
              className="offer-details-description text-white xm:text-min-xs sm:text-base font-normal xm:mb-6 sm:mb-10"
              dangerouslySetInnerHTML={{ __html: this.content() }}
            />
            <div className="container flex flex-wrap xm:justify-start sm:max-w-lg gap-2 md:gap-4 xm:mb-5 sm:mb-10">
              <div className="relative container bg-white content-end sm:content-center w-90 h-66 sm:w-136 xxl:w-165 3xl:w-180 sm:h-99 py-2 px-1 md:py-2 md:px-4  xm:rounded-tl-md sm:rounded-tl-lg xm:rounded-br-md sm:rounded-br-lg">
                <div
                  className={`${
                    this.state.showModalWindow === 1 ? "block" : "hidden"
                  } absolute top-1/80 left-1/77 md:left-5 sm:left-50 w-298 bg-white rounded-xs p-4`}
                >
                  <p className="font-normal text-min-xs text-dark-blue">
                    {this.getFirstHint(productData.default_payout_idr)}
                  </p>
                </div>
                <img
                  onMouseEnter={() => this.onMouseEnterHandler(1)}
                  onMouseLeave={() => this.onMouseEnterHandler(0)}
                  className="cursor-pointer absolute top-1 right-5 sm:top-8 sm:right-8"
                  src={questionModalIcon}
                  alt="icon"
                />
                <span className="block text-dark-blue font-bold xm:text-base sm:text-md text-center">
                  {resources.offersDetails.payout}
                </span>
                <span className="block text-dark-blue font-bold xm:text-min-xs sm:text-xs text-center">
                  Rp {productData.default_payout_idr}
                </span>
              </div>
              <div className="relative container bg-white content-end sm:content-center w-90 h-66 sm:w-136 xxl:w-165 3xl:w-153 sm:h-99 py-2 px-1 md:py-2 md:px-4  xm:rounded-tl-md sm:rounded-tl-lg xm:rounded-br-md sm:rounded-br-lg">
                <div
                  className={`${
                    this.state.showModalWindow === 2 ? "block" : "hidden"
                  } absolute top-1/82 left-1/78 sm:left-50 w-298 bg-white rounded-xs p-4`}
                >
                  <p className="font-normal text-min-xs text-dark-blue">
                    {this.getSecondHint(productData.av_order)}
                  </p>
                </div>
                <img
                  onMouseEnter={() => this.onMouseEnterHandler(2)}
                  onMouseLeave={() => this.onMouseEnterHandler(0)}
                  className="cursor-pointer absolute top-1 right-5 sm:top-8 sm:right-8"
                  src={questionModalIcon}
                  alt="icon"
                />
                <span className="block text-dark-blue font-bold xm:text-base sm:text-md text-center">
                  {resources.productsFormMain.av_order}
                </span>
                <span className="block text-dark-blue font-bold xm:text-min-xs sm:text-xs text-center">
                  {productData.av_order} {resources.productsFormMain.products}
                </span>
              </div>
              <div className="relative container bg-white content-end sm:content-center w-90 h-66 sm:w-136 xxl:w-165 3xl:w-153 sm:h-99 py-2 px-1 md:py-2 md:px-4  xm:rounded-tl-md sm:rounded-tl-lg xm:rounded-br-md sm:rounded-br-lg">
                <div
                  className={`${
                    this.state.showModalWindow === 3 ? "block" : "hidden"
                  } absolute top-1/82 right-2/7 sm:right-1/30 w-298 bg-white rounded-xs p-4`}
                >
                  <p className="font-normal text-min-xs text-dark-blue">
                    {this.getThirdHint(productData.approve_page)}
                  </p>
                </div>
                <img
                  onMouseEnter={() => this.onMouseEnterHandler(3)}
                  onMouseLeave={() => this.onMouseEnterHandler(0)}
                  className="cursor-pointer absolute top-1 right-5 sm:top-8 sm:right-8"
                  src={questionModalIcon}
                  alt="icon"
                />
                <span className="block text-dark-blue font-bold xm:text-base sm:text-md text-center">
                  {resources.productsFormMain.delivered}
                </span>
                <span className="block text-dark-blue font-bold xm:text-min-xs sm:text-xs text-center">
                  {displayPercent(productData.approve_page)}
                </span>
              </div>
            </div>
            <img
              className="hidden cursor-pointer sm:block"
              src={arrow}
              alt="arrow"
            />
          </div>
        </div>
      </>
    );
  }

  getProductCategories = (): any => {
    const categories = getDictionary("categoriesD");
    const productCategories = this.props.productData.categories.split(",");
    return decodeCategoriesArray(productCategories, categories);
  };

  content = () => {
    const description =
      getLocale() === "en_GB"
        ? this.props.productData.description_en
        : this.props.productData.description_id;

    return description ? description : "";
  };

  getFirstHint = (value: any) => {
    const englishVersion = `You will earn Rp ${value} for delivered order. Payment depends on the number of items purchased by the customer.
The higher the order value, the bigger your payment!`;
    const indonesianVersion = `Anda akan mendapatkan Rp ${value} untuk pesanan yang diterima. Pembayaran tergantung pada jumlah barang yang dibeli oleh pelanggan. 
Semakin tinggi nilai pesanan, semakin besar pembayaran Anda!`;

    return getLocale() === "en_GB" ? englishVersion : indonesianVersion;
  };

  getSecondHint = (value: any) => {
    const englishVersion = `On average, each customer order consists of ${value} products per receipt.
The more products shipped, the higher your payout will be.`;
    const indonesianVersion = `Rata-rata, setiap pesanan pelanggan terdiri dari ${value} produk per diterima. 
Semakin banyak produk yang dikirim, semakin tinggi pula bayaran Anda.`;

    return getLocale() === "en_GB" ? englishVersion : indonesianVersion;
  };

  getThirdHint = (value: any) => {
    const englishVersion = `On average, ${
      value / 100
    }% of customers confirm their order and pay upon delivery.`;
    const indonesianVersion = `Rata-rata, ${
      value / 100
    }% pelanggan mengonfirmasi pesanan mereka dan membayar pada saat pengiriman.`;

    return getLocale() === "en_GB" ? englishVersion : indonesianVersion;
  };
}
