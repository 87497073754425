import * as React from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { Dispatch } from "redux";
import { ISetActiveMenuItem } from "../../common/interfaces/ISetActiveMenuItem";
import { navPath } from "../../common/ResourceDef";
import { setActiveMenuItem } from "../../reducers/CommonReducer";
import { resources } from "../../common/Resources";

export class HelpViewInner extends React.Component<ISetActiveMenuItem> {
  componentDidMount = () => this.props.setActiveMenuItem(navPath.help);

  public render() {
    return (
        <div>
            <h2 className="mt-10 mb-4 text-center font-bold text-3xl">
                {resources.helpView.title}
            </h2>
            <p className="text-center text-md">
                {resources.helpView.content}
            </p>
        </div>
    )
  }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (
  dispatch: Dispatch
) => ({
  setActiveMenuItem: (view: string) => dispatch(setActiveMenuItem(view)),
});

export const HelpView: React.ComponentClass = connect(
  null,
  mapDispatchToProps
)(HelpViewInner);
