import React from "react";

import { StatisticSelect } from "./CampaignSelect";

const yearOptions = [
  { value: 2021, label: "2021" },
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
];

const monthOptions = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const TableFilters = ({
  filters,
  campaigns,
  onChangeFilter,
}: {
  filters: any;
  campaigns: any;
  onChangeFilter: (filter: string, value: number | string) => void;
}) => {
  const campaignsOptions = campaigns.map((campaign: any) => ({
    value: campaign.campaign_id,
    label: campaign.campaign_name,
  }));
  const getLabel = (
    options: any,
    value: number | string,
    placeholder: string
  ) =>
    options.find((option: any) => option.value === value)?.label || placeholder;

  return (
    <div className="lg:pl-0 flex-col ">
      <div className="w-full md:w-334 mb-5 block lg:hidden">
        <StatisticSelect
          filter="campaign_id"
          label={getLabel(
            campaignsOptions,
            filters.campaign_id,
            "Select Your Campaign"
          )}
          options={campaignsOptions}
          onChangeFilter={onChangeFilter}
        />
      </div>
      <div className="flex items-center justify-between md:justify-start gap-5 lg:gap-3 xl:gap-5 mb-6 lg:mb-0">
        <div className="w-full md:w-111">
          <StatisticSelect
            filter="year"
            label={getLabel(yearOptions, filters.year, "Year")}
            options={yearOptions}
            onChangeFilter={onChangeFilter}
          />
        </div>
        <div className="w-full md:w-111">
          <StatisticSelect
            filter="month"
            label={getLabel(monthOptions, filters.month, "Month")}
            options={monthOptions}
            onChangeFilter={onChangeFilter}
          />
        </div>
      </div>
    </div>
  );
};
