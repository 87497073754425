import * as React from "react";
import { getLocale } from "../../../../helpers/LocaleHelper";
import { ITermsAndConditions } from "./interfaces/ITermsAndConditions";

export class TermsAndConditions extends React.Component<ITermsAndConditions> {
  termsContent = () => {
    const terms =
      getLocale() === "en_GB" ? this.props.terms_en : this.props.terms_id;

    return terms ? terms : "";
  };

  conditionsContent = () => {
    const conditions =
      getLocale() === "en_GB"
        ? this.props.conditions_en
        : this.props.conditions_id;

    return conditions ? conditions : "";
  };

  public render() {
    return (
      <div className="xm:pr-0 md:pr-8">
        <article
          className="mt-12 text-min-xs sm:text-xxs"
          dangerouslySetInnerHTML={{ __html: this.conditionsContent() }}
        ></article>
        <article
          className="text-min-xs sm:text-xxs"
          dangerouslySetInnerHTML={{ __html: this.termsContent() }}
        ></article>
      </div>
    );
  }
}
