import logoImg from "../../../common/images/full-logo-light.svg";
import shortLogoImg from "../../../common/images/short-logo.svg";
import React from "react";
import SubMenu from "antd/lib/menu/SubMenu";
import { connect } from "react-redux";
import { createAbsoluteUrl } from "../../../helpers/UrlCreator";
import { getMenuItems } from "../../../views/MenuItems";
import { IAppState } from "../../../store";
import { Icon, Menu } from "antd";
import { INavigationProps } from "./interfaces/INavigationProps";
import { Link } from "react-router-dom";
import { NavigationItem } from "../navigationItem/NavigationItem";
import { SubNavigationItem } from "../subNavigationItem/SubNavigationItem";
import { USER_ROLES } from "../../../common/enums/UserRoles";

const renderSubMenu = (subnavigationItems: SubNavigationItem) => {
  const { optionalParams } = subnavigationItems;
  return (
    <SubMenu
      key={subnavigationItems.title + "key"}
      style={{
        backgroundColor: "#222849",
      }}
      title={
        <span className="submenu-title-wrapper">
          <Icon
            style={{ fontSize: "32px" }}
            type={optionalParams && optionalParams.icon}
            rotate={optionalParams && optionalParams.rotate}
            className="anticon"
          />
          <span>{subnavigationItems.title}</span>
        </span>
      }
    >
      {subnavigationItems.navigationItems.map((navigationItem) =>
        renderMenuItem(navigationItem)
      )}
    </SubMenu>
  );
};

const renderMenuItem = (
  navigationItem: NavigationItem,
  onOpenMobileNavigation?: any
) => {
  const { optionalParams } = navigationItem;

  return (
    <Menu.Item
      key={navigationItem.key}
      className="navMenuItem"
      style={{
        backgroundColor: "#222849",
      }}
      onClick={onOpenMobileNavigation}
    >
      <Link to={`${navigationItem.link}`} style={{ fontSize: "16px" }}>
        {optionalParams!.component ? (
          <img
            src={optionalParams!.component}
            alt=""
            className="h-8 w-8 anticon"
          />
        ) : (
          <Icon
            type={optionalParams!.icon}
            style={{ fontSize: "32px" }}
            className="anticon"
          />
        )}
        <span>{navigationItem.name}</span>
      </Link>
    </Menu.Item>
  );
};

class NavigationMenuInner extends React.Component<INavigationProps> {
  state = {
    navigationItems: Array<any>(),
  };

  componentDidMount = () => {
    const permissions = this.props.permissions ? this.props.permissions : [];

    this.setState({ navigationItems: getMenuItems(permissions) });
  };

  UNSAFE_componentWillReceiveProps = (props: INavigationProps) => {
    const permissions = this.props.permissions ? this.props.permissions : [];

    this.setState(
      {
        navigationItems: getMenuItems(permissions),
      },
      () => this.forceUpdate()
    );
  };

  public render() {
    return (
      <nav className="customAnt--menu bg-dark-blue w-full">
        <Link
          to={createAbsoluteUrl("dashboard")}
          className={`link_logo border-b border-blue mb-6 w-10/12 flex ${
            this.props.collapsedMenu ? "justify-center" : "justify-start"
          }`}
        >
          <img
            src={this.props.collapsedMenu ? shortLogoImg : logoImg}
            alt="logo"
          />
        </Link>

        <Menu
          onClick={this.props.handleMenuClick}
          selectedKeys={[this.props.activeMenuItem]}
          mode="inline"
          theme="dark"
          style={{
            backgroundColor: "#222849",
          }}
        >
          {this.state.navigationItems.map((navigationItem) =>
            navigationItem.isMenuSubItem
              ? renderSubMenu(navigationItem)
              : renderMenuItem(navigationItem)
          )}
        </Menu>
      </nav>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const userRole = state.commonState.appUserInfo.id_users_role;

  return {
    activeMenuItem: state.commonState.activeMenuItem,
    permissions: state.commonState.appUserInfo.permissions,
    isCCSimplified: userRole === USER_ROLES.CC_SIMPLIFIED,
  };
};

const NavigationMenu = connect(mapStateToProps)(NavigationMenuInner);

export default NavigationMenu;
export { renderMenuItem, renderSubMenu };
