import React, { useState } from "react";

import useViewport from "../../../lib/hooks/useViewport";
import StatisticTableMore from "./StatisticTableMore";
import { TableFilters } from "./TableFilters";
import moment from "moment";

export const StatisticTable = ({
  data,
  filters,
  campaigns,
  onChangeFilter,
}: {
  onChangeFilter: (filter: string, value: number | string) => void;
  data: any;
  filters: any;
  campaigns: any;
}) => {
  const { isMobile, isTablet } = useViewport();
  const [activeRowId, setActiveRowId] = useState(0);

  return (
    <section className="container-fluid w-full h-full pt-10 pr-5 xxl:pr-11 bg-white">
      <div className="pl-4 md:pl-6 lg:px-0 flex flex-col lg:flex-row justify-between">
        <div className="w-full flex flex-col lg:flex-row justify-between">
          <h1 className="pl-0 md:pl-6 xl:pl-10 text-indigo text-md xl:text-sm font-semibold mb-6">
            Conversion DAY BY DAY
          </h1>
          <TableFilters
            filters={filters}
            campaigns={campaigns}
            onChangeFilter={onChangeFilter}
          />
        </div>
      </div>
      {data.length === 0 && (
        <div className="w-full h-full flex items-center justify-center">
          <p className="text-dark-gray text-sm font-semibold">No data found</p>
        </div>
      )}
      {data.length > 0 && (
        <table className="w-full text-xxxs text-right border-collapse mb-10">
          <thead>
            <tr className="w-full border-t border-b border-white-gray">
              <th className="text-left pl-5 pr-3 2xl:pr-0 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                Year
              </th>
              <th className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                Month
              </th>
              <th className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                Day
              </th>
              <th className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                LP Opens (click)
              </th>
              <th className="pr-5 md:pr-0 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                Leads
              </th>
              <th className="pr-5 md:pr-0 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                Leads Rate
              </th>
              <th className="pr-5 md:pr-0 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                Sold leads
              </th>
              <th className="pl-5 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo hidden xxl:table-cell">
                Conversion Rate
              </th>
              <th className="pr-5 md:pr-0 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                Delivered leads
              </th>
              <th className="pr-5 md:pr-0 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo">
                Delivered rate
              </th>
              <th className="pr-5 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo hidden xxl:table-cell">
                Payout amount IDR
              </th>
              <th className="py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray lg:text-indigo hidden xxl:table-cell">
                CPA dynamic payout for 1 lead
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((el: any, index: number) => {
              const onClickHandler = (id: number) => {
                setActiveRowId(id);
              };

              return isMobile || isTablet ? (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => onClickHandler(index)}
                    className={`${
                      activeRowId ? "border-b-0" : "border-b"
                    } even:bg-heavently-white border-t border-white-gray`}
                  >
                    <td className="text-left pr-3 2xl:pr-0 pl-5 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.year}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {moment()
                        .month(el.month - 1)
                        .format("MMMM")}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.day}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.clicks}
                    </td>
                    <td className="pr-5 md:pr-3 2xl:pr-0 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.total_leads}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.leads_rate} %
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.sold_leads}
                    </td>
                    <td className="pl-5 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo hidden xxl:table-cell">
                      {el.conversion_rate} %
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.delivered_leads}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.delivery_rate} %
                    </td>
                    <td className="py-3 3xl:w-298 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo hidden xxl:table-cell">
                      Rp {el.cpa_for_1_lead}
                    </td>
                    <td className="pr-5 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo hidden xxl:table-cell">
                      Rp {el.total_payout}
                    </td>
                  </tr>
                  {isMobile || isTablet
                    ? activeRowId === index && <StatisticTableMore row={el} />
                    : null}
                </React.Fragment>
              ) : (
                <React.Fragment key={index}>
                  <tr className="even:bg-heavently-white border-t border-b border-white-gray">
                    <td className="text-left pr-3 2xl:pr-0 pl-5 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.year}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {moment()
                        .month(el.month - 1)
                        .format("MMMM")}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.day}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.clicks}
                    </td>
                    <td className="pr-5 md:pr-3 2xl:pr-0 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.total_leads}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.leads_rate} %
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.sold_leads}
                    </td>
                    <td className="pl-5 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo hidden xxl:table-cell">
                      {el.conversion_rate} %
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.delivered_leads}
                    </td>
                    <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo">
                      {el.delivery_rate} %
                    </td>
                    <td className="py-3 3xl:w-298 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo hidden xxl:table-cell">
                      Rp {el.cpa_for_1_lead}
                    </td>
                    <td className="pr-5 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray lg:text-indigo hidden xxl:table-cell">
                      Rp {el.total_payout}
                    </td>
                  </tr>
                  {isMobile || isTablet
                    ? activeRowId === index && <StatisticTableMore />
                    : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </section>
  );
};
