import * as React from 'react';
import styles from './styles/LogRegNav.module.scss';
import { createAbsoluteUrl } from '../../helpers/UrlCreator';
import { resources } from '../../common/Resources';

interface props {
    activeTab?: string
}

export const LogRegNav = (props: props) => {
    return (
        <nav className={styles.grid}>
            <a className={`${props.activeTab === 'registration' ? 'text-green' : 'text-blue-dark'} text-base font-normal cursor-pointer hover:text-green`} href={createAbsoluteUrl('register')}>{resources.loginPage.registration}</a>
            <a className={`${props.activeTab === 'login' ? 'text-green' : 'text-blue-dark'} text-base font-normal cursor-pointer hover:text-green`} href={createAbsoluteUrl('login')}>{resources.loginPage.logIn}</a>
        </nav>
    );
}
