import React from "react";
import { ITargetUrlForm } from "./interfaces/ITargetUrlForm";
import { OfferDetailsContainer } from "../../../../../components/containers/offerDetailsContainer/OfferDetailsContainer";
import { resources } from "../../../../../common/Resources";
import { TargetUrlLanding } from "./components/targetUrlLanding/TargetUrlLanding";
import checked from "./images/icon-checked.svg";
import deleteContent from "./images/delete.svg";
import copyContent from "./images/content_copy.svg";
import { copyClipboard } from "../../../../../helpers/CopyHelpers";
import { findInDictionaryAndReturnValueWithSpin } from "../../../../../helpers/DisctionariesHelper";
import { GenerateUrlForm } from "../../../../../components/GenerateUrlForm/GenerateUrlForm";

export class TargetUrlForm extends React.Component<ITargetUrlForm> {
  state = {
    landing: Array<number>(),
    prelanding: Array<number>(),
    showTabContent: true,
  };

  idExistsInArr = (id: number, arr: number[]): number[] => {
    // check if given id in given arr - if true: delete this element | if false add this element and sort array
    return arr.indexOf(id) < 0
      ? arr.concat(id).sort()
      : arr.filter((el) => el !== id);
  };

  onLandingChange = (event: any): void => {
    const landingArr = this.state.landing;

    this.setState({
      landing: this.idExistsInArr(Number(event.target.value), landingArr),
    });
  };

  onPreLandingChange = (event: any): void => {
    const prelandingArr = this.state.prelanding;

    this.setState({
      prelanding: this.idExistsInArr(Number(event.target.value), prelandingArr),
    });
  };

  onHandlerClick(newValue: boolean) {
    this.setState({ showTabContent: newValue });
  }

  public render() {
    const { pages } = this.props;
    const { showTabContent } = this.state;
    const filteredCampaigns = this.props.campaigns.filter((campaign: any) => {
      return showTabContent
        ? campaign.deleted_at === null
        : campaign.deleted_at !== null;
    });

    return (
      <OfferDetailsContainer>
        {pages && pages.length > 0 ? (
          <div className="mt-12 xm:pl-0 md:pr-8">
            <div className="flex gap-2 items-center mb-3">
              <img src={checked} alt="checked-icon" />
              <span className="text-indigo font-normal text-xmd">Step 1</span>
            </div>
            <h6 className="text-dark-gray text-min-xs sm:text-xxs font-normal mb-7">
              {resources.offersDetails.step_1}
            </h6>
            <div>
              {pages
                .filter((pages) => !pages["is_prelanding"])
                .map((page) => {
                  return (
                    <TargetUrlLanding
                      key={page["id"]}
                      id={page["id"]}
                      name={page["name"]}
                      url={page["url"]}
                      is_top={page["is_top"]}
                      is_desktop={page["is_desktop"]}
                      is_mobile={page["is_mobile"]}
                      onChange={this.onLandingChange}
                      selected={this.state.landing}
                    />
                  );
                })}
            </div>
            <div className="flex gap-2 items-center mt-12 mb-4">
              <img src={checked} alt="checked-icon" />
              <span className="text-indigo font-normal text-xmd">Step 2</span>
            </div>
            <div>
              <h6 className="text-dark-gray text-min-xs sm:text-xxs font-normal mb-5">
                {resources.offersDetails.step_2}
              </h6>
              <GenerateUrlForm
                landing={this.state.landing}
                productId={this.props.id}
                buttonName={resources.offersDetails.generateURL}
                placeholderName={resources.offersDetails.campaign_name}
                reRenderTable={this.props.reRenderTable}
              />
            </div>
            {this.props.campaigns.length > 0 && (
              <div>
                <h6 className="xm:text-xs sm:text-xmd text-indigo font-normal mb-5">
                  Campaigns
                </h6>
                <div className="flex gap-5 mb-6">
                  <button
                    onClick={() => this.onHandlerClick(true)}
                    className={`${
                      this.state.showTabContent ? "activeTab" : "unActiveTab"
                    } rounded-md border border-dark-blue py-1 px-5 text-dark-gray text-xxs text-normal`}
                  >
                    Active
                  </button>
                  <button
                    onClick={() => this.onHandlerClick(false)}
                    className={`${
                      !this.state.showTabContent ? "activeTab" : "unActiveTab"
                    } rounded-md border border-dark-blue py-1 px-5 text-dark-gray text-xxs text-normal`}
                  >
                    Archived
                  </button>
                </div>
                {filteredCampaigns.length > 0 && (
                  <div>
                    <table className="sm:table-fixed">
                      <thead className="border-b border-light-white-gray">
                        <tr>
                          <th className="text-dark-gray text-min-xs sm:text-xxs font-normal p-4 w-32">
                            Name
                          </th>
                          <th className="text-dark-gray text-min-xs sm:text-xxs font-normal p-4 w-56">
                            Landing
                          </th>
                          <th className="text-dark-gray text-min-xs sm:text-xxs font-normal p-4 w-56">
                            URL
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCampaigns.map((el: any) => {
                          return (
                            <tr key={el.key}>
                              <td className="text-dark-gray text-min-xs sm:text-xxs font-normal p-4 align-top">
                                {el.name}
                              </td>
                              <td className="text-dark-gray text-min-xs sm:text-xxs font-normal p-4">
                                {el.lp.map((item: any) => (
                                  <div key={item}>
                                    {findInDictionaryAndReturnValueWithSpin(
                                      item,
                                      pages
                                    )}
                                  </div>
                                ))}
                              </td>
                              <td className="flex items-center text-dark-gray text-min-xs sm:text-xxs font-normal p-4">
                                <span className="mr-2">
                                  {el.url !== null && el.url.length > 24
                                    ? el.url.substring(0, 20) + "..."
                                    : el.url}
                                </span>
                                <div className="flex gap-3">
                                  <img
                                    className="cursor-pointer"
                                    onClick={(e) => copyClipboard(e, el.url)}
                                    src={copyContent}
                                    alt="copy"
                                  />
                                  <img
                                    className="cursor-pointer"
                                    onClick={() =>
                                      this.props.deleteTargetUrlCampaigns(el.id)
                                    }
                                    src={deleteContent}
                                    alt="delete"
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <p>{resources.offersDetails.noLandings}</p>
        )}
      </OfferDetailsContainer>
    );
  }
}
