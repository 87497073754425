import React, { useState } from "react";
import { Spin } from "antd";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { resources } from "../../../common/Resources";
import { setWizard } from "../../../reducers/CommonReducer";
import { useWizardProduct } from "../lib/getWizardProduct";
import { TargetUrlLanding } from "../../offers/offerDetails/forms/targetUrl/components/targetUrlLanding/TargetUrlLanding";

export const WizardLanding = () => {
  const dispatch = useDispatch();
  const [selectedLanding, setSelectedLanding] = useState<number[]>([]);
  const { productData, productImg, landings } = useWizardProduct();

  const onSetCampaigns = () => {
    dispatch(setWizard({ lp: selectedLanding }));
  };

  return (
    <section className="container-fluid h-full xm:bg-white md:bg-transparent mb-16 pt-10 px-4 md:px-8">
      {Object.keys(productData).length > 0 ? (
        <>
          <div className="flex gap-3 mb-9">
            <div className="min-w-38 h-38 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-50">
              <div className="w-33 h-33 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-light-white-gray-wizard text-white font-semibold text-min-xs rounded-50 content-center text-center">
                <span className="text-indigo text-xmd font-semibold">2</span>
              </div>
            </div>
            <h2 className="self-center text-indigo font-semibold text-md md:text-sm">
              Pilih Landing untuk produk yang dipilih
            </h2>
          </div>
          <div className="w-full lg:w-758 bg-white rounded-xxs">
            <div className="rounded-tl-xxs rounded-tr-xxs">
              <img
                className="mx-auto"
                src={productImg.toString()}
                alt="icon-product"
              />
            </div>
            <div className="pt-4 border border-gray md:border-none md:border-transparent rounded-b-xxs mb-12 md:mb-0">
              <div className="pl-6">
                <h2 className="mb-2 text-indigo font-semibold text-md hidden sm:block">
                  {productData[0].name_short}
                </h2>
                <p className="pr-6 text-dark-gray font-normal text-min-xs sm:text-xxs">
                  Anda dapat memilih beberapa item, yang akan dibuka secara acak
                  dalam kampanye Anda.
                </p>
                <div className="pt-6 pr-7 md:pb-7">
                  {landings.map((page) => {
                    const idExistsInArr = (
                      id: number,
                      arr: number[]
                    ): number[] => {
                      // check if given id in given arr - if true: delete this element | if false add this element and sort array
                      return arr.indexOf(id) < 0
                        ? arr.concat(id).sort()
                        : arr.filter((el) => el !== id);
                    };

                    const onLandingChange = (event: any): void => {
                      const landingArr = idExistsInArr(
                        Number(event.target.value),
                        selectedLanding
                      );
                      setSelectedLanding(landingArr);
                    };

                    return (
                      <div className="pageContent" key={page["id"]}>
                        <TargetUrlLanding
                          key={page["id"]}
                          id={page["id"]}
                          name={page["name"]}
                          url={page["url"]}
                          is_top={page["is_top"]}
                          is_desktop={page["is_desktop"]}
                          is_mobile={page["is_mobile"]}
                          onChange={onLandingChange}
                          selected={selectedLanding}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <NavLink to="/wizard/campaign" onClick={onSetCampaigns}>
              <div className="pb-8 w-181 md:w-244 mx-auto">
                <button className="w-181 h-38 md:w-244 md:h-44 text-white text-min-xs md:text-xxs font-bold text-center rounded-full border-1 bg-gradient-to-r from-blue to-aquamarine hover:from-light-blue hover:to-navy-blue hover:text-white">
                  HALAMAN BERIKUTNYA
                </button>
              </div>
            </NavLink>
          </div>
        </>
      ) : (
        <div className="text-center">
          <Spin tip={resources.spin.loading} />
        </div>
      )}
    </section>
  );
};
