import * as React from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { DashboardContent } from "./contents/DashboardContent";
import { Dispatch } from "redux";
import { ISetActiveMenuItem } from "../../common/interfaces/ISetActiveMenuItem";
import { navPath } from "../../common/ResourceDef";
import { setActiveMenuItem } from "../../reducers/CommonReducer";

export class DashboardViewInner extends React.Component<ISetActiveMenuItem> {
  componentDidMount = () => this.props.setActiveMenuItem(navPath.dashboard);

  public render() {
    return <DashboardContent />;
  }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (
  dispatch: Dispatch
) => ({
  setActiveMenuItem: (view: string) => dispatch(setActiveMenuItem(view)),
});

export const DashboardView: React.ComponentClass = connect(
  null,
  mapDispatchToProps
)(DashboardViewInner);
