import * as React from "react";
import { connect, MapDispatchToProps } from "react-redux";
import { Dispatch } from "redux";
import { ISetActiveMenuItem } from "../../common/interfaces/ISetActiveMenuItem";
import { ProfileSettingsContent } from "./content/ProfileSettingsContent";
import { setActiveMenuItem } from "../../reducers/CommonReducer";
import { navPath } from "../../common/ResourceDef";

class ProfileSettingsViewInner extends React.Component<ISetActiveMenuItem> {
  componentDidMount = () => {
    this.props.setActiveMenuItem(navPath.profile);
  };

  public render() {
    return <ProfileSettingsContent />;
  }
}

const mapDispatchToProps: MapDispatchToProps<ISetActiveMenuItem, {}> = (
  dispatch: Dispatch
) => ({
  setActiveMenuItem: (view: string) => {
    dispatch(setActiveMenuItem(view));
  },
});

export const ProfileSettingsView: React.ComponentClass = connect(
  null,
  mapDispatchToProps
)(ProfileSettingsViewInner);
