import CustomVariablesSettings from "../customVariablesSettings/CustomVariablesSettingsContnet";
import InformationModalDialog from "../../../../components/others/modal/InformationModalDialog";
import PostbackUrlContent from "../postbackUrl/contents/PostbackUrlContent";
import React from "react";
import styles from "../styles/PostbackSettingsContent.module.scss";
import {
  IPostbackConfig,
  IPostbackSettingsState,
} from "../interfaces/IPostbackSettingsState";
import {
  IPostbackDataResponse,
  IPostbackTypeResponse,
} from "../interfaces/IPostbackDataResponse";
import { IProductId } from "../../../../common/interfaces/IProductId";
import { IUserId } from "../../../../common/interfaces/IUserId";
import { openErrorNotification } from "../../../../helpers/NotificationHelper";
import { PostbackSettingsForm } from "../forms/PostbackSettingsForm";
import { resources } from "../../../../common/Resources";
import { Spin } from "antd";
import {
  getPostbackConfig,
  updatePostbackConfig,
  deletePostbackConfig,
} from "../../../../services/PostbacksConfigService";
import {
  getCustomVariables,
  updateCustomVariables,
} from "../../../../services/CustomVariableService";

// will be used in future, now set to 1
const MAX_URL_COUNT_PER_STATUS = 1;

export class PostbackSettingsContent extends React.Component<
  IProductId & IUserId,
  IPostbackSettingsState
> {
  _isMounted = false;

  getMounted = () => {
    return this._isMounted;
  };

  setMounted = (val: boolean) => {
    this._isMounted = val;
  };

  state = {
    postbacks: [] as Array<IPostbackConfig>,
    isLoading: true,
    selectedPostback: 0,
    unusedStatuses: [] as Array<IPostbackConfig>,
    customVariables: [] as Array<string>,
  };

  // supported statuses
  postbacksConfigTemplate = {
    postback_awaiting: {
      id: 0,
      name: resources.statistics.awaiting,
    },
    postback_double: {
      id: 1,
      name: resources.statistics.double,
    },
    postback_rejected: {
      id: 2,
      name: resources.statistics.rejected,
    },
    postback_sold: {
      id: 3,
      name: resources.statistics.sold,
    },
    postback_trash: {
      id: 4,
      name: resources.statistics.trash,
    },
    postback_excluded: {
      id: 5,
      name: resources.statistics.excluded,
    },
  };

  componentDidMount = () => {
    this.setMounted(true);
    this.getPostbacks();
  };

  componentWillUnmount = () => {
    this.setMounted(false);
  };

  getPostbacks = async () => {
    if (this.getMounted()) {
      this.setState({
        isLoading: true,
      });
    }
    try {
      const fetchedPostbacksData = (await getPostbackConfig(
        this.props.productId,
        this.props.userId
      )) as IPostbackDataResponse;
      const customVariables = (await getCustomVariables(
        this.props.userId
      )) as Array<string>;
      const fetchedPostbacks = fetchedPostbacksData.postbacks;
      // postbacks that apper in postbacksConfigTemplate and have some value
      const editablePostbacks = fetchedPostbacks
        .map((postbackData: IPostbackTypeResponse): string => postbackData.type)
        // get from fetchPostbacks only ones that appear in postbacksConfigTemplate
        .filter(
          (postbackType: string): boolean =>
            postbackType in this.postbacksConfigTemplate
        )
        // map to IPostbackConfig
        .map(
          (postbackType: string): IPostbackConfig => ({
            type: postbackType,
            name: this.postbacksConfigTemplate[postbackType].name,
            // url equals to array of IPostbacks objects
            urls: fetchedPostbacks.filter(
              (el: IPostbackTypeResponse) => el.type === postbackType
            )[0].urls,
            id: this.postbacksConfigTemplate[postbackType].id,
          })
        );

      const unusedStatuses = Object.keys(this.postbacksConfigTemplate)
        // get statuses that apper in postbacksConfigTemplate but no in fetchedPostbacks
        // or apper in fetchedPostbacks but support more urls
        .filter(
          (postbackType: string) =>
            !fetchedPostbacks
              .map((postback: IPostbackTypeResponse) => postback.type)
              .includes(postbackType) ||
            editablePostbacks.filter(
              (postback: IPostbackConfig) =>
                postback.type === postbackType &&
                postback.urls.length < MAX_URL_COUNT_PER_STATUS
            ).length > 0
        )
        // map to IPostbackConfig
        .map(
          (el: string): IPostbackConfig => ({
            name: this.postbacksConfigTemplate[el].name,
            id: this.postbacksConfigTemplate[el].id,
            type: el,
            urls: [],
          })
        );
      if (this.getMounted()) {
        this.setState({
          postbacks: editablePostbacks,
          unusedStatuses,
          isLoading: false,
          customVariables: customVariables,
        });
      }
    } catch {
      openErrorNotification(" getPostbacks");
    }
  };

  upsertPostback = async (
    url: string,
    type: string,
    postbackId: number
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      updatePostbackConfig(
        url,
        type,
        postbackId,
        this.props.productId,
        this.props.userId
      )
        .then(() => {
          this.getPostbacks();
          resolve();
        })
        .catch((err) => {
          this.getPostbacks();
          reject(err);
        });
    });
  };

  deletePostback = async (postbackId: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      deletePostbackConfig(postbackId, this.props.userId)
        .then(() => {
          this.getPostbacks();
          resolve();
        })
        .catch((err) => {
          reject(err.response.data.message);
        });
    });
  };

  updateCustomVariables = async (customVariables: Array<string>) => {
    try {
      await updateCustomVariables(customVariables, this.props.userId);
      this.getPostbacks();
    } catch {
      openErrorNotification(" updateCustomVariables");
    }
  };

  public render() {
    if (this.state.isLoading) {
      return (
        <div className={styles.spiner}>
          <Spin tip={resources.spin.loading} />
        </div>
      );
    }
    const addButton =
      this.state.unusedStatuses.length > 0 ? (
        <InformationModalDialog
          buttonTooltip={resources.offersDetails.createPostback}
          buttonTitle={resources.offersDetails.createPostback}
          buttonClassName={styles.addButton}
          withHideMethod={true}
          width={692}
        >
          <PostbackUrlContent
            postbackUrl={""}
            postbackId={undefined}
            canStatusChange={true}
            onUpsert={this.upsertPostback}
            statuses={this.state.unusedStatuses}
            customVariables={this.state.customVariables}
          />
        </InformationModalDialog>
      ) : null;
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {resources.profileView.postbacksList}
          </div>
          {addButton}
        </div>
        <div className={"formStyle " + styles.postbackSettingsForm}>
          {this.state.postbacks.map((postbackConfig: IPostbackConfig) => (
            <PostbackSettingsForm
              key={postbackConfig.id}
              postbackConfig={postbackConfig}
              onUpsert={this.upsertPostback}
              statuses={this.state.unusedStatuses}
              customVariables={this.state.customVariables}
              // will be used in future, now disabled
              // onDelete={this.deletePostback}
            />
          ))}
        </div>
        {this.props.userId ? (
          <>
            <div className={styles.sectionDivider}></div>
            <CustomVariablesSettings
              customVariables={this.state.customVariables}
              onUpsert={this.updateCustomVariables}
              maxVariablesCount={5}
            />
          </>
        ) : null}
      </div>
    );
  }
}
