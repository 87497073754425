import React from "react";
import { IUpdateUserInf } from "../../../../models/IUpdateUserInf";
import { IUserPersonalData } from "../../../../models/IUserPersonalData";
import { openSuccessNotification } from "../../../../helpers/NotificationHelper";
import { resources } from "../../../../common/Resources";
import { updateUser } from "../../../../services/m29_users_management/GeneralUserService";

interface IUser {
  user: IUserPersonalData;
  id?: number;
}

export class UserSettingsForm extends React.Component<IUser> {
  state = {
    id: "",
    email: "",
    name: "",
    validationWarningDisplay: "none",
    loading: false,
  };

  componentDidMount = () => {
    const { id, email, name } = this.generateInitialState();

    this.setState({
      id,
      email,
      name,
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="flex flex-col gap-2.5 mb-5">
        <div className="w-full md:w-382 flex flex-col">
          <input
            className={`${this.state.validationWarningDisplay === "block" ? "inputError" : ""} placeholder-gray-500 placeholder-opacity-50 border rounded-md border-lite-gray py-2 px-5 w-full text-xxs font-normal`}
            placeholder={resources.labels.name}
            type="text"
            name={"name"}
            value={this.state.name}
            onChange={this.handleChange}
          />
          {this.state.validationWarningDisplay === "block" && (
            <span className="block self-end text-red text-min font-normal">
              *please add correct name
            </span>
          )}
        </div>
        <div className="w-full md:w-382 flex flex-col">
          <input
            className={`${this.state.validationWarningDisplay === "block" ? "inputError" : ""} placeholder-gray-500 placeholder-opacity-50 border rounded-md border-lite-gray py-2 px-5 w-full text-xxs font-normal`}
            placeholder={resources.labels.emailAddress}
            type="text"
            name={"email"}
            value={this.state.email}
            onChange={this.handleChange}
          />
          {this.state.validationWarningDisplay === "block" && (
            <span className="block self-end text-red text-min font-normal">
              *please add correct email
            </span>
          )}
        </div>
        <div className="w-148 h-44 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-md">
          <button
            className="w-144 h-41 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:bg-transparent bg-blue-gray text-white font-semibold text-xxs rounded-md"
            type="submit"
            disabled={this.state.loading}
          >
            SAVE
          </button>
        </div>
      </form>
    );
  }

  generateInitialState = () => {
    const user = this.props.user;

    const initialState = {
      id: user.id ? user.id : "",
      email: user.email ? user.email : "",
      name: user.name ? user.name : "",
    };

    return initialState;
  };

  handleChange = (e: any): void => {
    if (e.target.type === "number") {
      // it allows digits-only in input type="number"
      const re = /^[0-9\b]+$/;
      if (re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  // handleSelectChange = (e: any): void => {
  //     this.setState({
  //         timeZone: e,
  //     })
  // }

  handleSubmit = (e: any): void => {
    e.preventDefault();
    if (this.validation()) {
      this.updateUserData();
    } else {
      this.displayValidationAlert();
    }
  };

  updateUserData = async () => {
    this.setState({ loading: true });

    const object: IUpdateUserInf = {
      name: this.state.name,
      email: this.state.email,
    };

    await updateUser(this.props.id!, object)
      .then((response) => {
        if (response) {
          this.hideValidationAlert();
          openSuccessNotification(resources.statement.updated);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  // createFormDataObject = () => {
  //     const keys = Object.keys(this.state);
  //     const values = Object.values(this.state);
  //
  //     let obj = this.generateInitialState();
  //
  //     for (let i = 0; i <= keys.length; i++) {
  //         if (keys[i] === 'validationVaringDisplay' || keys[i] === 'loading') {
  //             return
  //         } else {
  //             obj = { ...obj, [keys[i]]: values[i] };
  //         }
  //     }
  //
  //     return obj;
  // }

  displayValidationAlert = () => {
    this.setState({ validationWarningDisplay: "block" });
  };

  hideValidationAlert = () => {
    this.setState({ validationWarningDisplay: "none" });
  };

  // handleReset = (): void => {
  //     const { email, name, phoneNo, telegram, skype, timeZone } = this.generateInitialState();
  //
  //     this.setState({
  //         email,
  //         name,
  //         phoneNo,
  //         telegram,
  //         skype,
  //         timeZone
  //     });
  //
  //     this.hideValidationAlert();
  // }

  validation = (): boolean => {
    const { email, name } = this.state;

    if (this.checkLength(email, 3) && this.checkLength(name, 3)) {
      return true;
    } else {
      return false;
    }
  };

  checkLength = (el: string | [any], howLong: number) => {
    return el.length >= howLong ? true : false;
  };
}
