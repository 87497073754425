import React from "react";
import {Form, FormikBag, FormikErrors, FormikProps, withFormik} from "formik";
import { IChangePasswordFormValues } from "./interfaces/IChangePasswordFormValues";
import { ILoading } from "../../../../common/interfaces/ILoading";
import { IPasswordChange } from "../../../../models/IPasswordChange";
import { IUserId } from "../../../../common/interfaces/IUserId";
import {
  openAlertNotification,
  openSuccessNotification,
} from "../../../../helpers/NotificationHelper";
import { resources } from "../../../../common/Resources";
import { updateUserPassword } from "../../../../services/m29_users_management/GeneralUserService";
import TextInputF from "../../../../components/templateForm/form/textInput/TextInputF";

export class ChangePasswordFormTemplate extends React.Component<
  IUserId & FormikProps<IChangePasswordFormValues & ILoading>
> {
  render() {
    return (
        <Form className='flex flex-col gap-2.5 mb-5'>
          <div className='w-full md:w-382 flex flex-col'>
            <TextInputF
              {...this.props}
              name="currentPassword"
              placeholder={resources.profileView.currentPass}
              isPassword
              required
            />
          </div>
          <div className='w-full md:w-382 flex flex-col'>
            <TextInputF
              {...this.props}
              name="newPassword"
              placeholder={resources.profileView.newPass}
              isPassword
              required
            />
          </div>
          <div className='w-full md:w-382 flex flex-col'>
            <TextInputF
              {...this.props}
              name="repeatNewPassword"
              placeholder={resources.profileView.repeatNewPass}
              isPassword
              required
            />
          </div>
          <div className="w-148 h-44 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-md">
            <button
              className="w-144 h-41 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:bg-transparent bg-blue-gray text-white font-semibold text-xxs rounded-md"
              type="submit"
              disabled={
                !this.props.isValid ||
                this.props.isSubmitting ||
                this.props.values.loading
              }
            >
              SAVE
            </button>
          </div>
        </Form>
    );
  }
}

export const ChangePasswordForm = withFormik<
    IUserId,
    IChangePasswordFormValues & ILoading
>({
  enableReinitialize: true,

  validate: (
      values: IChangePasswordFormValues
  ): FormikErrors<IChangePasswordFormValues> => {
    let errors: FormikErrors<IChangePasswordFormValues> = {};
    const newPasswordError = validatePasswordRules(values.newPassword);
    if (newPasswordError) {
      errors.newPassword = newPasswordError;
    }
    const passwordMatchError = validateNewPasswordMatch(
        values.newPassword,
        values.repeatNewPassword
    );
    if (passwordMatchError) {
      errors.repeatNewPassword = passwordMatchError;
    }
    return errors;
  },

  handleSubmit: async (
      values: IChangePasswordFormValues,
      bag: FormikBag<IUserId, IChangePasswordFormValues>
  ) => {
    const obj: IPasswordChange = {
      currentPassword: values.currentPassword,
      password: values.newPassword,
    };

    await updateUserPassword(bag.props.userId!, obj)
        .then((response) => {
          if (!response.error) {
            openSuccessNotification(resources.statement.updated);
            bag.resetForm();
          } else {
            switch (response.message) {
              case "validation_error": {
                bag.setFieldError(
                    "currentPassword",
                    resources.validation.current_password_is_incorrect
                );
                break;
              }
              default: {
                openAlertNotification(resources.statement.unrecognized_error);
              }
            }
          }
        })
        .catch((error) => {
          bag.setErrors(error.response.data.errors);
        })
        .finally(() => {
          bag.setSubmitting(false);
        });
  }
})(ChangePasswordFormTemplate);

const validateNewPasswordMatch = (
    newPassword: string,
    repeatNewPassword: string
): string | undefined => {
  if (newPassword !== repeatNewPassword) {
    return resources.validation.requiredTheSamePasswords;
  }
  return undefined;
};

const validatePasswordRules = (newPassword: string): string | undefined => {
  const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,32}$)");
  const specChar = new RegExp("[!@#$%^&*]", "g");
  const specMatches = newPassword.match(specChar);
  if (
    !reg.test(newPassword) ||
    !Array.isArray(specMatches) ||
    specMatches.length < 2
  ) {
    return resources.validation.passwordCondition;
  }
  return undefined;
};
