import axios from "axios";
import { createGatewayAbsoluteUrl } from "../../helpers/UrlCreator";
import { IDictionaryValue } from "../../components/templateForm/types/dictionaries/IDictionaryValue";
import {
  openErrorNotification,
  openAlertNotification,
} from "../../helpers/NotificationHelper";
import { resourceDef } from "../../common/ResourceDef";
import moment from "moment";

const statisticsDashboardUrl = createGatewayAbsoluteUrl(
  resourceDef.StatisticsDashboard
);
const orderTotalUrl = createGatewayAbsoluteUrl(resourceDef.OrderTotal);
const downloadStatisticsUrl = createGatewayAbsoluteUrl(
  `${resourceDef.LeadsStatistics}/download`
);
const companiesListUrl = createGatewayAbsoluteUrl(resourceDef.CompaniesList);
const countriesListUrl = createGatewayAbsoluteUrl(resourceDef.CountriesList);
const partnersListUrl = createGatewayAbsoluteUrl(resourceDef.PartnersList);
const statisticsCampaignsUrl = createGatewayAbsoluteUrl(
  resourceDef.LeadsStatisticsCampaigns
);
const campaignStatisticsUrl = createGatewayAbsoluteUrl(
  resourceDef.CampaignStatistics
);

const statisticsErrorHandler = (filters: any) => {
  const groupBy = filters.groupBy;
  const endDate = moment(filters.endDate);
  const startDate = moment(filters.startDate);

  if (groupBy === "day") {
    return { startDate, endDate: startDate.clone().add(30, "days") };
  }

  if (groupBy === "month") {
    return { startDate, endDate: startDate.clone().add(11, "months") };
  }

  return { startDate, endDate };
};

export const getLeadsStatistics = (filters: any) =>
  axios
    .post(statisticsDashboardUrl, {
      filters,
    })
    .then((res) => res.data.data)
    .catch((e) => {
      openAlertNotification(e.response.data.message, {
        duration: 3,
      });
      throw statisticsErrorHandler(filters);
    });

export const getCampaignsStatistics = (filters: any) =>
  axios
    .post(campaignStatisticsUrl, {
      ...filters,
    })
    .then((res) => res.data.data)
    .catch((e) => {
      openAlertNotification(e.response.data.message, {
        duration: 3,
      });
      throw statisticsErrorHandler(filters);
    });

export const getStatisticsCampaigns = () =>
  axios
    .post(statisticsCampaignsUrl, {})
    .then((res) => res.data.data)
    .catch((e) => {
      openAlertNotification(e.response.data.message, {
        duration: 3,
      });
    });

export const getOrderTotal = (filters: any) =>
  axios
    .post(orderTotalUrl, {
      filters,
    })
    .then((res) => res.data.data)
    .catch((e) => {
      if (e.response.data.messageId !== 3) {
        openAlertNotification(e.response.data.message, {
          duration: 3,
        });
        throw statisticsErrorHandler(filters);
      }
    });

export const dowloadStatistics = (filters: any, columnOrder: string[]) =>
  axios({
    method: "post",
    url: downloadStatisticsUrl,
    data: { filters, columnOrder },
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    responseType: "blob",
  })
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "statistics.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(function (e) {
      openErrorNotification("M55 service Error -> dowloadStatistics");
      return [];
    });

export const getCompaniesList = () =>
  axios
    .post(companiesListUrl)
    .then((res) => {
      return res.data.data.sort((a: IDictionaryValue, b: IDictionaryValue) =>
        a.name.localeCompare(b.name)
      );
    })
    .catch(() => {
      openErrorNotification("M55 service Error -> getCompaniesList");
      return [];
    });

export const getCountriesList = () =>
  axios
    .get(countriesListUrl)
    .then((res) => {
      return res.data.data.sort((a: IDictionaryValue, b: IDictionaryValue) =>
        a.name.localeCompare(b.name)
      );
    })
    .catch(() => {
      openErrorNotification("M55 service Error -> getCountriesList");
      return [];
    });

export const getPartnersByCompany = (companyId: number) =>
  axios
    .post(partnersListUrl, {
      filters: {
        company_id: companyId,
      },
    })
    .then((res) => {
      return res.data.data
        .map((partner: any) => {
          return {
            ...partner,
            name: partner.name
              ? `${partner.name} [${partner.email}]`
              : `Name not given [${partner.email}]`,
          };
        })
        .sort(
          (a: IDictionaryValue, b: IDictionaryValue) =>
            a.name && b.name && a.name.localeCompare(b.name)
        );
    })
    .catch((e) => {
      console.log(e);
      openErrorNotification("M55 service Error -> getPartnersByCompany");
      return [];
    });
