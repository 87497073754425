import * as React from "react";
import { PartnerViewCheckbox } from "../../../../../../../components/partnerViewCheckbox/PartnerViewCheckbox";
import { Icon } from "antd";

export const TargetUrlLanding = (props: any) => {
  const ifSelected = (id: number, selectedArr: number[]): boolean => {
    return selectedArr.indexOf(id) >= 0;
  };

  return (
    <div className="flex items-center gap-2 mb-8">
      <PartnerViewCheckbox
        checked={ifSelected(props.id, props.selected)}
        value={props.id}
        onChange={props.onChange}
      />
      <div>
        <span className="text-dark-gray text-min-xs sm:text-xxs font-normal">
          {props.name}
        </span>
        <a
          href={props.url}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-4 text-blue-dark text-xxs"
        >
          View
          <Icon type="link" className="ml-1 relative -top-1" />
        </a>
      </div>
    </div>
  );
};
