import { useQuery } from "react-query";
import { getProducts } from "../../../services/m30_products/ProductsService";
import { getProductImages } from "../../../services/m30_products/images/ProductImagesService";
import { IProductImageFilters } from "../../../services/m30_products/images/interfaces/IProductImageFilters";
import { IProductPagesFilters } from "../../../services/m30_products/pages/interfaces/IProductPagesFilters";
import { getAllProductPages } from "../../../services/m30_products/pages/ProductPagesService";

type WizardProductData = {
  productData: ProductDataType[];
  productImg: string[];
  landings: [];
};

type ProductDataType = {
  id: number;
  name_short: string;
  description_en: string;
  description_ru: string;
};

export const useWizardProduct = (): WizardProductData => {
  const productInfo = {
    columns: ["name_short", "description_en", "description_ru"],
  };

  const objectImg: IProductImageFilters = {
    is_main: true,
  };

  const objectLandings: IProductPagesFilters = {
    columns: ["id", "name", "url", "deleted_at", "is_prelanding"],
  };

  const { data: productData } = useQuery<ProductDataType[]>(
    "productData-history",
    () => getProducts(productInfo)
  );

  const { data: productImg } = useQuery<string[]>("productImg-history", () =>
    getProductImages(441, objectImg).then((res) => {
      const obj = res !== null ? res : [];
      return obj[0].src;
    })
  );

  const { data: landings } = useQuery<[]>("productLandings-history", () =>
    getAllProductPages(Number(441), objectLandings).then((response) => {
      const landingsProduct = response.filter(
        (x: any) => x.deleted_at === null && !x["is_prelanding"]
      );
      return landingsProduct;
    })
  );

  return {
    productData: productData || [],
    productImg: productImg || [],
    landings: landings || [],
  };
};
