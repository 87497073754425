import * as React from 'react';
import styles from './styles/SessionExpiredContent.module.scss';
import { createAbsoluteUrl } from '../../../helpers/UrlCreator';
import { LogoAsLinkToLandingPage } from '../../../components/logoAsLinkToLandingPage/LogoAsLinkToLandingPage';
import { resources } from '../../../common/Resources';

export class SessionExpiredContent extends React.PureComponent {
    render() {
        return (
            <section className={styles.grid}>
              <div
                  className={styles.sessionExpired}
              >
                {resources.statement.session_expired}
              </div>
              <div
                  className={styles.sessionExpiredDescription}
              >
                {resources.statement.session_expired_description}
              </div>
              <LogoAsLinkToLandingPage/>
              <div className="w-270 h-44 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-md">
                <a className='w-265 text-center content-center h-41 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:bg-transparent bg-blue-gray hover:text-white text-white font-semibold text-xxs rounded-md'
                   href={createAbsoluteUrl('login')}>{resources.submitSuccess.logIn}</a>
              </div>
            </section>
        );
    }
}