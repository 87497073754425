import axios from 'axios';
import { createGatewayAbsoluteUrl } from '../helpers/UrlCreator';
import { getOriginalToken, setOriginalToken, setToken } from '../helpers/LocalStorageHelpers';
import { resourceDef } from '../common/ResourceDef';
import { resources } from '../common/Resources';
import { setHeader } from '../helpers/Authentication';

const loginQuery = createGatewayAbsoluteUrl(resourceDef.Authenticate);
const authQuery = createGatewayAbsoluteUrl(resourceDef.CheckTokenStatus);
const logoutQuery = createGatewayAbsoluteUrl(resourceDef.Logout);
const refreshTokenQuery = createGatewayAbsoluteUrl(resourceDef.RefreshToken);
const impersonateUrl = createGatewayAbsoluteUrl(resourceDef.Impersonate);

export const login = (username: string, password: string) => axios.post(loginQuery, { 'username': username, 'password': password })
    .then(response => {
        setToken(response.data.accessToken);
        return { error: false, status: response.status, message: '' };
    })
    .catch(error => {
        return { error: true, status: error.response.data.status, message: error.response.data.message };
    });

export const checkTokenStatus = () => axios.get(authQuery)
    .then(() => {
        return true;
    })
    .catch(function () {
        return false;
    });

export const logout = () => axios.post(logoutQuery)
    .catch(() => {
        // openErrorNotification('Error logout');
    });

export const refreshToken = () => axios.patch(refreshTokenQuery)
    .then((response) => {
        setToken(response.data.accessToken);

        setHeader();

        return response.data.accessToken;
    })
    .catch(() => {
        // openErrorNotification(resources.statement.databaseConnectionError + ' refreshToken');

        return null;
    });

export const refreshOriginalToken = async () => {
    const originalToken = getOriginalToken();
    if (originalToken === null || originalToken === '') return;
    try {
        const axiosInstance = axios.create();
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${originalToken}`;
        const response = await axiosInstance.patch(refreshTokenQuery);
        setOriginalToken(response.data.accessToken);
    }
    catch {
        console.log(resources.statement.databaseConnectionError + ' refreshToken');
    };
}

export const getDifferentUserToken = (userId: number) : Promise<string | null> => axios.post(`${impersonateUrl}/${userId}`)
    .then((response) => {
        return response.data.accessToken;
    })
    .catch(() => {
        // openErrorNotification(`Cannot get access token. User ID: ${userId}.`);
        return null;
    })
