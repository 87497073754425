import React from "react";
import { Spin } from "antd";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import copyContent from "./images/copy.svg";
import { resources } from "../../../common/Resources";
import { copyClipboard } from "../../../helpers/CopyHelpers";
import { useWizardProduct } from "../lib/getWizardProduct";

export const WizardCopyUrl = () => {
  const wizardUrl = useSelector((state: any) => state.commonState.wizard.url);
  const { productData, productImg } = useWizardProduct();

  return (
    <section className="container-fluid h-full xm:bg-white md:bg-transparent mb-16 pt-10 px-4 md:px-8">
      {Object.keys(productData).length > 0 ? (
        <>
          <div className="flex gap-3 mb-9">
            <div className="min-w-38 h-38 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-50">
              <div className="w-33 h-33 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-light-white-gray-wizard text-white font-semibold text-min-xs rounded-50 content-center text-center">
                <span className="text-indigo text-xmd font-semibold">4</span>
              </div>
            </div>
            <h2 className="self-center text-indigo font-semibold text-md md:text-sm">
              Salin dan mulai menghasilkan
            </h2>
          </div>
          <div className="w-full lg:w-758 bg-white rounded-xxs">
            <div className="rounded-tl-xxs rounded-tr-xxs hidden md:block">
              <img
                className="mx-auto"
                src={productImg.toString()}
                alt="icon-product"
              />
            </div>
            <div className="md:pl-6 md:pr-14 pb-11">
              <h2 className="pt-4 mb-2 text-indigo font-semibold text-md hidden md:block">
                {productData[0].name_short}
              </h2>
              <p className="text-dark-gray font-normal text-min-xs sm:text-xxs pb-7">
                Salin URL pemasaran unik Anda dan mulai menghasilkan
              </p>
              <div className="flex gap-4 items-center">
                <textarea
                  className="whitespace-pre-wrap w-631 h-88 lg:h-70 resize-none text-gray-500 text-opacity-50 border rounded-xs border-lite-gray py-2 px-5 w-full text-min-xs sm:text-xxs font-normal"
                  defaultValue={wizardUrl}
                />
                <img
                  className="w-24 h-24 sm:w-36 sm:h-36 cursor-pointer"
                  src={copyContent}
                  onClick={(e) => copyClipboard(e, wizardUrl)}
                  alt="copy"
                />
              </div>
            </div>
            <NavLink to="/statistics">
              <div className="pb-8 w-244 mx-auto">
                <button className="w-222 h-38 md:w-244 md:h-44 text-white text-min-xs md:text-xxs font-bold text-center rounded-full border-1 bg-gradient-to-r from-blue to-aquamarine hover:from-light-blue hover:to-navy-blue hover:text-white">
                  MASUK KE STATISTIK ANDA
                </button>
              </div>
            </NavLink>
          </div>
        </>
      ) : (
        <div className="text-center">
          <Spin tip={resources.spin.loading} />
        </div>
      )}
    </section>
  );
};
