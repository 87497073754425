import * as React from 'react';
import { connect } from 'react-redux';
import { Result, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { resources } from '../../common/Resources';

class NotFoundInner extends React.Component<RouteComponentProps> {

    public render() {
        return (
            <Result
              className='w-550 mx-auto'
                status="404"
                title="404"
                subTitle={resources.title.notFoundLabel}
                extra={<div className="ml-24 w-280 h-44 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-md">
                    <Button
                      className="hover:bg-transparent hover:text-white text-white w-278 h-41 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-gray font-semibold text-xxs rounded-md"
                      onClick={() => this.props.history.replace('/')}>
                        {resources.buttons.goToMainPage}
                    </Button>
                </div>}
            />
        )
    };
}

export const NotFoundView: React.ComponentClass = connect(null, null)(withRouter(NotFoundInner));
