import * as React from "react";
import { connect } from "react-redux";
import { getUserInf } from "../../../services/m29_users_management/GeneralUserService";
import { IAppState } from "../../../store";
import { resources } from "../../../common/Resources";
import { WalletForm } from "../forms/wallet/WalletForm";
import { UserSettings } from "../components/userSettings/UserSettings";
import { ChangePassword } from "../components/changePassword/ChangePassword";
import { IProfileSettingsProps } from "../interfaces/IProfileProps";

export class SettingsContentInner extends React.Component<IProfileSettingsProps> {
  state = {
    user: {} as any,
    tabKey: 1,
  };

  componentDidMount = () => {
    this.getUsersInf();
  };

  onClickTab = (tabKey: number) => {
    this.setState({ tabKey });
  };

  public render() {
    const { user } = this.state;

    return (
      <div className="container-fluid h-full xm:bg-white md:bg-transparent mb-16 pt-10 px-6 md:px-8">
        <div className="flex flex-col gap-8">
          <h1 className="inline-block text-indigo text-md sm:text-sm font-semibold">
            Settings
          </h1>
          <ul className="flex gap-6 sm:gap-10 max-w-max pb-5 pl-1">
            <li
              className={`${
                this.state.tabKey === 1 ? "text-green" : "text-blue-dark"
              } text-xxxs font-semibold text-green cursor-pointer`}
              onClick={() => this.onClickTab(1)}
            >
              {resources.tabPane.userSettings}
              <hr
                className={`${
                  this.state.tabKey === 1 ? "block" : "hidden"
                } border-b-2 w-1/8 border-green`}
              />
            </li>
            <li
              className={`${
                this.state.tabKey === 2 ? "text-green" : "text-blue-dark"
              } text-xxxs font-semibold text-green cursor-pointer`}
              onClick={() => this.onClickTab(2)}
            >
              {resources.tabPane.changePassword}
              <hr
                className={`${
                  this.state.tabKey === 2 ? "block" : "hidden"
                } border-b-2 w-1/8 border-green`}
              />
            </li>
            <li
              className={`${
                this.state.tabKey === 3 ? "text-green" : "text-blue-dark"
              } text-xxxs font-semibold text-green cursor-pointer`}
              onClick={() => this.onClickTab(3)}
            >
              {resources.tabPane.wallet}
              <hr
                className={`${
                  this.state.tabKey === 3 ? "block" : "hidden"
                } border-b-2 w-1/8 border-green`}
              />
            </li>
            <li
              className={`${
                this.state.tabKey === 4 ? "text-green" : "text-blue-dark"
              } text-xxxs font-semibold text-green cursor-pointer`}
              onClick={() => this.onClickTab(4)}
            >
              {resources.tabPane.payoutHistory}
              <hr
                className={`${
                  this.state.tabKey === 4 ? "block" : "hidden"
                } border-b-2 w-1/8 border-green`}
              />
            </li>
          </ul>
        </div>
        <hr className="border-white-gray mb-10" />

        {(this.state.tabKey === 1 && (
          <UserSettings user={user} id={this.props.appUserInfo.id} />
        )) ||
          (this.state.tabKey === 2 && (
            <ChangePassword userId={this.props.appUserInfo.id} />
          )) ||
          (this.state.tabKey === 3 && (
            <WalletForm user={user} id={this.props.appUserInfo.id as number} />
          )) ||
          (this.state.tabKey === 4 && "PayoutsHistory")}
      </div>
    );
  }

  getUsersInf = () => {
    const object = {
      columns: [
        "id",
        "email",
        "name",
        "phone",
        "telegram",
        "skype",
        "timezone",
        "partner_token",
        "wallet_name",
        "wallet_number",
      ],
    };

    getUserInf(object).then((user) => {
      this.setState({ user });
    });
  };
}

const mapStateToProps = (state: IAppState): IProfileSettingsProps => {
  return {
    appUserInfo: state.commonState.appUserInfo,
  };
};

export const ProfileSettingsContent =
  connect(mapStateToProps)(SettingsContentInner);
