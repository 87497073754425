import * as React from "react";
import { getLocale } from "../../../../helpers/LocaleHelper";
import { IChangeLang } from "../../../../models/IChangeLang";
import { IHandleUpdate } from "./interfaces/IHandleUpdate";
import arrowDownIcon from "./images/arrow-down.svg";

const LANGS_MAP = Object.freeze({
  en_GB: "English",
  id_ID: "Indonesian",
});

export const HeaderLangPicker = ({
  changeLang,
  handleUpdate,
}: IChangeLang & IHandleUpdate) => {
  const locale = getLocale();
  const [open, setOpen] = React.useState(false);

  const onChangeLanguage = (language: string) => {
    changeLang(language);
    handleUpdate();
    setOpen(false);
  };

  return (
    <div className="w-40 relative">
      <div
        className="flex py-1 px-4 justify-between items-center border-2 rounded-full border-blue cursor-pointer"
        onClick={() => setOpen((open) => !open)}
      >
        <span className="text-white">{LANGS_MAP[locale]}</span>
        <img src={arrowDownIcon} alt="arrow-down" />
      </div>

      {open && (
        <div className="absolute top-109 z-20 w-full py-2 px-4 border-2 rounded-xxs border-blue bg-gradient-to-r from-light-blue to-navy-blue">
          <div
            className="cursor-pointer text-white mb-1 hover:text-blue-400"
            onClick={() => onChangeLanguage("en_GB")}
          >
            English
          </div>
          <div
            className="cursor-pointer text-white hover:text-blue-400"
            onClick={() => onChangeLanguage("id_ID")}
          >
            Indonesian
          </div>
        </div>
      )}
    </div>
  );
};
