import * as React from "react";
import { getProductImages } from "../../../../services/m30_products/images/ProductImagesService";
import { getProducts } from "../../../../services/m30_products/ProductsService";
import { hasPermission } from "../../../../authorizations/AuthorizationLogic";
import { IOffersListFiltersFormProps } from "../forms/interfaces/IOffersListFiltersFormProps";
import { IProductCardProps } from "../interfaces/IProductCardProps";
import { IProductImageFilters } from "../../../../services/m30_products/images/interfaces/IProductImageFilters";
import { UserPermissionType } from "../../../../models/IAppUserInfoModel";
import noImg from "../../../../common/images/noImg.png";
import { getDictionary } from "../../../../helpers/DisctionariesHelper";
import { decodeCategoriesArray } from "../../../../helpers/Encoders";
import { Link } from "react-router-dom";
import { getLocale } from "../../../../helpers/LocaleHelper";
import { resources } from "../../../../common/Resources";

export class OffersListContent extends React.Component {
  _isMounted = false;

  state = {
    productsListBase: Array<IProductCardProps>(),
    productsList: Array<IProductCardProps>(),
    src_list: [],
    globalSearchString: "",
    pageSize: 18,
    loading: false,
    beauty: undefined,
    health: undefined,
    slimming: undefined,
    adult: undefined,
    other: undefined,
    indonesia1: undefined,
    indonesia2: undefined,
    thailand: undefined,
    vietnam: undefined,
    saudiArabia: undefined,
    cpa: undefined,
    cpl: undefined,
    globalSearchDisplay: "none",
    visibleSort: false,
    visibleRequest: false,
  };

  componentDidMount = () => {
    this._isMounted = true;

    this.getProductsList();
    this.getProductImg();
    window.scrollTo(0, 0);
  };

  componentWillUnmount = () => (this._isMounted = false);

  findProductImg = (product: any) => {
    const obj: any[] = this.state.src_list.filter(
      (x: any) => x.id_product === product.id
    );
    const src = obj.length ? obj[0].src : undefined;

    return src ? src : noImg;
  };

  getProductCategories = (product: any): string => {
    const categories = getDictionary("categoriesD");
    const productCategories = product.categories.split(",");
    return decodeCategoriesArray(productCategories, categories);
  };

  public render() {
    const locale = getLocale();

    return (
      <section className="px-6 md:px-8">
        <h1 className="text-indigo font-semibold text-2xl mb-3 mt-10 sm:mb-10 sm:mt-10">
          {resources.productsFormMain.title}
        </h1>
        <p className="block mb-8 sm:mb-0 text-dark font-normal text-xxs sm:hidden">
          Pilih produk yang akan diiklankan atau gunakan PANDUAN kami untuk
          melakukan proses langkah demi langkah
        </p>
        <div className="flex justify-center flex-wrap gap-6 mb-10">
          {this.state.productsList.map((product: any) => (
            <div
              key={product.id}
              className="w-full lg:w-offers-2xl border-gray border-1 rounded-xl"
            >
              <img
                src={this.findProductImg(product)}
                alt="productImage"
                className="w-full h-96 object-cover rounded-t-xl"
              />
              <div className="bg-white rounded-b-xl">
                <h3 className="text-indigo font-semibold text-xl mt-0 m-4 pt-4">
                  {product.name_short}
                </h3>
                <div className="flex gap-2 m-4">
                  {this.getProductCategories(product)
                    .split(",")
                    .map((category: any, index: number) => (
                      <span key={index} className="bg-gray px-3 rounded-full">
                        {category}
                      </span>
                    ))}
                </div>
                <p
                  className="m-4 border-b border-light-gray pb-4"
                  dangerouslySetInnerHTML={{
                    __html:
                      locale === "en_GB"
                        ? product.description_en
                        : product.description_id,
                  }}
                />
                <div className="flex flex-col sm:flex-row justify-between">
                  <div className="w-full sm:w-315">
                    <div className="mx-4 mb-4 flex justify-between">
                      <span className="font-normal">
                        {resources.productsFormMain.country}
                      </span>
                      <span className="font-normal">ID</span>
                    </div>
                    <div className="mx-4 mb-4 flex justify-between">
                      <span className="font-normal">
                        {resources.productsFormMain.payout}
                      </span>
                      <span className="font-normal">
                        Rp {product.default_payout_idr}
                      </span>
                    </div>
                  </div>
                  <div className="w-full sm:w-315">
                    <div className="mx-4 mb-4 flex justify-between">
                      <span className="font-normal">
                        {resources.productsFormMain.av_order}
                      </span>
                      <span className="font-normal">
                        {product.av_order} {resources.productsFormMain.products}
                      </span>
                    </div>
                    <div className="mx-4 mb-4 flex justify-between">
                      <span className="font-normal">
                        {resources.productsFormMain.delivered}
                      </span>
                      <span className="font-normal">
                        {product.approve_page / 100}%
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <Link
                    to={`/offers/${product.id}`}
                    className="w-191 h-44 pt-3 m-4 text-white text-center rounded-full border-1 bg-gradient-to-r from-blue to-aquamarine hover:from-light-blue hover:to-navy-blue hover:text-white"
                  >
                    {resources.productsFormMain.advertize}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }

  getProductImg = () => {
    const object: IProductImageFilters = {
      is_main: true,
    };

    getProductImages("all", object).then((src_list) => {
      src_list !== null && this._isMounted && this.setState({ src_list });
    });
  };

  onChange = (event: any): void =>
    this.setState({ [event.target.name]: event.target.value });

  toggleSort = () => this.setState({ visibleSort: !this.state.visibleSort });

  onChangeFilters = (obj: IOffersListFiltersFormProps) => {
    this.setState(
      {
        beauty: obj.beauty,
        health: obj.health,
        slimming: obj.slimming,
        adult: obj.adult,
        other: obj.other,
        indonesia1: obj.indonesia1,
        indonesia2: obj.indonesia2,
        thailand: obj.thailand,
        vietnam: obj.vietnam,
        saudiArabia: obj.saudiArabia,
        cpa: obj.cpa,
        cpl: obj.cpl,
      },
      () => {
        this.applyFilters();
      }
    );
  };

  applyFilters = () => {
    const baseList = this.state.productsListBase;
    const globalString = this.state.globalSearchString;
    const beautyFilter = this.state.beauty;
    const healthyFilter = this.state.health;
    const slimmingFilter = this.state.slimming;
    const adultFilter = this.state.adult;
    // const otherFilter = this.state.other;
    const indonesia1Filter = this.state.indonesia1;
    const indonesia2Filter = this.state.indonesia2;
    const thailandFilter = this.state.thailand;
    const vietnamFilter = this.state.vietnam;
    const saudiFilter = this.state.saudiArabia;
    const cpaFilter = this.state.cpa;
    const cplFilter = this.state.cpl;

    // global search filter
    const globalSearchResult = baseList.filter((product) => {
      return (
        product.name_short &&
        product.name_short!.toLowerCase().includes(globalString)
      );
    });

    // cat filter
    const catFilterResult = globalSearchResult.filter((product) => {
      return (
        product.categories !== null &&
        ((beautyFilter ? product.categories!.includes("1") : false) ||
          (healthyFilter ? product.categories!.includes("2") : false) ||
          (slimmingFilter ? product.categories!.includes("3") : false) ||
          (adultFilter ? product.categories!.includes("4") : false) ||
          (!beautyFilter && !healthyFilter && !slimmingFilter && !adultFilter))
      );
    });

    // country filter
    const countryFilterResult = catFilterResult.filter((product) => {
      // Indonasia  and Indonasia2 filter
      // HOTFIX
      //:TODO move that logic to backend
      let indonesiaFilter = false;
      if (hasPermission(UserPermissionType.SeeOriginalProductCountry)) {
        indonesiaFilter =
          (indonesia1Filter ? product.country_id === 1 : false) ||
          (indonesia2Filter ? product.country_id === 7 : false);
      } else {
        indonesiaFilter = indonesia1Filter
          ? product.country_id === 1 || product.country_id === 7
          : false;
      }
      return (
        indonesiaFilter ||
        (thailandFilter ? product.country_id === 3 : false) ||
        (vietnamFilter ? product.country_id === 5 : false) ||
        (saudiFilter ? product.country_id === 4 : false) ||
        (!indonesia1Filter &&
          !indonesia2Filter &&
          !thailandFilter &&
          !vietnamFilter &&
          !saudiFilter)
      );
    });

    // conversion type filter
    const conversionTypeFilterResult = countryFilterResult.filter((product) => {
      return (
        (cpaFilter ? product.default_payout_schema === "cpa" : false) ||
        (cplFilter ? product.default_payout_schema === "cpl" : false) ||
        (!cpaFilter && !cplFilter)
      );
    });

    const result = conversionTypeFilterResult;

    this.setState({ productsList: result });
  };

  onGlobalSearch = (event: any) =>
    this.setState(
      { globalSearchString: event.target.value.toLowerCase() },
      () => this.applyFilters()
    );

  showGlobalSearch = () =>
    this.setState({
      globalSearchDisplay:
        this.state.globalSearchDisplay === "block" ? "none" : "block",
    });

  getProductsList = () => {
    this.setState({ loading: true });

    const object = {
      columns: [
        "id",
        "name_short",
        "default_landing_price",
        "country_name",
        "default_payout",
        "categories",
        "default_payout_schema",
        "country_id",
        "default_payout_idr",
        "description_en",
        "approve_page",
        "av_order",
      ],
      is_visible_affbay: 1,
      is_visible_partners: 1,
      is_visible_influencers: 1,
    };

    getProducts(object)
      .then((response) => {
        const productsList = response.sort((a: any, b: any) =>
          (a.name_short ? a.name_short : "").localeCompare(
            b.name_short ? b.name_short : ""
          )
        );

        this._isMounted &&
          this.setState({
            productsListBase: productsList,
            productsList,
          });
      })
      .finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
  };
}
